import { makeAutoObservable } from "mobx";
import {
	AttributeShelf,
	LoaderShelf,
	PaginatedListShelf,
} from "@startapp/mobx-utils";
import api from "~/resources/api";
import { onFetchError } from "~/resources/fetchError";
import { showSuccessToast } from "~/resources/toast";
import strings from "~/resources/strings";

const pageStrings = strings.pages.users.table;

export default class Store {
	public paginetedListShelf: PaginatedListShelf<api.User>;

	public name = new AttributeShelf<string>("");

	public loader = new LoaderShelf();

	constructor() {
		makeAutoObservable(this);
		this.paginetedListShelf = new PaginatedListShelf(
			(page) => api.getAllUsers(page, this.name.value),
			{
				fetchOnConstructor: true,
				onFetchError,
			},
		);
	}

	public deleteUser = async (id: string) => {
		this.loader.start();
		try {
			const deleteUser = await api.deleteUser(id);
			showSuccessToast(pageStrings.delete(deleteUser.name));
			this.paginetedListShelf.refresh();
		} catch (e) {
			onFetchError(e);
		} finally {
			this.loader.end();
		}
	};
}
