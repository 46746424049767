import { Flex } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import React from "react";
import strings from "~/resources/strings";
import { TextInput } from "..";

type FieldType = "bankName" | "agency" | "agencyDv" | "account" | "accountDv";

interface FormValues {
	field: (field: FieldType) => {
		name: FieldType;
		onChange: (element: {
			target: {
				value: string;
			};
		}) => void;
		value: string;
		error: string | null;
	};
}

interface IProps {
	isLoading: boolean;
	formValues: FormValues;
}

export const BankForm: React.FC<IProps> = observer((props) => {

	const {
		isLoading,
		formValues,
	} = props;

	const commonStrings = strings.common;

	return (
		<>
			<TextInput
				labelText={commonStrings.fields.bankName}
				labelProps={{ fontWeight: "bold" }}
				type="text"
				isDisabled={isLoading}
				errorText={formValues.field("bankName").error}
				{...formValues.field("bankName")}
			/>
			<Flex flexDirection={{ base: "column", md: "row" }} justifyContent="space-between" >
				<TextInput
					boxProps={{ w: "75%" }}
					w={{ base: "100%", md: "97%" }}
					mb={{
						base: 8,
						md:0,
					}}
					labelText={commonStrings.fields.account}
					labelProps={{ fontWeight: "bold" }}
					type="text"
					isDisabled={isLoading}
					errorText={formValues.field("account").error}
					{...formValues.field("account")}
				/>
				<TextInput
					boxProps={{ w: "25%" }}
					w={{ base: "100%", md: "97%" }}
					mb={{
						base: 8,
						md:0,
					}}
					labelText={commonStrings.fields.accountDv}
					labelProps={{ fontWeight: "bold" }}
					type="text"
					isDisabled={isLoading}
					errorText={formValues.field("accountDv").error}
					{...formValues.field("accountDv")}
				/>
			</Flex>
			<Flex flexDirection={{ base: "column", md: "row" }} justifyContent="space-between" >
				<TextInput
					boxProps={{ w: "75%" }}
					labelText={commonStrings.fields.agency}
					type="text"
					w={{ base: "100%", md: "97%" }}
					mb={{
						base: 8,
						md:0,
					}}
					isDisabled={isLoading}
					errorText={formValues.field("agency").error}
					{...formValues.field("agency")}
				/>
				<TextInput
					boxProps={{ w: "25%" }}
					labelText={commonStrings.fields.agencyDv}
					type="text"
					w={{ base: "100%", md: "97%" }}
					mb={{
						base: 8,
						md:0,
					}}
					isDisabled={isLoading}
					errorText={formValues.field("agencyDv").error}
					{...formValues.field("agencyDv")}
				/>
			</Flex>
		</>
	);
});
