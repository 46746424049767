import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import {
	Flex,
	Td,
	Tr,
	Image,
} from "@chakra-ui/react";
import {
	DrawerSearch,
	Table,
	TableCellWithActionButtons,
} from "~/components";
import strings from "~/resources/strings";
import api from "~/resources/api";
import { useHistory } from "~/hooks/useHistory";
import Store from "./store";
import { useGlobalStore } from "~/contexts/useGlobalContext";

const TableView: React.FC = () => {
	const [isOpen , setIsOpen] = React.useState(false);
	const pageStrings = strings.pages.adminUserTable;
	const { dialog } = useGlobalStore();
	const history = useHistory();
	const store = useLocalObservable(() => new Store());
	const onSearch = () => store.paginetedListShelf.refresh();
	const onGoToEdit = (id: string) => history.push(`edit/${id}`);
	const onGoToCreate = () => history.push("create");

	const openDialog = (admin: api.AdminUser) => {
		const modal = strings.common.modal;
		dialog.showDialog({
			title: modal.title,
			closeOnOverlayClick: true,
			description: modal.description(admin.name),
			buttons: [
				{
					title: modal.button.delete,
					onPress: () => {
						store.deleteAdmin(admin.id);
						dialog.closeDialog();
					},
					buttonProps: { bg: "red.500" },
				},
				{
					title: modal.button.cancel,
					onPress: () => dialog.closeDialog(),
					outlined: true,
				},
			],
		});
	};

	return (
		<Flex flexDir="column" p={{ base: "2", lg: "16" }}>
			<Table
				data={store.paginetedListShelf.items}
				loading={store.paginetedListShelf.loader.isLoading}
				addButtonText={strings.actions.create}
				tableTitle={pageStrings.table.title}
				onAdd={onGoToCreate}
				headers={pageStrings.table.header}
				headerFilter={
					<Image
						w={5}
						mx={2}
						src="/search.svg"
						onClick={() => setIsOpen(true)}
					/>
				}
				renderRow={(item, index) => (
					<Tr key={index}>
						<Td>{item.name}</Td>
						<Td>{item.email}</Td>
						<TableCellWithActionButtons
							onEdit={() => onGoToEdit(item.id)}
							onDelete={() => openDialog(item)}
						/>
					</Tr>
				)}
				emptyMessage={strings.common.noResutls}
				currentPage={store.paginetedListShelf.page}
				prevPage={store.paginetedListShelf.previousPage}
				nextPage={store.paginetedListShelf.nextPage}
				hasNextPage={store.paginetedListShelf.hasNextPage}
			/>
			<DrawerSearch
				onClick={onSearch}
				onChange={store.name.setValue}
				value={store.name.value}
				setStatus={store.adminType.setValue}
				filterAdmin
				isOpen={isOpen}
				onClose={() => setIsOpen(false)}
			/>
		</Flex>
	);
};

export default observer(TableView);
