import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import {
	Box,
	Button,
	Stack,
	Switch,
	Text,
	Textarea,
	Flex,
	Heading,
} from "@chakra-ui/react";
import {
	CentralizedCard,
	DatePicker,
	PdfPicker,
	SmallImagePicker,
	TextInput,
} from "~/components";
import strings from "~/resources/strings";
import { useHistory } from "~/hooks/useHistory";
import Store from "./store";

const CreateOrEdit: React.FC = () => {
	const commonStrings = strings.common;
	const pageStrings = strings.pages.product.createOrEdit;
	const { id } = useParams<{ id: string }>();
	const history = useHistory();

	const store = useLocalObservable(() => new Store(id || ""));

	const onSuccess = () => {
		history.goBack();
	};

	return (
		<Flex
			w={{
				base:"100%",
				md:"90%",
				lg:"80%",
				xl:"60%",
			}}
			mx="auto"
		>
			<CentralizedCard
				title={{
					text: pageStrings.title(id),
				}}
				altPaddingForCard
			>
				<Flex align="center" gap={5}>
					<SmallImagePicker
						pickImage={store.imageShelf.getPickerFields().pick}
						src={store.imageShelf.src}
					/>
					<Text
						p={3}
						cursor="pointer"
						onClick={store.imageShelf.getPickerFields().pick}
					>
						{pageStrings.textAddImage}
					</Text>
				</Flex>
				<TextInput
					placeholder={commonStrings.fields.name}
					type="text"
					isDisabled={store.loader.isLoading}
					errorText={store.formShelf.field("name").error}
					{...store.formShelf.field("name")}
				/>
				<Textarea
					placeholder={commonStrings.fields.description}
					isDisabled={store.loader.isLoading}
					minH="40vh"
					_placeholder={{
						color: "white",
					}}
					{...store.formShelf.field("description")}
				/>
				<DatePicker
					placeholder={strings.components.datePicker.expirationDate}
					onChangeDate={store.expirationDate.setValue}
					selectedDate={store.expirationDate.value}
				/>
				<Box w="100%" py={8}>
					<Heading fontSize={17}>{commonStrings.fields.document}</Heading>
					<PdfPicker
						pickImage={() => store.file.getPickerFields().pick()}
						src={store.file.fileName}
					/>
				</Box>
				<Stack>
					<Text color="white" fontSize={16}>{commonStrings.fields.isFinalized}</Text>
					<Box>
						<Switch
							isChecked={store.isFinalized.value}
							mb={8}
							colorScheme="teal"
							onChangeCapture={() => store.isFinalized.setValue(!store.isFinalized.value)}
							size="lg"
						/>
					</Box>
				</Stack>
				<Stack spacing={5}>
					<Button
						w="100%"
						padding={8}
						isLoading={store.loader.isLoading}
						onClick={() => store.createOrEditProduct(onSuccess)}
					>
						{commonStrings.buttons.done}
					</Button>
					<Text
						cursor="pointer"
						onClick={() => history.goBack()}
						textAlign="center"
						textDecorationLine="underline"
					>
						{pageStrings.back}
					</Text>
				</Stack>
			</CentralizedCard>
		</Flex>
	);
};

export default observer(CreateOrEdit);
