import React from "react";
import { useParams } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite";
import {
	IconButton,
	Tooltip,
	Flex,
	Heading,
	Center,
} from "@chakra-ui/react";
import format from "~/resources/format";
import strings from "~/resources/strings";
import { Store } from "./store";
import {
	ButtonBack,
	CentralizedCard,
	DetailsRow,
	Dialog,
	DocumentLink,
	TableLinkedUsers,
	TableNotification,
} from "~/components";
import { useHistory } from "~/hooks/useHistory";
import api from "~/resources/api";

const Details: React.FC = () => {
	const [ modalPurchasedProduct, setModalPurchasedProduct ] = React.useState<boolean>(false);
	const [ modalNotification, setModalNotification ] = React.useState<boolean>(false);
	const commonStrings = strings.common;
	const { id } = useParams<"id">();
	const history = useHistory();
	const store = useLocalObservable(() => new Store(id || ""));
	const onGoToEdit = (idProduct: string) => history.push(`/admin/purchasedProduct/edit/${idProduct}`);
	const onGoToCreatePurchasedProduct = () => history.push(`/admin/product/createProductLinked/${id}`);
	const onGoToCreateNotification = () => history.push(`/admin/notification/create/${id}`);
	const onGoToEditNotification = (idNotification: string) => history.push(`/admin/notification/edit/${idNotification}`);
	const onGoToDetailsNotification = (idNotification: string) => history.push(`/admin/notification/details/${idNotification}`);

	const onDeletePurchasedProdut = (product: api.PurchasedProduct) => {
		store.purchasedProductId.setValue(product.id);
		setModalPurchasedProduct(true);
	};

	const onDeleteNotification = (notification: string) => {
		store.notificationId.setValue(notification);
		setModalNotification(true);
	};

	return (
		<Center flexDirection="column">
			<>
				<CentralizedCard
					title={{
						text: commonStrings.detailsTitle,
						helper: (
							<Tooltip label={strings.common.edit}>
								<IconButton
									variant="icon"
									aria-label="Voltar"
									size="lg"
								/>
							</Tooltip>
						),
					}}
					isLoading={store.fetchModelShelf.initialLoader.isLoading}
				>
					{
						store.fetchModelShelf.model.value &&
							<Flex direction="column" mb={5}>
								<Heading fontSize="md" color="primary.500">{commonStrings.fields.user}</Heading>
								<DetailsRow
									label={commonStrings.fields.name}
									value={store.fetchModelShelf.fetchedModel.name}
								/>
								<DetailsRow
									label={commonStrings.fields.id}
									value={store.fetchModelShelf.fetchedModel.id}
								/>
								<DetailsRow
									label={commonStrings.fields.expirationDate}
									value={format.date(store.fetchModelShelf.fetchedModel.expirationDate)}
								/>
								<DetailsRow
									label={commonStrings.fields.status}
									value={commonStrings.fields.finalizedOrNot(!!store.fetchModelShelf.fetchedModel.finalizedAt)}
								/>
								<DetailsRow
									label={commonStrings.fields.description}
									value={store.fetchModelShelf.fetchedModel.description}
								/>
								{
									store.fetchModelShelf.model.value.incomeTax &&
									(<DocumentLink label={commonStrings.fields.incomeTax} pdf={store.fetchModelShelf.fetchedModel.incomeTax || ""} />)
								}
							</Flex>
					}
					<TableNotification
						items={store.paginatedListShelfNotification.items}
						isLoading={store.paginatedListShelfNotification.loader.isLoading}
						page={store.paginatedListShelfNotification.page}
						previousPage={store.paginatedListShelfNotification.previousPage}
						nextPage={store.paginatedListShelfNotification.nextPage}
						hasNextPage={store.paginatedListShelfNotification.hasNextPage}
						onEdit={onGoToEditNotification}
						onCreate={onGoToCreateNotification}
						onDelete={onDeleteNotification}
						onGoToDetails={onGoToDetailsNotification}
					/>
					<Dialog
						closeOnOverlayClick
						description={strings.pages.product.details.descriptionDeleteNotification}
						isOpen={modalNotification}
						onClose={() => setModalNotification(false)}
						buttons={[
							{
								title: strings.actions.delete,
								onPress: () => {
									store.deleteNotification(store.notificationId.value);
									setModalPurchasedProduct(false);
								},
								buttonProps: { bg: "red.500" },
							},
							{
								title: strings.actions.cancel,
								onPress: () => setModalNotification(false),
								outlined: true,
							},
						]}
					/>

					<Flex>
						<Flex
							my={7}
							h="1px"
							mx="auto"
							w="90%"
							bg="primary.600"
						/>
					</Flex>

					<TableLinkedUsers
						items={store.paginatedListShelfUsers.items}
						isLoading={store.paginatedListShelfUsers.loader.isLoading}
						page={store.paginatedListShelfUsers.page}
						previousPage={store.paginatedListShelfUsers.previousPage}
						nextPage={store.paginatedListShelfUsers.nextPage}
						hasNextPage={store.paginatedListShelfUsers.hasNextPage}
						onCreate={onGoToCreatePurchasedProduct}
						onEdit={onGoToEdit}
						openModal={onDeletePurchasedProdut}
					/>
					<Dialog
						closeOnOverlayClick
						description={strings.pages.product.details.descriptionDeleteProduct}
						onClose={() => setModalPurchasedProduct(false)}
						buttons={[
							{
								title: strings.actions.delete,
								onPress: () => {
									store.deletePurchasedProduct(store.purchasedProductId.value);
									setModalPurchasedProduct(false);
								},
								buttonProps: { bg: "red.500" },
							},
							{
								title: strings.actions.cancel,
								onPress: () => setModalPurchasedProduct(false),
								outlined: true,
							},
						]}
						isOpen={modalPurchasedProduct}
					/>
				</CentralizedCard>
				<ButtonBack />
			</>
		</Center>
	);
};

export default observer(Details);
