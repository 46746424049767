import React from "react";
import { observer } from "mobx-react-lite";
import {
	Button,
	Flex,
	useToast,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { Card, TextInput } from "~/components";
import strings from "~/resources/strings";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import { useHistory } from "~/hooks/useHistory";
import { showSuccessToast } from "~/resources/toast";

export const NewPassword: React.FC = observer(() => {

	const [hasToken, setHasToken] = React.useState(false);
	const pageStrings = strings.pages.recoveryPage;
	const { token } = useParams<{ token: string }>();
	const { authStore } = useGlobalStore();
	const toast = useToast();
	const history = useHistory();

	React.useEffect(() => {
		if (token && !authStore.receivedToken) {
			authStore.validateTokenUrl(
				() => { },
				() => history.push("/recovery"),
				token,
			);
		}
	}, []);

	return (
		<Flex
			justifyContent="center"
			alignItems="center"
			w="100%"
			minH="100vh"
			bg="primary.700"
		>
			<Card
				display="flex"
				alignItems="center"
				maxW={{ base: "100vw", sm: "28rem" }}
				rounded={{ base: 0, sm: 10 }}
				w="100%"
				p={10}
				bgColor="primary.500"
			>
				<Flex
					w="100%"
					direction="column"
					gap={4}
				>
					<TextInput
						labelProps={{
							color: "white",
							textAlign: "center",
							fontWeight: "normal",
						}}
						type="password"
						labelText={pageStrings.newPassword}
						isDisabled={authStore.loader.isLoading}
						{...authStore.form.field("newPassword")}
					/>
					<TextInput
						labelProps={{
							color: "white",
							textAlign: "center",
							fontWeight: "normal",
						}}
						type="password"
						labelText={pageStrings.confirmNewPassword}
						isDisabled={authStore.loader.isLoading}
						{...authStore.form.field("confirmNewPassword")}
					/>
					<Button
						isLoading={authStore.loader.isLoading}
						onClick={() => {
							setHasToken(!hasToken);
							authStore.resetPassword(() => {
								showSuccessToast(pageStrings.successPassword);
								history.push("/login");
							},
							(errorMessage: string) => toast({ status: "error", title: errorMessage }),
							);
						}}
					>
						{pageStrings.newPasswordButton}
					</Button>
				</Flex>
			</Card>
		</Flex>
	);
});
