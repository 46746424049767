import React from "react";
import { observer } from "mobx-react-lite";
import {
	Button,
	Heading,
	Td,
	Tr,
} from "@chakra-ui/react";
import api from "~/resources/api";
import { TableCellWithActionButtons } from "../TableCellWithActionButtons";
import { Table } from "../Table";
import strings from "~/resources/strings";
import format from "~/resources/format";

interface IProps {
	items: api.Notification[];
	previousPage: () => void;
	nextPage: () => void;
	page: number;
	isLoading: boolean;
	hasNextPage: boolean;
	onCreate: () => void;
	onEdit: (id: string) => void;
	onDelete: (id: string) => void;
	onGoToDetails: (id: string) => void;
}

export const TableNotification: React.FC<IProps> = observer((props) => {
	const {
		items,
		isLoading,
		page,
		nextPage,
		previousPage,
		hasNextPage,
		onCreate,
		onEdit,
		onDelete,
		onGoToDetails,
	} = props;

	const componentStrings = strings.pages.product.details;

	return (
		<>
			<Heading color="white" fontSize={18}>{componentStrings.titleNotification}</Heading>
			<Table
				isCard
				data={items}
				loading={isLoading}
				headers={componentStrings.headersNotification}
				renderRow={(item, index) => (
					<Tr key={index}>
						<Td>{item.id}</Td>
						<Td>{item.name}</Td>
						<Td>{format.cutLongStrings(item.description)}</Td>
						<Td>{format.date(item.createdAt)}</Td>
						<TableCellWithActionButtons
							onEdit={() => onEdit(item.id)}
							onView={() => onGoToDetails(item.id)}
							onDelete={() => onDelete(item.id)}
						/>
					</Tr>
				)}
				emptyMessage={strings.common.noResutls}
				currentPage={page}
				prevPage={previousPage}
				nextPage={nextPage}
				hasNextPage={hasNextPage}
			/>
			<Button
				w={{
					base: "90%",
					md: "20%",
					xl:"10%",
				}}
				py={30}
				onClick={onCreate}
			>
				{strings.actions.create}
			</Button>
		</>
	);
});
