import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import {
	Flex,
	Image,
	Td,
	Tr,
} from "@chakra-ui/react";
import {
	DrawerSearch,
	Table,
	TableCellWithActionButtons,
} from "~/components";
import strings from "~/resources/strings";
import api from "~/resources/api";
import format from "~/resources/format";
import ImagePlaceHolder from "../../../../static/pick_image.svg";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import Store from "./store";
import { useHistory } from "~/hooks/useHistory";

const TableView: React.FC = () => {
	const [isOpen, setIsOpen] = React.useState(false);
	const store = useLocalObservable(() => new Store());
	const pageStrings = strings.pages.product.table;
	const { dialog } = useGlobalStore();
	const history = useHistory();
	const onSearch = () => store.paginetedListShelf.refresh();
	const onGoToEdit = (id: string) => history.push(`edit/${id}`);
	const onGoToDetails = (id: string) => history.push(`details/${id}`);
	const onGoToCreate = () => history.push("create");

	const openDialog = (item: api.Product) => {
		const modal = strings.common.modal;
		dialog.showDialog({
			title: modal.title,
			closeOnOverlayClick: true,
			description: modal.descriptionDelete,
			buttons: [
				{
					title: modal.button.delete,
					onPress: () => {
						store.deleteProduct(item.id);
						dialog.closeDialog();
					},
					buttonProps: { bg: "red.500" },
				},
				{
					title: modal.button.cancel,
					onPress: () => dialog.closeDialog(),
					outlined: true,
				},
			],
		});
	};

	return (
		<Flex flexDir="column" p={{ base: "2", lg: "16" }}>
			<Table
				data={store.paginetedListShelf.items}
				loading={store.paginetedListShelf.loader.isLoading}
				tableTitle={pageStrings.title}
				headers={pageStrings.header}
				headerFilter={
					<Flex flexDirection="row" alignItems="center">
						<Image
							w={5}
							mx={2}
							src="/search.svg"
							alt={pageStrings.altSearch}
							onClick={() => setIsOpen(true)}
						/>
						<Image
							w={5}
							h={4}
							mx={2}
							src="/downloadIcon.svg"
							alt={pageStrings.altDownload}
							onClick={() => store.downloadCSV()}
						/>
					</Flex>
				}
				renderRow={(item) => (
					<Tr>
						<Td>{item.id}</Td>
						<Td>{item.name}</Td>
						<Td>{format.date(item.expirationDate)}</Td>
						<Td>
							<Image
								w={35}
								h={35}
								rounded="sm"
								src={item.image ? item.image.url : ImagePlaceHolder}
							/>
						</Td>
						<Td>{pageStrings.status(item.finalizedAt)}</Td>
						<TableCellWithActionButtons
							onEdit={() => onGoToEdit(item.id)}
							onDelete={() => openDialog(item)}
							onView={() => onGoToDetails(item.id)}
						/>
					</Tr>
				)}
				onAdd={onGoToCreate}
				addButtonText={pageStrings.add}
				emptyMessage={strings.common.noResutls}
				currentPage={store.paginetedListShelf.page}
				prevPage={store.paginetedListShelf.previousPage}
				nextPage={store.paginetedListShelf.nextPage}
				hasNextPage={store.paginetedListShelf.hasNextPage}
			/>
			<DrawerSearch
				onClick={onSearch}
				onChange={store.name.setValue}
				value={store.name.value || ""}
				searchFinalized={store.isFinalized.value}
				setStatus={store.isFinalized.setValue}
				isOpen={isOpen}
				onClose={() => setIsOpen(false)}
			/>
		</Flex>
	);
};

export default observer(TableView);
