import TableView from "./TableView";
import Details from "./Details";
import CreateOrEdit from "./CreateOrEdit";
import { PurchasedProduct } from "./PurchasedProduct";

export const Users = {
	TableView,
	Details,
	CreateOrEdit,
	PurchasedProduct,
};

