import React from "react";
import { useParams } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite";
import {
	IconButton,
	Tooltip,
	Flex,
	Heading,
	Td,
	Tr,
	Button,
	Center,
} from "@chakra-ui/react";
import format from "~/resources/format";
import strings from "~/resources/strings";
import { Store } from "./store";
import {
	BankAccountDetails,
	ButtonBack,
	CentralizedCard,
	DetailsRow,
	Table,
	TableCellWithActionButtons,
} from "~/components";
import api from "~/resources/api";
import { useHistory } from "~/hooks/useHistory";
import { useGlobalStore } from "~/contexts/useGlobalContext";

const Details: React.FC = () => {
	const commonStrings = strings.common;
	const pageStrings = strings.pages.users.details;
	const { id } = useParams<"id">();
	const { dialog } = useGlobalStore();
	const history = useHistory();
	const store = useLocalObservable(() => new Store(id || ""));
	const onGoToEdit = (idProduct: string) => history.push(`/admin/purchasedProduct/edit/${idProduct}`);
	const onGoToCreatePurchasedProduct = () => history.push(`/admin/users/createProductLinked/${id}`);

	const openDialog = (credit: api.PurchasedProduct) => {
		const modal = strings.common.modal;
		dialog.showDialog({
			title: modal.title,
			closeOnOverlayClick: true,
			description: modal.descriptionCredit,
			buttons: [
				{
					title: modal.button.delete,
					onPress: () => {
						store.deletePurchasedProduct(credit.id);
						dialog.closeDialog();
					},
					buttonProps: { bg: "red.500" },
				},
				{
					title: modal.button.cancel,
					onPress: () => dialog.closeDialog(),
					outlined: true,
				},
			],
		});
	};
	return (
		<Center flexDirection="column">
			<>
				<CentralizedCard
					title={{
						text: commonStrings.detailsTitle,
						helper: (
							<Tooltip label={strings.common.edit}>
								<IconButton
									variant="icon"
									aria-label="Voltar"
									size="lg"
								/>
							</Tooltip>
						),
					}}
					isLoading={store.fetchModelShelf.initialLoader.isLoading}
				>
					{
						store.fetchModelShelf.model.value &&
							<Flex direction="column" mb={5}>
								<Heading fontSize="md" color="primary.500">{commonStrings.fields.user}</Heading>
								<DetailsRow
									label={commonStrings.fields.name}
									value={store.fetchModelShelf.fetchedModel.name}
								/>
								<DetailsRow
									label={commonStrings.fields.email}
									value={store.fetchModelShelf.fetchedModel.email}
								/>

								{store.fetchModelShelf.fetchedModel.documentNumber.length > 14 ? (
									<DetailsRow
										label={commonStrings.fields.cnpj}
										value={format.formatCNPJ(store.fetchModelShelf.fetchedModel.documentNumber || "")}
									/>
								) : (
									<DetailsRow
										label={commonStrings.fields.cpf}
										value={format.documentNumber(store.fetchModelShelf.fetchedModel.documentNumber || "")}
									/>
								)}
								{
									store.fetchModelShelf.fetchedModel.phone && (
										<DetailsRow
											label={commonStrings.fields.phone}
											value={format.phone(store.fetchModelShelf.fetchedModel.phone)}
										/>
									)
								}
								{
									store.fetchModelShelf.fetchedModel.whatsapp && (
										<DetailsRow
											label={commonStrings.fields.whatsapp}
											value={format.phone(store.fetchModelShelf.fetchedModel.whatsapp)}
										/>
									)
								}
								{
									store.fetchModelShelf.fetchedModel.bankName && (
										<BankAccountDetails
											bankAccount={store.fetchModelShelf.fetchedModel}
										/>
									)
								}
							</Flex>
					}
					<Heading color="white" fontSize={18}>{pageStrings.title}</Heading>
					<Table
						isCard
						data={store.paginatedListShelf.items}
						loading={store.paginatedListShelf.loader.isLoading}
						headers={pageStrings.headersProducts}
						renderRow={(item, index) => (
							<Tr key={index}>
								<Td>{item.id}</Td>
								<Td>{item.user.name}</Td>
								<Td>{item.product.name}</Td>
								<Td>{item.finalizedAt ? format.date(item.finalizedAt) : pageStrings.notFinalized}</Td>
								<Td />
								<TableCellWithActionButtons
									onEdit={() => onGoToEdit(item.id)}
									onDelete={() => openDialog(item)}
								/>
							</Tr>
						)}
						emptyMessage={strings.common.noResutls}
						currentPage={store.paginatedListShelf.page}
						prevPage={store.paginatedListShelf.previousPage}
						nextPage={store.paginatedListShelf.nextPage}
						hasNextPage={store.paginatedListShelf.hasNextPage}
					/>
					<Center>
						<Button
							w={{
								base: "90%",
								md: "20%",
								xl:"10%",
							}}
							onClick={() => onGoToCreatePurchasedProduct()}
						>
							{pageStrings.addButtonText}
						</Button>
					</Center>
				</CentralizedCard>
				<ButtonBack />
			</>
		</Center>
	);
};

export default observer(Details);
