import React from "react";
import { observer } from "mobx-react-lite";
import { Routes, Route } from "react-router-dom";
import { MainLayout } from "~/layout";
import {
	HomePage,
	UserDeleteRequests,
	Posts,
	Users,
	AdminUsers,
	Product,
} from "~/pages";

const AdminRoutes = observer(() => (
	<MainLayout>
		<Routes>
			<Route
				index
				element={<HomePage />}
			/>
			<Route path="posts">
				<Route
					index
					element={<Posts.TableView />}
				/>
				<Route
					path="create"
					element={<Posts.CreateOrEdit />}
				/>
				<Route
					path="edit/:id"
					element={<Posts.CreateOrEdit />}
				/>
			</Route>
			<Route path="userDeleteRequests">
				<Route
					index
					element={<UserDeleteRequests.TableView />}
				/>
			</Route>
			<Route path="users">
				<Route
					index
					element={<Users.TableView />}
				/>
				<Route
					path="details/:id"
					element={<Users.Details />}
				/>
				<Route
					path="create"
					element={<Users.CreateOrEdit />}
				/>
				<Route
					path="edit/:id"
					element={<Users.CreateOrEdit />}
				/>
				<Route
					path="createProductLinked/:id"
					element={<Product.CreateLinkedAUser toUser />}
				/>
			</Route>
			<Route path="adminUsers">
				<Route
					index
					element={<AdminUsers.TableView />}
				/>
				<Route
					path="create"
					element={<AdminUsers.CreateOrEdit />}
				/>
				<Route
					path="edit/:id"
					element={<AdminUsers.CreateOrEdit />}
				/>
			</Route>
			<Route path="product">
				<Route
					index
					element={<Product.TableView />}
				/>
				<Route
					path="create"
					element={<Product.CreateOrEdit />}
				/>
				<Route
					path="edit/:id"
					element={<Product.CreateOrEdit />}
				/>
				<Route
					path="details/:id"
					element={<Product.Details />}
				/>
				<Route
					path="createProductLinked/:id"
					element={<Product.CreateLinkedAUser />}
				/>
			</Route>
			<Route path="notification">
				<Route
					path="create/:id"
					element={<Product.Notification.Create />}
				/>
				<Route
					path="edit/:id"
					element={<Product.Notification.Edit />}
				/>
				<Route
					path="details/:id"
					element={<Product.Notification.Details />}
				/>
			</Route>
			<Route path="purchasedProduct">
				<Route
					path="edit/:id"
					element={<Users.PurchasedProduct.Edit />}
				/>
			</Route>
		</Routes>
	</MainLayout>
));

export default AdminRoutes;
