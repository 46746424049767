import React from "react";
import { useParams } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite";
import {
	Center,
	Flex,
	Heading,
	Link,
	Text,
} from "@chakra-ui/react";
import strings from "~/resources/strings";
import { Store } from "./store";
import { CentralizedCard, DetailsRow } from "~/components";
import { useHistory } from "~/hooks/useHistory";

const Details: React.FC = () => {
	const commonStrings = strings.common;
	const pageStrings = strings.pages.product.notification.details;
	const { id } = useParams<"id">();
	const store = useLocalObservable(() => new Store(id || ""));
	const history = useHistory();

	return (
		<Center flexDirection="column">
			<>
				<CentralizedCard
					title={{
						text: pageStrings.title,
					}}
					isLoading={store.fetchModelShelf.initialLoader.isLoading}
					widthCardIR="60%"
				>
					{
						store.fetchModelShelf.model.value &&
							<>
								<DetailsRow
									value={store.fetchModelShelf.fetchedModel.name}
								/>
								<Heading fontSize={16} py={2}>{commonStrings.fields.files}</Heading>
								{
									store.fetchModelShelf.fetchedModel.files?.map((file: string, index: number) => (
										<Flex
											justifyContent="center"
											borderRadius={12}
											borderColor="primary.50"
											borderWidth={1}
											borderStyle="dashed"
											key={index}
											w="max-content"
										>
											<Link
												href={file}
												isExternal
												px={12}
												py={1}
												color="white"
											>
												{pageStrings.pdf(index + 1)}
											</Link>
										</Flex>
									))
								}
								<DetailsRow
									value={store.fetchModelShelf.fetchedModel.description}
									inputProps={{ minH: 40 }}
									boxProps={{ my: 5 }}
								/>
							</>
					}
					<Text
						onClick={() => history.goBack()}
						textAlign="center"
						color="primary.200"
						fontSize={14}
						textDecoration="underline"
						pt={10}
						pb={4}
						cursor="pointer"
					>
						{strings.actions.back}
					</Text>
				</CentralizedCard>
			</>
		</Center>
	);
};

export default observer(Details);
