import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import {
	Flex,
	Image,
	Td,
	Tr,
} from "@chakra-ui/react";
import { Table, TableCellWithActionButtons } from "~/components";
import strings from "~/resources/strings";
import ImagePlaceHolder from "../../../../static/pick_image.svg";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import Store from "./store";
import { useHistory } from "~/hooks/useHistory";
import api from "~/resources/api";
import format from "~/resources/format";

const TableView: React.FC = () => {
	const store = useLocalObservable(() => new Store());
	const pageStrings = strings.pages.posts.table;
	const { dialog } = useGlobalStore();
	const history = useHistory();

	const onGoToEdit = (id: string) => history.push(`edit/${id}`);
	const onGoToCreate = () => history.push("create");

	const openDialog = (item: api.Post) => {
		const modal = strings.common.modal;
		dialog.showDialog({
			title: modal.title,
			closeOnOverlayClick: true,
			description: modal.descriptionDelete,
			buttons: [
				{
					title: modal.button.delete,
					onPress: () => {
						store.deletePost(item.id);
						dialog.closeDialog();
					},
					buttonProps: { bg: "red.500" },
				},
				{
					title: modal.button.cancel,
					onPress: () => dialog.closeDialog(),
					outlined: true,
				},
			],
		});
	};


	return (
		<Flex flexDir="column" p={{ base: "2", lg: "16" }}>
			<Table
				data={store.paginetedListShelf.items}
				loading={store.paginetedListShelf.loader.isLoading}
				tableTitle={pageStrings.title}
				headers={pageStrings.header}
				renderRow={(item) => (
					<Tr>
						<Td>
							<Image
								rounded="md"
								w={35}
								h={35}
								src={item.image ? item.image.url : ImagePlaceHolder}
							/>
						</Td>
						<Td>{item.id}</Td>
						<Td>{item.title}</Td>
						<Td>{format.cutLongStrings(item.description)}</Td>
						<TableCellWithActionButtons
							onEdit={() => onGoToEdit(item.id)}
							onDelete={() => openDialog(item)}
						/>
					</Tr>
				)}
				onAdd={onGoToCreate}
				addButtonText={pageStrings.add}
				emptyMessage={strings.common.noResutls}
				currentPage={store.paginetedListShelf.page}
				prevPage={store.paginetedListShelf.previousPage}
				nextPage={store.paginetedListShelf.nextPage}
				hasNextPage={store.paginetedListShelf.hasNextPage}
			/>
		</Flex>
	);
};

export default observer(TableView);
