import { makeAutoObservable } from "mobx";
import {  ImagePickerShelf } from "@startapp/mobx-utils/src/web";
import {
	AttributeShelf,
	LoaderShelf,
	PaginatedListShelf,
} from "@startapp/mobx-utils";
import { onFetchError } from "~/resources/fetchError";
import api from "~/resources/api";
import strings from "~/resources/strings";
import { showSuccessToast } from "~/resources/toast";
import FilePickerShelf from "~/shelves/FilePickerShelf";
import format from "~/resources/format";

const pageStrings = strings.pages.product.createOrEdit;

export default class Store {
	public loader = new LoaderShelf();
	public imageShelf = new ImagePickerShelf(api.uploadImage);
	public files = new AttributeShelf<string[]>([]);
	public file = new FilePickerShelf(api.uploadUncertainFile);
	public product = new AttributeShelf<api.Product | null>(null);
	public user = new AttributeShelf<api.User | null>(null);
	public name = new AttributeShelf<string>("");
	public investedAmount = new AttributeShelf<string>("");
	public percentageExpectedValue = new AttributeShelf<string>("");
	public grossValue = new AttributeShelf<string>("");
	public expirationDate = new AttributeShelf<Date>(new Date());
	public investmentDate = new AttributeShelf<Date>(new Date());
	public finalizedDate = new AttributeShelf<Date>(new Date());
	public isFinalized = new AttributeShelf<boolean>(false);
	public paginatedListShelfUser: PaginatedListShelf<api.User>;
	public paginatedListShelfProduct: PaginatedListShelf<api.Product>;

	constructor(id: string, toUser?: boolean) {
		makeAutoObservable(this);

		this.paginatedListShelfUser = new PaginatedListShelf(
			(page) => api.getAllUsersToAutoComplete(page, this.name.value),
			{
				fetchOnConstructor: true,
				onFetchError,
			},
		);

		this.paginatedListShelfProduct = new PaginatedListShelf(
			(page) => api.getAllProductToAutoComplete(page, this.name.value),
			{
				fetchOnConstructor: true,
				onFetchError,
			},
		);

		if (id) {
			if (toUser) {
				this.getUser(id);
				return;
			}
			this.getProduct(id);
		}
	}

	public getUser = async (id: string) => {
		this.loader.start();
		try {
			const user = await api.getUser(id);
			this.user.setValue(user);
		} catch (e) {
			onFetchError(e);
		} finally {
			this.loader.end();
		}
	};

	public getProduct = async (id: string) => {
		this.loader.start();
		try {
			const product = await api.getProductById(id);
			this.product.setValue(product);
		} catch (e) {
			onFetchError(e);
		} finally {
			this.loader.end();
		}
	};

	public get productData() {

		const purchasedProduct = {
			investedAmount: Number(format.removeCharactersExceptLetterNumber(this.investedAmount.value)),
			investmentDate: this.investmentDate.value,
			grossValue: this.isFinalized.value ? Number(format.removeCharactersExceptLetterNumber(this.grossValue.value)) : null,
			percentageExpectedValue: Number(this.percentageExpectedValue.value),
			files: this.files.value,
			finalizedAt: this.isFinalized.value ? this.finalizedDate.value : null,
		};
		if (this.finalizedDate.value) {
			this.isFinalized.setValue(true);
		}
		return purchasedProduct;
	}

	public createPurchasedProduct = async (onSuccess: () => void) => {
		this.loader.start();
		try {

			if (this.user.value && this.product.value) {
				await api.createPurchasedProduct(this.user.value.id, this.product.value.id, this.productData);
			}

			showSuccessToast(pageStrings.successPurchasedProduct);
			onSuccess();
		} catch (e) {
			onFetchError(e);
		} finally {
			this.loader.end();
		}
	};
}
