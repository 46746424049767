import React from "react";
import { observer } from "mobx-react-lite";
import {
	Center,
	Text,
} from "@chakra-ui/react";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import strings from "~/resources/strings";

export const HomePage: React.FC = observer(() => {
	const { authStore } = useGlobalStore();

	return (
		<Center h={{base: "50vh", md:"80vh"}}>
			<Text
				color="white"
				fontWeight="bold"
				cursor="default"
			>
				{strings.home.message(authStore.currentAdminUser?.name)}
			</Text>
		</Center>
	);
});


