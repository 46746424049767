import { LocalizedStringsMethods } from "localized-strings";
import { UAParser } from "ua-parser-js";

let baseUrl: string = "localhost:8000";
let strings: (LocalizedStringsMethods & any) | null = null;

export function setUrl(url: string): void {
    baseUrl = url;
}

export function setStrings(newStrings: (LocalizedStringsMethods & {}) | null): void {
    strings = newStrings;
}
export interface LatLng {
    lat: number;
    lng: number;
}

export interface Address {
    zipcode: string;
    street: string;
    streetNumber: string;
    complementary: string;
    neighborhood: string;
    city: string;
    state: StateUF;
}

export interface Image {
    thumb: SimpleImage;
    width: number;
    height: number;
    url: string;
}

export interface SimpleImage {
    width: number;
    height: number;
    url: string;
}

export interface FilterByPeriod {
    startDate: Date | null;
    endDate: Date | null;
}

export interface UncertainImage {
    bytes: Buffer | null;
    image: Image | null;
}

export interface UncertainFile {
    bytes: Buffer | null;
    url: string | null;
}

export interface EditAdminUser {
    name: string;
    email: string;
    password: string;
}

export interface AdminUser {
    id: string;
    name: string;
    email: string;
    password: string;
    type: AdminUserType;
}

export interface User {
    id: string;
    email: string;
    name: string;
    password: string;
    documentNumber: string;
    whatsapp: string | null;
    phone: string | null;
    agency: string | null;
    agencyDv: string | null;
    account: string | null;
    accountDv: string | null;
    bankName: string | null;
}

export interface NewUser {
    advisor: AdminUser;
    email: string;
    name: string;
    password: string;
    documentNumber: string;
    whatsapp: string | null;
    phone: string | null;
    agency: string | null;
    agencyDv: string | null;
    account: string | null;
    accountDv: string | null;
    bankName: string | null;
}

export interface UserWithAdvisor {
    advisor: AdminUser | null;
    id: string;
    email: string;
    name: string;
    password: string;
    documentNumber: string;
    whatsapp: string | null;
    phone: string | null;
    agency: string | null;
    agencyDv: string | null;
    account: string | null;
    accountDv: string | null;
    bankName: string | null;
}

export interface EditUser {
    email: string;
    name: string;
    password: string;
    documentNumber: string;
    whatsapp: string | null;
    phone: string | null;
    agency: string | null;
    agencyDv: string | null;
    account: string | null;
    accountDv: string | null;
    bankName: string | null;
}

export interface IncomeTax {
    id: string;
    user: User;
    year: string;
    files: string;
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date | null;
}

export interface NewIncomeTax {
    userId: string;
    year: string;
    files: UncertainFile;
}

export interface EditIncomeTax {
    year: string;
    files: UncertainFile;
}

export interface Product {
    id: string;
    name: string;
    description: string;
    image: Image | null;
    expirationDate: Date;
    finalizedAt: Date | null;
    createdAt: Date;
    updatedAt: Date;
    incomeTax: string | null;
    deletedAt: Date | null;
}

export interface NewProduct {
    name: string;
    description: string;
    incomeTax: UncertainFile | null;
    image: UncertainImage;
    expirationDate: Date;
    finalizedAt: Date | null;
}

export interface NewNotification {
    name: string;
    description: string;
    files: UncertainFile[];
}

export interface Notification {
    id: string;
    name: string;
    product: Product;
    files: string[] | null;
    description: string;
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date | null;
}

export interface NewPost {
    image: UncertainImage;
    title: string;
    description: string;
}

export interface Post {
    id: string;
    image: Image;
    title: string;
    description: string;
    createdAt: Date;
    deletedAt: Date | null;
}

export interface PurchasedProduct {
    id: string;
    user: User;
    product: Product;
    percentageExpectedValue: number;
    investedAmount: number;
    liftingAmount: number;
    investmentDate: Date;
    grossValue: number | null;
    files: string[] | null;
    finalizedAt: Date | null;
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date | null;
}

export interface NewPurchasedProduct {
    investedAmount: number;
    investmentDate: Date;
    percentageExpectedValue: number;
    grossValue: number | null;
    files: string[] | null;
    finalizedAt: Date | null;
}

export interface EditPurchasedProduct {
    product: Product;
    investedAmount: number;
    percentageExpectedValue: number;
    investmentDate: Date;
    grossValue: number | null;
    files: string[] | null;
    finalizedAt: Date | null;
}

export interface NewProductLinkedAUser {
    investedAmount: number;
    investmentDate: Date;
    grossValue: number | null;
    files: string[] | null;
    finalizedAt: Date | null;
    product: NewProduct;
}

export interface ReportStructure {
    advisorName: string;
    userName: string;
    productName: string;
    investedAmount: number;
    percentageExpectedValue: number;
    investmentDate: Date;
    finalizedAt: Date | null;
    grossValue: number | null;
}

export interface BankAccount {
    bankName: string | null;
    agency: string | null;
    agencyDv: string | null;
    account: string | null;
    accountDv: string | null;
}

export interface UserStatistics {
    appliedValue: number;
    liftedValue: number;
    liftingAmount: number;
    netAnnualAverage: number;
    countProductsNotFinalizated: number;
    countProductsFinalizated: number;
}

export interface UserDeleteRequest {
    id: string;
    user: User;
    acceptedAt: Date | null;
    createdAt: Date;
    deletedAt: Date | null;
}

export enum StateUF {
    AC = "AC",
    AL = "AL",
    AP = "AP",
    AM = "AM",
    BA = "BA",
    CE = "CE",
    DF = "DF",
    ES = "ES",
    GO = "GO",
    MA = "MA",
    MT = "MT",
    MS = "MS",
    MG = "MG",
    PA = "PA",
    PB = "PB",
    PR = "PR",
    PE = "PE",
    PI = "PI",
    RJ = "RJ",
    RN = "RN",
    RS = "RS",
    RO = "RO",
    RR = "RR",
    SC = "SC",
    SP = "SP",
    SE = "SE",
    TO = "TO",
}

export function translateStateUF(enumStateUF: StateUF): string {
    switch (enumStateUF) {
        case StateUF.AC: {
            return strings ? strings["enum"]["StateUF"]["AC"] || StateUF.AC : StateUF.AC;
        }
        case StateUF.AL: {
            return strings ? strings["enum"]["StateUF"]["AL"] || StateUF.AL : StateUF.AL;
        }
        case StateUF.AP: {
            return strings ? strings["enum"]["StateUF"]["AP"] || StateUF.AP : StateUF.AP;
        }
        case StateUF.AM: {
            return strings ? strings["enum"]["StateUF"]["AM"] || StateUF.AM : StateUF.AM;
        }
        case StateUF.BA: {
            return strings ? strings["enum"]["StateUF"]["BA"] || StateUF.BA : StateUF.BA;
        }
        case StateUF.CE: {
            return strings ? strings["enum"]["StateUF"]["CE"] || StateUF.CE : StateUF.CE;
        }
        case StateUF.DF: {
            return strings ? strings["enum"]["StateUF"]["DF"] || StateUF.DF : StateUF.DF;
        }
        case StateUF.ES: {
            return strings ? strings["enum"]["StateUF"]["ES"] || StateUF.ES : StateUF.ES;
        }
        case StateUF.GO: {
            return strings ? strings["enum"]["StateUF"]["GO"] || StateUF.GO : StateUF.GO;
        }
        case StateUF.MA: {
            return strings ? strings["enum"]["StateUF"]["MA"] || StateUF.MA : StateUF.MA;
        }
        case StateUF.MT: {
            return strings ? strings["enum"]["StateUF"]["MT"] || StateUF.MT : StateUF.MT;
        }
        case StateUF.MS: {
            return strings ? strings["enum"]["StateUF"]["MS"] || StateUF.MS : StateUF.MS;
        }
        case StateUF.MG: {
            return strings ? strings["enum"]["StateUF"]["MG"] || StateUF.MG : StateUF.MG;
        }
        case StateUF.PA: {
            return strings ? strings["enum"]["StateUF"]["PA"] || StateUF.PA : StateUF.PA;
        }
        case StateUF.PB: {
            return strings ? strings["enum"]["StateUF"]["PB"] || StateUF.PB : StateUF.PB;
        }
        case StateUF.PR: {
            return strings ? strings["enum"]["StateUF"]["PR"] || StateUF.PR : StateUF.PR;
        }
        case StateUF.PE: {
            return strings ? strings["enum"]["StateUF"]["PE"] || StateUF.PE : StateUF.PE;
        }
        case StateUF.PI: {
            return strings ? strings["enum"]["StateUF"]["PI"] || StateUF.PI : StateUF.PI;
        }
        case StateUF.RJ: {
            return strings ? strings["enum"]["StateUF"]["RJ"] || StateUF.RJ : StateUF.RJ;
        }
        case StateUF.RN: {
            return strings ? strings["enum"]["StateUF"]["RN"] || StateUF.RN : StateUF.RN;
        }
        case StateUF.RS: {
            return strings ? strings["enum"]["StateUF"]["RS"] || StateUF.RS : StateUF.RS;
        }
        case StateUF.RO: {
            return strings ? strings["enum"]["StateUF"]["RO"] || StateUF.RO : StateUF.RO;
        }
        case StateUF.RR: {
            return strings ? strings["enum"]["StateUF"]["RR"] || StateUF.RR : StateUF.RR;
        }
        case StateUF.SC: {
            return strings ? strings["enum"]["StateUF"]["SC"] || StateUF.SC : StateUF.SC;
        }
        case StateUF.SP: {
            return strings ? strings["enum"]["StateUF"]["SP"] || StateUF.SP : StateUF.SP;
        }
        case StateUF.SE: {
            return strings ? strings["enum"]["StateUF"]["SE"] || StateUF.SE : StateUF.SE;
        }
        case StateUF.TO: {
            return strings ? strings["enum"]["StateUF"]["TO"] || StateUF.TO : StateUF.TO;
        }
    }
    return "";
}

export function allValuesStateUF(): StateUF[] {
    return [
        StateUF.AC,
        StateUF.AL,
        StateUF.AP,
        StateUF.AM,
        StateUF.BA,
        StateUF.CE,
        StateUF.DF,
        StateUF.ES,
        StateUF.GO,
        StateUF.MA,
        StateUF.MT,
        StateUF.MS,
        StateUF.MG,
        StateUF.PA,
        StateUF.PB,
        StateUF.PR,
        StateUF.PE,
        StateUF.PI,
        StateUF.RJ,
        StateUF.RN,
        StateUF.RS,
        StateUF.RO,
        StateUF.RR,
        StateUF.SC,
        StateUF.SP,
        StateUF.SE,
        StateUF.TO,
    ];
}

export function allTranslatedValuesStateUF(): string[] {
    return [
        translateStateUF(StateUF.AC),
        translateStateUF(StateUF.AL),
        translateStateUF(StateUF.AP),
        translateStateUF(StateUF.AM),
        translateStateUF(StateUF.BA),
        translateStateUF(StateUF.CE),
        translateStateUF(StateUF.DF),
        translateStateUF(StateUF.ES),
        translateStateUF(StateUF.GO),
        translateStateUF(StateUF.MA),
        translateStateUF(StateUF.MT),
        translateStateUF(StateUF.MS),
        translateStateUF(StateUF.MG),
        translateStateUF(StateUF.PA),
        translateStateUF(StateUF.PB),
        translateStateUF(StateUF.PR),
        translateStateUF(StateUF.PE),
        translateStateUF(StateUF.PI),
        translateStateUF(StateUF.RJ),
        translateStateUF(StateUF.RN),
        translateStateUF(StateUF.RS),
        translateStateUF(StateUF.RO),
        translateStateUF(StateUF.RR),
        translateStateUF(StateUF.SC),
        translateStateUF(StateUF.SP),
        translateStateUF(StateUF.SE),
        translateStateUF(StateUF.TO),
    ];
}

export function allDisplayableValuesStateUF(): string[] {
    return allTranslatedValuesStateUF().sort();
}

export function valueFromTranslationStateUF(translation: string): StateUF {
    const index = allTranslatedValuesStateUF().indexOf(translation);
    return allValuesStateUF()[index] || StateUF.AC;
}

export enum PaymentMethod {
    pix = "pix",
    cash = "cash",
    creditCard = "creditCard",
    debitCard = "debitCard",
}

export function translatePaymentMethod(enumPaymentMethod: PaymentMethod): string {
    switch (enumPaymentMethod) {
        case PaymentMethod.pix: {
            return strings ? strings["enum"]["PaymentMethod"]["pix"] || PaymentMethod.pix : PaymentMethod.pix;
        }
        case PaymentMethod.cash: {
            return strings ? strings["enum"]["PaymentMethod"]["cash"] || PaymentMethod.cash : PaymentMethod.cash;
        }
        case PaymentMethod.creditCard: {
            return strings ? strings["enum"]["PaymentMethod"]["creditCard"] || PaymentMethod.creditCard : PaymentMethod.creditCard;
        }
        case PaymentMethod.debitCard: {
            return strings ? strings["enum"]["PaymentMethod"]["debitCard"] || PaymentMethod.debitCard : PaymentMethod.debitCard;
        }
    }
    return "";
}

export function allValuesPaymentMethod(): PaymentMethod[] {
    return [
        PaymentMethod.pix,
        PaymentMethod.cash,
        PaymentMethod.creditCard,
        PaymentMethod.debitCard,
    ];
}

export function allTranslatedValuesPaymentMethod(): string[] {
    return [
        translatePaymentMethod(PaymentMethod.pix),
        translatePaymentMethod(PaymentMethod.cash),
        translatePaymentMethod(PaymentMethod.creditCard),
        translatePaymentMethod(PaymentMethod.debitCard),
    ];
}

export function allDisplayableValuesPaymentMethod(): string[] {
    return allTranslatedValuesPaymentMethod().sort();
}

export function valueFromTranslationPaymentMethod(translation: string): PaymentMethod {
    const index = allTranslatedValuesPaymentMethod().indexOf(translation);
    return allValuesPaymentMethod()[index] || PaymentMethod.pix;
}

export enum AnimalSex {
    male = "male",
    female = "female",
}

export function translateAnimalSex(enumAnimalSex: AnimalSex): string {
    switch (enumAnimalSex) {
        case AnimalSex.male: {
            return strings ? strings["enum"]["AnimalSex"]["male"] || AnimalSex.male : AnimalSex.male;
        }
        case AnimalSex.female: {
            return strings ? strings["enum"]["AnimalSex"]["female"] || AnimalSex.female : AnimalSex.female;
        }
    }
    return "";
}

export function allValuesAnimalSex(): AnimalSex[] {
    return [
        AnimalSex.male,
        AnimalSex.female,
    ];
}

export function allTranslatedValuesAnimalSex(): string[] {
    return [
        translateAnimalSex(AnimalSex.male),
        translateAnimalSex(AnimalSex.female),
    ];
}

export function allDisplayableValuesAnimalSex(): string[] {
    return allTranslatedValuesAnimalSex().sort();
}

export function valueFromTranslationAnimalSex(translation: string): AnimalSex {
    const index = allTranslatedValuesAnimalSex().indexOf(translation);
    return allValuesAnimalSex()[index] || AnimalSex.male;
}

export enum RatingStatus {
    pending = "pending",
    rated = "rated",
}

export function translateRatingStatus(enumRatingStatus: RatingStatus): string {
    switch (enumRatingStatus) {
        case RatingStatus.pending: {
            return strings ? strings["enum"]["RatingStatus"]["pending"] || RatingStatus.pending : RatingStatus.pending;
        }
        case RatingStatus.rated: {
            return strings ? strings["enum"]["RatingStatus"]["rated"] || RatingStatus.rated : RatingStatus.rated;
        }
    }
    return "";
}

export function allValuesRatingStatus(): RatingStatus[] {
    return [
        RatingStatus.pending,
        RatingStatus.rated,
    ];
}

export function allTranslatedValuesRatingStatus(): string[] {
    return [
        translateRatingStatus(RatingStatus.pending),
        translateRatingStatus(RatingStatus.rated),
    ];
}

export function allDisplayableValuesRatingStatus(): string[] {
    return allTranslatedValuesRatingStatus().sort();
}

export function valueFromTranslationRatingStatus(translation: string): RatingStatus {
    const index = allTranslatedValuesRatingStatus().indexOf(translation);
    return allValuesRatingStatus()[index] || RatingStatus.pending;
}

export enum PetParents {
    father = "father",
    mother = "mother",
}

export function translatePetParents(enumPetParents: PetParents): string {
    switch (enumPetParents) {
        case PetParents.father: {
            return strings ? strings["enum"]["PetParents"]["father"] || PetParents.father : PetParents.father;
        }
        case PetParents.mother: {
            return strings ? strings["enum"]["PetParents"]["mother"] || PetParents.mother : PetParents.mother;
        }
    }
    return "";
}

export function allValuesPetParents(): PetParents[] {
    return [
        PetParents.father,
        PetParents.mother,
    ];
}

export function allTranslatedValuesPetParents(): string[] {
    return [
        translatePetParents(PetParents.father),
        translatePetParents(PetParents.mother),
    ];
}

export function allDisplayableValuesPetParents(): string[] {
    return allTranslatedValuesPetParents().sort();
}

export function valueFromTranslationPetParents(translation: string): PetParents {
    const index = allTranslatedValuesPetParents().indexOf(translation);
    return allValuesPetParents()[index] || PetParents.father;
}

export enum Species {
    dog = "dog",
    cat = "cat",
    rodents = "rodents",
    reptiles = "reptiles",
    fish = "fish",
}

export function translateSpecies(enumSpecies: Species): string {
    switch (enumSpecies) {
        case Species.dog: {
            return strings ? strings["enum"]["Species"]["dog"] || Species.dog : Species.dog;
        }
        case Species.cat: {
            return strings ? strings["enum"]["Species"]["cat"] || Species.cat : Species.cat;
        }
        case Species.rodents: {
            return strings ? strings["enum"]["Species"]["rodents"] || Species.rodents : Species.rodents;
        }
        case Species.reptiles: {
            return strings ? strings["enum"]["Species"]["reptiles"] || Species.reptiles : Species.reptiles;
        }
        case Species.fish: {
            return strings ? strings["enum"]["Species"]["fish"] || Species.fish : Species.fish;
        }
    }
    return "";
}

export function allValuesSpecies(): Species[] {
    return [
        Species.dog,
        Species.cat,
        Species.rodents,
        Species.reptiles,
        Species.fish,
    ];
}

export function allTranslatedValuesSpecies(): string[] {
    return [
        translateSpecies(Species.dog),
        translateSpecies(Species.cat),
        translateSpecies(Species.rodents),
        translateSpecies(Species.reptiles),
        translateSpecies(Species.fish),
    ];
}

export function allDisplayableValuesSpecies(): string[] {
    return allTranslatedValuesSpecies().sort();
}

export function valueFromTranslationSpecies(translation: string): Species {
    const index = allTranslatedValuesSpecies().indexOf(translation);
    return allValuesSpecies()[index] || Species.dog;
}

export enum NotificationType {
    consultation = "consultation",
    hospitalization = "hospitalization",
    exam = "exam",
    vaccine = "vaccine",
    medication = "medication",
}

export function translateNotificationType(enumNotificationType: NotificationType): string {
    switch (enumNotificationType) {
        case NotificationType.consultation: {
            return strings ? strings["enum"]["NotificationType"]["consultation"] || NotificationType.consultation : NotificationType.consultation;
        }
        case NotificationType.hospitalization: {
            return strings ? strings["enum"]["NotificationType"]["hospitalization"] || NotificationType.hospitalization : NotificationType.hospitalization;
        }
        case NotificationType.exam: {
            return strings ? strings["enum"]["NotificationType"]["exam"] || NotificationType.exam : NotificationType.exam;
        }
        case NotificationType.vaccine: {
            return strings ? strings["enum"]["NotificationType"]["vaccine"] || NotificationType.vaccine : NotificationType.vaccine;
        }
        case NotificationType.medication: {
            return strings ? strings["enum"]["NotificationType"]["medication"] || NotificationType.medication : NotificationType.medication;
        }
    }
    return "";
}

export function allValuesNotificationType(): NotificationType[] {
    return [
        NotificationType.consultation,
        NotificationType.hospitalization,
        NotificationType.exam,
        NotificationType.vaccine,
        NotificationType.medication,
    ];
}

export function allTranslatedValuesNotificationType(): string[] {
    return [
        translateNotificationType(NotificationType.consultation),
        translateNotificationType(NotificationType.hospitalization),
        translateNotificationType(NotificationType.exam),
        translateNotificationType(NotificationType.vaccine),
        translateNotificationType(NotificationType.medication),
    ];
}

export function allDisplayableValuesNotificationType(): string[] {
    return allTranslatedValuesNotificationType().sort();
}

export function valueFromTranslationNotificationType(translation: string): NotificationType {
    const index = allTranslatedValuesNotificationType().indexOf(translation);
    return allValuesNotificationType()[index] || NotificationType.consultation;
}

export enum AdminUserType {
    master = "master",
    advisor = "advisor",
}

export function translateAdminUserType(enumAdminUserType: AdminUserType): string {
    switch (enumAdminUserType) {
        case AdminUserType.master: {
            return strings ? strings["enum"]["AdminUserType"]["master"] || AdminUserType.master : AdminUserType.master;
        }
        case AdminUserType.advisor: {
            return strings ? strings["enum"]["AdminUserType"]["advisor"] || AdminUserType.advisor : AdminUserType.advisor;
        }
    }
    return "";
}

export function allValuesAdminUserType(): AdminUserType[] {
    return [
        AdminUserType.master,
        AdminUserType.advisor,
    ];
}

export function allTranslatedValuesAdminUserType(): string[] {
    return [
        translateAdminUserType(AdminUserType.master),
        translateAdminUserType(AdminUserType.advisor),
    ];
}

export function allDisplayableValuesAdminUserType(): string[] {
    return allTranslatedValuesAdminUserType().sort();
}

export function valueFromTranslationAdminUserType(translation: string): AdminUserType {
    const index = allTranslatedValuesAdminUserType().indexOf(translation);
    return allValuesAdminUserType()[index] || AdminUserType.master;
}

export enum ImageFormat {
    png = "png",
    jpeg = "jpeg",
}

export function translateImageFormat(enumImageFormat: ImageFormat): string {
    switch (enumImageFormat) {
        case ImageFormat.png: {
            return strings ? strings["enum"]["ImageFormat"]["png"] || ImageFormat.png : ImageFormat.png;
        }
        case ImageFormat.jpeg: {
            return strings ? strings["enum"]["ImageFormat"]["jpeg"] || ImageFormat.jpeg : ImageFormat.jpeg;
        }
    }
    return "";
}

export function allValuesImageFormat(): ImageFormat[] {
    return [
        ImageFormat.png,
        ImageFormat.jpeg,
    ];
}

export function allTranslatedValuesImageFormat(): string[] {
    return [
        translateImageFormat(ImageFormat.png),
        translateImageFormat(ImageFormat.jpeg),
    ];
}

export function allDisplayableValuesImageFormat(): string[] {
    return allTranslatedValuesImageFormat().sort();
}

export function valueFromTranslationImageFormat(translation: string): ImageFormat {
    const index = allTranslatedValuesImageFormat().indexOf(translation);
    return allValuesImageFormat()[index] || ImageFormat.png;
}

export enum FileFormat {
    pdf = "pdf",
}

export function translateFileFormat(enumFileFormat: FileFormat): string {
    switch (enumFileFormat) {
        case FileFormat.pdf: {
            return strings ? strings["enum"]["FileFormat"]["pdf"] || FileFormat.pdf : FileFormat.pdf;
        }
    }
    return "";
}

export function allValuesFileFormat(): FileFormat[] {
    return [
        FileFormat.pdf,
    ];
}

export function allTranslatedValuesFileFormat(): string[] {
    return [
        translateFileFormat(FileFormat.pdf),
    ];
}

export function allDisplayableValuesFileFormat(): string[] {
    return allTranslatedValuesFileFormat().sort();
}

export function valueFromTranslationFileFormat(translation: string): FileFormat {
    const index = allTranslatedValuesFileFormat().indexOf(translation);
    return allValuesFileFormat()[index] || FileFormat.pdf;
}

export enum ErrorType {
    NotFound = "NotFound",
    MissingArgument = "MissingArgument",
    InvalidArgument = "InvalidArgument",
    InvalidPermission = "InvalidPermission",
    BadFormattedResponse = "BadFormattedResponse",
    InternalError = "InternalError",
    Validation = "Validation",
    EmailOrPasswordWrong = "EmailOrPasswordWrong",
    AccessNotAllowed = "AccessNotAllowed",
    NotLoggedIn = "NotLoggedIn",
    EmailAlreadyRegistered = "EmailAlreadyRegistered",
    AlreadyRegistered = "AlreadyRegistered",
    AlreadyFinished = "AlreadyFinished",
    IsAfterDate = "IsAfterDate",
    IsBeforeDate = "IsBeforeDate",
    Fatal = "Fatal",
    Connection = "Connection",
}

export function translateErrorType(enumErrorType: ErrorType): string {
    switch (enumErrorType) {
        case ErrorType.NotFound: {
            return strings ? strings["enum"]["ErrorType"]["NotFound"] || ErrorType.NotFound : ErrorType.NotFound;
        }
        case ErrorType.MissingArgument: {
            return strings ? strings["enum"]["ErrorType"]["MissingArgument"] || ErrorType.MissingArgument : ErrorType.MissingArgument;
        }
        case ErrorType.InvalidArgument: {
            return strings ? strings["enum"]["ErrorType"]["InvalidArgument"] || ErrorType.InvalidArgument : ErrorType.InvalidArgument;
        }
        case ErrorType.InvalidPermission: {
            return strings ? strings["enum"]["ErrorType"]["InvalidPermission"] || ErrorType.InvalidPermission : ErrorType.InvalidPermission;
        }
        case ErrorType.BadFormattedResponse: {
            return strings ? strings["enum"]["ErrorType"]["BadFormattedResponse"] || ErrorType.BadFormattedResponse : ErrorType.BadFormattedResponse;
        }
        case ErrorType.InternalError: {
            return strings ? strings["enum"]["ErrorType"]["InternalError"] || ErrorType.InternalError : ErrorType.InternalError;
        }
        case ErrorType.Validation: {
            return strings ? strings["enum"]["ErrorType"]["Validation"] || ErrorType.Validation : ErrorType.Validation;
        }
        case ErrorType.EmailOrPasswordWrong: {
            return strings ? strings["enum"]["ErrorType"]["EmailOrPasswordWrong"] || ErrorType.EmailOrPasswordWrong : ErrorType.EmailOrPasswordWrong;
        }
        case ErrorType.AccessNotAllowed: {
            return strings ? strings["enum"]["ErrorType"]["AccessNotAllowed"] || ErrorType.AccessNotAllowed : ErrorType.AccessNotAllowed;
        }
        case ErrorType.NotLoggedIn: {
            return strings ? strings["enum"]["ErrorType"]["NotLoggedIn"] || ErrorType.NotLoggedIn : ErrorType.NotLoggedIn;
        }
        case ErrorType.EmailAlreadyRegistered: {
            return strings ? strings["enum"]["ErrorType"]["EmailAlreadyRegistered"] || ErrorType.EmailAlreadyRegistered : ErrorType.EmailAlreadyRegistered;
        }
        case ErrorType.AlreadyRegistered: {
            return strings ? strings["enum"]["ErrorType"]["AlreadyRegistered"] || ErrorType.AlreadyRegistered : ErrorType.AlreadyRegistered;
        }
        case ErrorType.AlreadyFinished: {
            return strings ? strings["enum"]["ErrorType"]["AlreadyFinished"] || ErrorType.AlreadyFinished : ErrorType.AlreadyFinished;
        }
        case ErrorType.IsAfterDate: {
            return strings ? strings["enum"]["ErrorType"]["IsAfterDate"] || ErrorType.IsAfterDate : ErrorType.IsAfterDate;
        }
        case ErrorType.IsBeforeDate: {
            return strings ? strings["enum"]["ErrorType"]["IsBeforeDate"] || ErrorType.IsBeforeDate : ErrorType.IsBeforeDate;
        }
        case ErrorType.Fatal: {
            return strings ? strings["enum"]["ErrorType"]["Fatal"] || ErrorType.Fatal : ErrorType.Fatal;
        }
        case ErrorType.Connection: {
            return strings ? strings["enum"]["ErrorType"]["Connection"] || ErrorType.Connection : ErrorType.Connection;
        }
    }
    return "";
}

export function allValuesErrorType(): ErrorType[] {
    return [
        ErrorType.NotFound,
        ErrorType.MissingArgument,
        ErrorType.InvalidArgument,
        ErrorType.InvalidPermission,
        ErrorType.BadFormattedResponse,
        ErrorType.InternalError,
        ErrorType.Validation,
        ErrorType.EmailOrPasswordWrong,
        ErrorType.AccessNotAllowed,
        ErrorType.NotLoggedIn,
        ErrorType.EmailAlreadyRegistered,
        ErrorType.AlreadyRegistered,
        ErrorType.AlreadyFinished,
        ErrorType.IsAfterDate,
        ErrorType.IsBeforeDate,
        ErrorType.Fatal,
        ErrorType.Connection,
    ];
}

export function allTranslatedValuesErrorType(): string[] {
    return [
        translateErrorType(ErrorType.NotFound),
        translateErrorType(ErrorType.MissingArgument),
        translateErrorType(ErrorType.InvalidArgument),
        translateErrorType(ErrorType.InvalidPermission),
        translateErrorType(ErrorType.BadFormattedResponse),
        translateErrorType(ErrorType.InternalError),
        translateErrorType(ErrorType.Validation),
        translateErrorType(ErrorType.EmailOrPasswordWrong),
        translateErrorType(ErrorType.AccessNotAllowed),
        translateErrorType(ErrorType.NotLoggedIn),
        translateErrorType(ErrorType.EmailAlreadyRegistered),
        translateErrorType(ErrorType.AlreadyRegistered),
        translateErrorType(ErrorType.AlreadyFinished),
        translateErrorType(ErrorType.IsAfterDate),
        translateErrorType(ErrorType.IsBeforeDate),
        translateErrorType(ErrorType.Fatal),
        translateErrorType(ErrorType.Connection),
    ];
}

export function allDisplayableValuesErrorType(): string[] {
    return allTranslatedValuesErrorType().sort();
}

export function valueFromTranslationErrorType(translation: string): ErrorType {
    const index = allTranslatedValuesErrorType().indexOf(translation);
    return allValuesErrorType()[index] || ErrorType.NotFound;
}

export async function uploadUncertainFile(file: UncertainFile, fileFormat: FileFormat | null, progress?: (progress: number) => void): Promise<string> {
    const args = {
        file: {
            bytes: file.bytes === null || file.bytes === undefined ? null : file.bytes.toString("base64"),
            url: file.url === null || file.url === undefined ? null : file.url,
        },
        fileFormat: fileFormat === null || fileFormat === undefined ? null : fileFormat,
    };
    const ret = await makeRequest({name: "uploadUncertainFile", args, progress});
    return ret;
}

export async function uploadImage(image: Buffer, imageFormat: ImageFormat | null, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: image.toString("base64"),
        imageFormat: imageFormat === null || imageFormat === undefined ? null : imageFormat,
    };
    const ret = await makeRequest({name: "uploadImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function uploadUncertainImage(image: UncertainImage, imageFormat: ImageFormat | null, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: {
            bytes: image.bytes === null || image.bytes === undefined ? null : image.bytes.toString("base64"),
            image: image.image === null || image.image === undefined ? null : {
                thumb: {
                    width: image.image.thumb.width || 0,
                    height: image.image.thumb.height || 0,
                    url: image.image.thumb.url,
                },
                width: image.image.width || 0,
                height: image.image.height || 0,
                url: image.image.url,
            },
        },
        imageFormat: imageFormat === null || imageFormat === undefined ? null : imageFormat,
    };
    const ret = await makeRequest({name: "uploadUncertainImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function uploadUncompressedImage(image: Buffer, imageFormat: ImageFormat | null, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: image.toString("base64"),
        imageFormat: imageFormat === null || imageFormat === undefined ? null : imageFormat,
    };
    const ret = await makeRequest({name: "uploadUncompressedImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function uploadUncompressedUncertainImage(image: UncertainImage, imageFormat: ImageFormat | null, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: {
            bytes: image.bytes === null || image.bytes === undefined ? null : image.bytes.toString("base64"),
            image: image.image === null || image.image === undefined ? null : {
                thumb: {
                    width: image.image.thumb.width || 0,
                    height: image.image.thumb.height || 0,
                    url: image.image.thumb.url,
                },
                width: image.image.width || 0,
                height: image.image.height || 0,
                url: image.image.url,
            },
        },
        imageFormat: imageFormat === null || imageFormat === undefined ? null : imageFormat,
    };
    const ret = await makeRequest({name: "uploadUncompressedUncertainImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function getOneAdminUserById(id: string, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getOneAdminUserById", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        email: ret.email,
        password: ret.password,
        type: ret.type,
    };
}

export async function getAllAdminUsers(pageOffset: number, filterByAdminType: AdminUserType | null, name: string | null, progress?: (progress: number) => void): Promise<AdminUser[]> {
    const args = {
        pageOffset: pageOffset || 0,
        filterByAdminType: filterByAdminType === null || filterByAdminType === undefined ? null : filterByAdminType,
        name: name === null || name === undefined ? null : name,
    };
    const ret = await makeRequest({name: "getAllAdminUsers", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        email: e.email,
        password: e.password,
        type: e.type,
    }));
}

export async function adminLogin(email: string, password: string, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        email: email,
        password: password,
    };
    const ret = await makeRequest({name: "adminLogin", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        email: ret.email,
        password: ret.password,
        type: ret.type,
    };
}

export async function createAdminUser(newAdminUser: EditAdminUser, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        newAdminUser: {
            name: newAdminUser.name,
            email: newAdminUser.email,
            password: newAdminUser.password,
        },
    };
    const ret = await makeRequest({name: "createAdminUser", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        email: ret.email,
        password: ret.password,
        type: ret.type,
    };
}

export async function createAdminAdvisor(newAdminUser: EditAdminUser, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        newAdminUser: {
            name: newAdminUser.name,
            email: newAdminUser.email,
            password: newAdminUser.password,
        },
    };
    const ret = await makeRequest({name: "createAdminAdvisor", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        email: ret.email,
        password: ret.password,
        type: ret.type,
    };
}

export async function editAdminUser(id: string, editAdminUser: EditAdminUser, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        id: id,
        editAdminUser: {
            name: editAdminUser.name,
            email: editAdminUser.email,
            password: editAdminUser.password,
        },
    };
    const ret = await makeRequest({name: "editAdminUser", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        email: ret.email,
        password: ret.password,
        type: ret.type,
    };
}

export async function deleteAdminUser(id: string, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "deleteAdminUser", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        email: ret.email,
        password: ret.password,
        type: ret.type,
    };
}

export async function logoutAdminUser(progress?: (progress: number) => void): Promise<void> {
    await makeRequest({name: "logoutAdminUser", args: {}, progress});
    return undefined;
}

export async function getCurrentAdminLogged(progress?: (progress: number) => void): Promise<AdminUser> {
    const ret = await makeRequest({name: "getCurrentAdminLogged", args: {}, progress});
    return {
        id: ret.id,
        name: ret.name,
        email: ret.email,
        password: ret.password,
        type: ret.type,
    };
}

export async function deleteUserForAdmin(id: string, progress?: (progress: number) => void): Promise<User> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "deleteUserForAdmin", args, progress});
    return {
        id: ret.id,
        email: ret.email,
        name: ret.name,
        password: ret.password,
        documentNumber: ret.documentNumber,
        whatsapp: ret.whatsapp === null || ret.whatsapp === undefined ? null : ret.whatsapp,
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        agency: ret.agency === null || ret.agency === undefined ? null : ret.agency,
        agencyDv: ret.agencyDv === null || ret.agencyDv === undefined ? null : ret.agencyDv,
        account: ret.account === null || ret.account === undefined ? null : ret.account,
        accountDv: ret.accountDv === null || ret.accountDv === undefined ? null : ret.accountDv,
        bankName: ret.bankName === null || ret.bankName === undefined ? null : ret.bankName,
    };
}

export async function editUser(id: string, editUser: NewUser, progress?: (progress: number) => void): Promise<UserWithAdvisor> {
    const args = {
        id: id,
        editUser: {
            advisor: {
                id: editUser.advisor.id,
                name: editUser.advisor.name,
                email: editUser.advisor.email,
                password: editUser.advisor.password,
                type: editUser.advisor.type,
            },
            email: editUser.email,
            name: editUser.name,
            password: editUser.password,
            documentNumber: editUser.documentNumber,
            whatsapp: editUser.whatsapp === null || editUser.whatsapp === undefined ? null : editUser.whatsapp,
            phone: editUser.phone === null || editUser.phone === undefined ? null : editUser.phone,
            agency: editUser.agency === null || editUser.agency === undefined ? null : editUser.agency,
            agencyDv: editUser.agencyDv === null || editUser.agencyDv === undefined ? null : editUser.agencyDv,
            account: editUser.account === null || editUser.account === undefined ? null : editUser.account,
            accountDv: editUser.accountDv === null || editUser.accountDv === undefined ? null : editUser.accountDv,
            bankName: editUser.bankName === null || editUser.bankName === undefined ? null : editUser.bankName,
        },
    };
    const ret = await makeRequest({name: "editUser", args, progress});
    return {
        advisor: ret.advisor === null || ret.advisor === undefined ? null : {
            id: ret.advisor.id,
            name: ret.advisor.name,
            email: ret.advisor.email,
            password: ret.advisor.password,
            type: ret.advisor.type,
        },
        id: ret.id,
        email: ret.email,
        name: ret.name,
        password: ret.password,
        documentNumber: ret.documentNumber,
        whatsapp: ret.whatsapp === null || ret.whatsapp === undefined ? null : ret.whatsapp,
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        agency: ret.agency === null || ret.agency === undefined ? null : ret.agency,
        agencyDv: ret.agencyDv === null || ret.agencyDv === undefined ? null : ret.agencyDv,
        account: ret.account === null || ret.account === undefined ? null : ret.account,
        accountDv: ret.accountDv === null || ret.accountDv === undefined ? null : ret.accountDv,
        bankName: ret.bankName === null || ret.bankName === undefined ? null : ret.bankName,
    };
}

export async function createUser(newUser: NewUser, progress?: (progress: number) => void): Promise<User> {
    const args = {
        newUser: {
            advisor: {
                id: newUser.advisor.id,
                name: newUser.advisor.name,
                email: newUser.advisor.email,
                password: newUser.advisor.password,
                type: newUser.advisor.type,
            },
            email: newUser.email,
            name: newUser.name,
            password: newUser.password,
            documentNumber: newUser.documentNumber,
            whatsapp: newUser.whatsapp === null || newUser.whatsapp === undefined ? null : newUser.whatsapp,
            phone: newUser.phone === null || newUser.phone === undefined ? null : newUser.phone,
            agency: newUser.agency === null || newUser.agency === undefined ? null : newUser.agency,
            agencyDv: newUser.agencyDv === null || newUser.agencyDv === undefined ? null : newUser.agencyDv,
            account: newUser.account === null || newUser.account === undefined ? null : newUser.account,
            accountDv: newUser.accountDv === null || newUser.accountDv === undefined ? null : newUser.accountDv,
            bankName: newUser.bankName === null || newUser.bankName === undefined ? null : newUser.bankName,
        },
    };
    const ret = await makeRequest({name: "createUser", args, progress});
    return {
        id: ret.id,
        email: ret.email,
        name: ret.name,
        password: ret.password,
        documentNumber: ret.documentNumber,
        whatsapp: ret.whatsapp === null || ret.whatsapp === undefined ? null : ret.whatsapp,
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        agency: ret.agency === null || ret.agency === undefined ? null : ret.agency,
        agencyDv: ret.agencyDv === null || ret.agencyDv === undefined ? null : ret.agencyDv,
        account: ret.account === null || ret.account === undefined ? null : ret.account,
        accountDv: ret.accountDv === null || ret.accountDv === undefined ? null : ret.accountDv,
        bankName: ret.bankName === null || ret.bankName === undefined ? null : ret.bankName,
    };
}

export async function getUser(id: string, progress?: (progress: number) => void): Promise<UserWithAdvisor> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getUser", args, progress});
    return {
        advisor: ret.advisor === null || ret.advisor === undefined ? null : {
            id: ret.advisor.id,
            name: ret.advisor.name,
            email: ret.advisor.email,
            password: ret.advisor.password,
            type: ret.advisor.type,
        },
        id: ret.id,
        email: ret.email,
        name: ret.name,
        password: ret.password,
        documentNumber: ret.documentNumber,
        whatsapp: ret.whatsapp === null || ret.whatsapp === undefined ? null : ret.whatsapp,
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        agency: ret.agency === null || ret.agency === undefined ? null : ret.agency,
        agencyDv: ret.agencyDv === null || ret.agencyDv === undefined ? null : ret.agencyDv,
        account: ret.account === null || ret.account === undefined ? null : ret.account,
        accountDv: ret.accountDv === null || ret.accountDv === undefined ? null : ret.accountDv,
        bankName: ret.bankName === null || ret.bankName === undefined ? null : ret.bankName,
    };
}

export async function getAllUsers(pageOffSet: number, name: string | null, progress?: (progress: number) => void): Promise<User[]> {
    const args = {
        pageOffSet: pageOffSet || 0,
        name: name === null || name === undefined ? null : name,
    };
    const ret = await makeRequest({name: "getAllUsers", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        email: e.email,
        name: e.name,
        password: e.password,
        documentNumber: e.documentNumber,
        whatsapp: e.whatsapp === null || e.whatsapp === undefined ? null : e.whatsapp,
        phone: e.phone === null || e.phone === undefined ? null : e.phone,
        agency: e.agency === null || e.agency === undefined ? null : e.agency,
        agencyDv: e.agencyDv === null || e.agencyDv === undefined ? null : e.agencyDv,
        account: e.account === null || e.account === undefined ? null : e.account,
        accountDv: e.accountDv === null || e.accountDv === undefined ? null : e.accountDv,
        bankName: e.bankName === null || e.bankName === undefined ? null : e.bankName,
    }));
}

export async function getAllUsersToAutoComplete(pageOffSet: number, name: string | null, progress?: (progress: number) => void): Promise<User[]> {
    const args = {
        pageOffSet: pageOffSet || 0,
        name: name === null || name === undefined ? null : name,
    };
    const ret = await makeRequest({name: "getAllUsersToAutoComplete", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        email: e.email,
        name: e.name,
        password: e.password,
        documentNumber: e.documentNumber,
        whatsapp: e.whatsapp === null || e.whatsapp === undefined ? null : e.whatsapp,
        phone: e.phone === null || e.phone === undefined ? null : e.phone,
        agency: e.agency === null || e.agency === undefined ? null : e.agency,
        agencyDv: e.agencyDv === null || e.agencyDv === undefined ? null : e.agencyDv,
        account: e.account === null || e.account === undefined ? null : e.account,
        accountDv: e.accountDv === null || e.accountDv === undefined ? null : e.accountDv,
        bankName: e.bankName === null || e.bankName === undefined ? null : e.bankName,
    }));
}

export async function getAllIncomeTaxByUser(pageOffSet: number, userId: string, progress?: (progress: number) => void): Promise<IncomeTax[]> {
    const args = {
        pageOffSet: pageOffSet || 0,
        userId: userId,
    };
    const ret = await makeRequest({name: "getAllIncomeTaxByUser", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        user: {
            id: e.user.id,
            email: e.user.email,
            name: e.user.name,
            password: e.user.password,
            documentNumber: e.user.documentNumber,
            whatsapp: e.user.whatsapp === null || e.user.whatsapp === undefined ? null : e.user.whatsapp,
            phone: e.user.phone === null || e.user.phone === undefined ? null : e.user.phone,
            agency: e.user.agency === null || e.user.agency === undefined ? null : e.user.agency,
            agencyDv: e.user.agencyDv === null || e.user.agencyDv === undefined ? null : e.user.agencyDv,
            account: e.user.account === null || e.user.account === undefined ? null : e.user.account,
            accountDv: e.user.accountDv === null || e.user.accountDv === undefined ? null : e.user.accountDv,
            bankName: e.user.bankName === null || e.user.bankName === undefined ? null : e.user.bankName,
        },
        year: e.year,
        files: e.files,
        createdAt: new Date(e.createdAt + "Z"),
        updatedAt: new Date(e.updatedAt + "Z"),
        deletedAt: e.deletedAt === null || e.deletedAt === undefined ? null : new Date(e.deletedAt + "Z"),
    }));
}

export async function getIncomeTaxById(id: string, progress?: (progress: number) => void): Promise<IncomeTax> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getIncomeTaxById", args, progress});
    return {
        id: ret.id,
        user: {
            id: ret.user.id,
            email: ret.user.email,
            name: ret.user.name,
            password: ret.user.password,
            documentNumber: ret.user.documentNumber,
            whatsapp: ret.user.whatsapp === null || ret.user.whatsapp === undefined ? null : ret.user.whatsapp,
            phone: ret.user.phone === null || ret.user.phone === undefined ? null : ret.user.phone,
            agency: ret.user.agency === null || ret.user.agency === undefined ? null : ret.user.agency,
            agencyDv: ret.user.agencyDv === null || ret.user.agencyDv === undefined ? null : ret.user.agencyDv,
            account: ret.user.account === null || ret.user.account === undefined ? null : ret.user.account,
            accountDv: ret.user.accountDv === null || ret.user.accountDv === undefined ? null : ret.user.accountDv,
            bankName: ret.user.bankName === null || ret.user.bankName === undefined ? null : ret.user.bankName,
        },
        year: ret.year,
        files: ret.files,
        createdAt: new Date(ret.createdAt + "Z"),
        updatedAt: new Date(ret.updatedAt + "Z"),
        deletedAt: ret.deletedAt === null || ret.deletedAt === undefined ? null : new Date(ret.deletedAt + "Z"),
    };
}

export async function createIncomeTax(userId: string, newIncomeTax: NewIncomeTax, progress?: (progress: number) => void): Promise<IncomeTax> {
    const args = {
        userId: userId,
        newIncomeTax: {
            userId: newIncomeTax.userId,
            year: newIncomeTax.year,
            files: {
                bytes: newIncomeTax.files.bytes === null || newIncomeTax.files.bytes === undefined ? null : newIncomeTax.files.bytes.toString("base64"),
                url: newIncomeTax.files.url === null || newIncomeTax.files.url === undefined ? null : newIncomeTax.files.url,
            },
        },
    };
    const ret = await makeRequest({name: "createIncomeTax", args, progress});
    return {
        id: ret.id,
        user: {
            id: ret.user.id,
            email: ret.user.email,
            name: ret.user.name,
            password: ret.user.password,
            documentNumber: ret.user.documentNumber,
            whatsapp: ret.user.whatsapp === null || ret.user.whatsapp === undefined ? null : ret.user.whatsapp,
            phone: ret.user.phone === null || ret.user.phone === undefined ? null : ret.user.phone,
            agency: ret.user.agency === null || ret.user.agency === undefined ? null : ret.user.agency,
            agencyDv: ret.user.agencyDv === null || ret.user.agencyDv === undefined ? null : ret.user.agencyDv,
            account: ret.user.account === null || ret.user.account === undefined ? null : ret.user.account,
            accountDv: ret.user.accountDv === null || ret.user.accountDv === undefined ? null : ret.user.accountDv,
            bankName: ret.user.bankName === null || ret.user.bankName === undefined ? null : ret.user.bankName,
        },
        year: ret.year,
        files: ret.files,
        createdAt: new Date(ret.createdAt + "Z"),
        updatedAt: new Date(ret.updatedAt + "Z"),
        deletedAt: ret.deletedAt === null || ret.deletedAt === undefined ? null : new Date(ret.deletedAt + "Z"),
    };
}

export async function editIncomeTax(id: string, editNewIncomeTax: EditIncomeTax, progress?: (progress: number) => void): Promise<IncomeTax> {
    const args = {
        id: id,
        editNewIncomeTax: {
            year: editNewIncomeTax.year,
            files: {
                bytes: editNewIncomeTax.files.bytes === null || editNewIncomeTax.files.bytes === undefined ? null : editNewIncomeTax.files.bytes.toString("base64"),
                url: editNewIncomeTax.files.url === null || editNewIncomeTax.files.url === undefined ? null : editNewIncomeTax.files.url,
            },
        },
    };
    const ret = await makeRequest({name: "editIncomeTax", args, progress});
    return {
        id: ret.id,
        user: {
            id: ret.user.id,
            email: ret.user.email,
            name: ret.user.name,
            password: ret.user.password,
            documentNumber: ret.user.documentNumber,
            whatsapp: ret.user.whatsapp === null || ret.user.whatsapp === undefined ? null : ret.user.whatsapp,
            phone: ret.user.phone === null || ret.user.phone === undefined ? null : ret.user.phone,
            agency: ret.user.agency === null || ret.user.agency === undefined ? null : ret.user.agency,
            agencyDv: ret.user.agencyDv === null || ret.user.agencyDv === undefined ? null : ret.user.agencyDv,
            account: ret.user.account === null || ret.user.account === undefined ? null : ret.user.account,
            accountDv: ret.user.accountDv === null || ret.user.accountDv === undefined ? null : ret.user.accountDv,
            bankName: ret.user.bankName === null || ret.user.bankName === undefined ? null : ret.user.bankName,
        },
        year: ret.year,
        files: ret.files,
        createdAt: new Date(ret.createdAt + "Z"),
        updatedAt: new Date(ret.updatedAt + "Z"),
        deletedAt: ret.deletedAt === null || ret.deletedAt === undefined ? null : new Date(ret.deletedAt + "Z"),
    };
}

export async function deleteIncomeTax(id: string, progress?: (progress: number) => void): Promise<IncomeTax> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "deleteIncomeTax", args, progress});
    return {
        id: ret.id,
        user: {
            id: ret.user.id,
            email: ret.user.email,
            name: ret.user.name,
            password: ret.user.password,
            documentNumber: ret.user.documentNumber,
            whatsapp: ret.user.whatsapp === null || ret.user.whatsapp === undefined ? null : ret.user.whatsapp,
            phone: ret.user.phone === null || ret.user.phone === undefined ? null : ret.user.phone,
            agency: ret.user.agency === null || ret.user.agency === undefined ? null : ret.user.agency,
            agencyDv: ret.user.agencyDv === null || ret.user.agencyDv === undefined ? null : ret.user.agencyDv,
            account: ret.user.account === null || ret.user.account === undefined ? null : ret.user.account,
            accountDv: ret.user.accountDv === null || ret.user.accountDv === undefined ? null : ret.user.accountDv,
            bankName: ret.user.bankName === null || ret.user.bankName === undefined ? null : ret.user.bankName,
        },
        year: ret.year,
        files: ret.files,
        createdAt: new Date(ret.createdAt + "Z"),
        updatedAt: new Date(ret.updatedAt + "Z"),
        deletedAt: ret.deletedAt === null || ret.deletedAt === undefined ? null : new Date(ret.deletedAt + "Z"),
    };
}

export async function getAllProducts(pageOffset: number, name: string | null, finalized: boolean | null, progress?: (progress: number) => void): Promise<Product[]> {
    const args = {
        pageOffset: pageOffset || 0,
        name: name === null || name === undefined ? null : name,
        finalized: finalized === null || finalized === undefined ? null : !!finalized,
    };
    const ret = await makeRequest({name: "getAllProducts", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        description: e.description,
        image: e.image === null || e.image === undefined ? null : {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        expirationDate: new Date(e.expirationDate + "Z"),
        finalizedAt: e.finalizedAt === null || e.finalizedAt === undefined ? null : new Date(e.finalizedAt + "Z"),
        createdAt: new Date(e.createdAt + "Z"),
        updatedAt: new Date(e.updatedAt + "Z"),
        incomeTax: e.incomeTax === null || e.incomeTax === undefined ? null : e.incomeTax,
        deletedAt: e.deletedAt === null || e.deletedAt === undefined ? null : new Date(e.deletedAt + "Z"),
    }));
}

export async function getAllProductToAutoComplete(pageOffset: number, name: string | null, progress?: (progress: number) => void): Promise<Product[]> {
    const args = {
        pageOffset: pageOffset || 0,
        name: name === null || name === undefined ? null : name,
    };
    const ret = await makeRequest({name: "getAllProductToAutoComplete", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        description: e.description,
        image: e.image === null || e.image === undefined ? null : {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        expirationDate: new Date(e.expirationDate + "Z"),
        finalizedAt: e.finalizedAt === null || e.finalizedAt === undefined ? null : new Date(e.finalizedAt + "Z"),
        createdAt: new Date(e.createdAt + "Z"),
        updatedAt: new Date(e.updatedAt + "Z"),
        incomeTax: e.incomeTax === null || e.incomeTax === undefined ? null : e.incomeTax,
        deletedAt: e.deletedAt === null || e.deletedAt === undefined ? null : new Date(e.deletedAt + "Z"),
    }));
}

export async function getProductById(id: string, progress?: (progress: number) => void): Promise<Product> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getProductById", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        description: ret.description,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        expirationDate: new Date(ret.expirationDate + "Z"),
        finalizedAt: ret.finalizedAt === null || ret.finalizedAt === undefined ? null : new Date(ret.finalizedAt + "Z"),
        createdAt: new Date(ret.createdAt + "Z"),
        updatedAt: new Date(ret.updatedAt + "Z"),
        incomeTax: ret.incomeTax === null || ret.incomeTax === undefined ? null : ret.incomeTax,
        deletedAt: ret.deletedAt === null || ret.deletedAt === undefined ? null : new Date(ret.deletedAt + "Z"),
    };
}

export async function createProduct(product: NewProduct, progress?: (progress: number) => void): Promise<Product> {
    const args = {
        product: {
            name: product.name,
            description: product.description,
            incomeTax: product.incomeTax === null || product.incomeTax === undefined ? null : {
                bytes: product.incomeTax.bytes === null || product.incomeTax.bytes === undefined ? null : product.incomeTax.bytes.toString("base64"),
                url: product.incomeTax.url === null || product.incomeTax.url === undefined ? null : product.incomeTax.url,
            },
            image: {
                bytes: product.image.bytes === null || product.image.bytes === undefined ? null : product.image.bytes.toString("base64"),
                image: product.image.image === null || product.image.image === undefined ? null : {
                    thumb: {
                        width: product.image.image.thumb.width || 0,
                        height: product.image.image.thumb.height || 0,
                        url: product.image.image.thumb.url,
                    },
                    width: product.image.image.width || 0,
                    height: product.image.image.height || 0,
                    url: product.image.image.url,
                },
            },
            expirationDate: (typeof product.expirationDate === "string" && (product.expirationDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (product.expirationDate as any).replace("Z", "") : product.expirationDate.toISOString().replace("Z", "")),
            finalizedAt: product.finalizedAt === null || product.finalizedAt === undefined ? null : (typeof product.finalizedAt === "string" && (product.finalizedAt as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (product.finalizedAt as any).replace("Z", "") : product.finalizedAt.toISOString().replace("Z", "")),
        },
    };
    const ret = await makeRequest({name: "createProduct", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        description: ret.description,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        expirationDate: new Date(ret.expirationDate + "Z"),
        finalizedAt: ret.finalizedAt === null || ret.finalizedAt === undefined ? null : new Date(ret.finalizedAt + "Z"),
        createdAt: new Date(ret.createdAt + "Z"),
        updatedAt: new Date(ret.updatedAt + "Z"),
        incomeTax: ret.incomeTax === null || ret.incomeTax === undefined ? null : ret.incomeTax,
        deletedAt: ret.deletedAt === null || ret.deletedAt === undefined ? null : new Date(ret.deletedAt + "Z"),
    };
}

export async function editProduct(id: string, editProduct: NewProduct, progress?: (progress: number) => void): Promise<Product> {
    const args = {
        id: id,
        editProduct: {
            name: editProduct.name,
            description: editProduct.description,
            incomeTax: editProduct.incomeTax === null || editProduct.incomeTax === undefined ? null : {
                bytes: editProduct.incomeTax.bytes === null || editProduct.incomeTax.bytes === undefined ? null : editProduct.incomeTax.bytes.toString("base64"),
                url: editProduct.incomeTax.url === null || editProduct.incomeTax.url === undefined ? null : editProduct.incomeTax.url,
            },
            image: {
                bytes: editProduct.image.bytes === null || editProduct.image.bytes === undefined ? null : editProduct.image.bytes.toString("base64"),
                image: editProduct.image.image === null || editProduct.image.image === undefined ? null : {
                    thumb: {
                        width: editProduct.image.image.thumb.width || 0,
                        height: editProduct.image.image.thumb.height || 0,
                        url: editProduct.image.image.thumb.url,
                    },
                    width: editProduct.image.image.width || 0,
                    height: editProduct.image.image.height || 0,
                    url: editProduct.image.image.url,
                },
            },
            expirationDate: (typeof editProduct.expirationDate === "string" && (editProduct.expirationDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (editProduct.expirationDate as any).replace("Z", "") : editProduct.expirationDate.toISOString().replace("Z", "")),
            finalizedAt: editProduct.finalizedAt === null || editProduct.finalizedAt === undefined ? null : (typeof editProduct.finalizedAt === "string" && (editProduct.finalizedAt as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (editProduct.finalizedAt as any).replace("Z", "") : editProduct.finalizedAt.toISOString().replace("Z", "")),
        },
    };
    const ret = await makeRequest({name: "editProduct", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        description: ret.description,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        expirationDate: new Date(ret.expirationDate + "Z"),
        finalizedAt: ret.finalizedAt === null || ret.finalizedAt === undefined ? null : new Date(ret.finalizedAt + "Z"),
        createdAt: new Date(ret.createdAt + "Z"),
        updatedAt: new Date(ret.updatedAt + "Z"),
        incomeTax: ret.incomeTax === null || ret.incomeTax === undefined ? null : ret.incomeTax,
        deletedAt: ret.deletedAt === null || ret.deletedAt === undefined ? null : new Date(ret.deletedAt + "Z"),
    };
}

export async function deleteProduct(id: string, progress?: (progress: number) => void): Promise<Product> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "deleteProduct", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        description: ret.description,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        expirationDate: new Date(ret.expirationDate + "Z"),
        finalizedAt: ret.finalizedAt === null || ret.finalizedAt === undefined ? null : new Date(ret.finalizedAt + "Z"),
        createdAt: new Date(ret.createdAt + "Z"),
        updatedAt: new Date(ret.updatedAt + "Z"),
        incomeTax: ret.incomeTax === null || ret.incomeTax === undefined ? null : ret.incomeTax,
        deletedAt: ret.deletedAt === null || ret.deletedAt === undefined ? null : new Date(ret.deletedAt + "Z"),
    };
}

export async function getAllNotificationByProduct(pageOffSet: number, productId: string, progress?: (progress: number) => void): Promise<Notification[]> {
    const args = {
        pageOffSet: pageOffSet || 0,
        productId: productId,
    };
    const ret = await makeRequest({name: "getAllNotificationByProduct", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        product: {
            id: e.product.id,
            name: e.product.name,
            description: e.product.description,
            image: e.product.image === null || e.product.image === undefined ? null : {
                thumb: {
                    width: e.product.image.thumb.width || 0,
                    height: e.product.image.thumb.height || 0,
                    url: e.product.image.thumb.url,
                },
                width: e.product.image.width || 0,
                height: e.product.image.height || 0,
                url: e.product.image.url,
            },
            expirationDate: new Date(e.product.expirationDate + "Z"),
            finalizedAt: e.product.finalizedAt === null || e.product.finalizedAt === undefined ? null : new Date(e.product.finalizedAt + "Z"),
            createdAt: new Date(e.product.createdAt + "Z"),
            updatedAt: new Date(e.product.updatedAt + "Z"),
            incomeTax: e.product.incomeTax === null || e.product.incomeTax === undefined ? null : e.product.incomeTax,
            deletedAt: e.product.deletedAt === null || e.product.deletedAt === undefined ? null : new Date(e.product.deletedAt + "Z"),
        },
        files: e.files === null || e.files === undefined ? null : e.files.map((e: any) => e),
        description: e.description,
        createdAt: new Date(e.createdAt + "Z"),
        updatedAt: new Date(e.updatedAt + "Z"),
        deletedAt: e.deletedAt === null || e.deletedAt === undefined ? null : new Date(e.deletedAt + "Z"),
    }));
}

export async function getNotificationById(id: string, progress?: (progress: number) => void): Promise<Notification> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getNotificationById", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        product: {
            id: ret.product.id,
            name: ret.product.name,
            description: ret.product.description,
            image: ret.product.image === null || ret.product.image === undefined ? null : {
                thumb: {
                    width: ret.product.image.thumb.width || 0,
                    height: ret.product.image.thumb.height || 0,
                    url: ret.product.image.thumb.url,
                },
                width: ret.product.image.width || 0,
                height: ret.product.image.height || 0,
                url: ret.product.image.url,
            },
            expirationDate: new Date(ret.product.expirationDate + "Z"),
            finalizedAt: ret.product.finalizedAt === null || ret.product.finalizedAt === undefined ? null : new Date(ret.product.finalizedAt + "Z"),
            createdAt: new Date(ret.product.createdAt + "Z"),
            updatedAt: new Date(ret.product.updatedAt + "Z"),
            incomeTax: ret.product.incomeTax === null || ret.product.incomeTax === undefined ? null : ret.product.incomeTax,
            deletedAt: ret.product.deletedAt === null || ret.product.deletedAt === undefined ? null : new Date(ret.product.deletedAt + "Z"),
        },
        files: ret.files === null || ret.files === undefined ? null : ret.files.map((e: any) => e),
        description: ret.description,
        createdAt: new Date(ret.createdAt + "Z"),
        updatedAt: new Date(ret.updatedAt + "Z"),
        deletedAt: ret.deletedAt === null || ret.deletedAt === undefined ? null : new Date(ret.deletedAt + "Z"),
    };
}

export async function createNotification(productId: string, newNotification: NewNotification, progress?: (progress: number) => void): Promise<Notification> {
    const args = {
        productId: productId,
        newNotification: {
            name: newNotification.name,
            description: newNotification.description,
            files: newNotification.files.map(e => ({
                bytes: e.bytes === null || e.bytes === undefined ? null : e.bytes.toString("base64"),
                url: e.url === null || e.url === undefined ? null : e.url,
            })),
        },
    };
    const ret = await makeRequest({name: "createNotification", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        product: {
            id: ret.product.id,
            name: ret.product.name,
            description: ret.product.description,
            image: ret.product.image === null || ret.product.image === undefined ? null : {
                thumb: {
                    width: ret.product.image.thumb.width || 0,
                    height: ret.product.image.thumb.height || 0,
                    url: ret.product.image.thumb.url,
                },
                width: ret.product.image.width || 0,
                height: ret.product.image.height || 0,
                url: ret.product.image.url,
            },
            expirationDate: new Date(ret.product.expirationDate + "Z"),
            finalizedAt: ret.product.finalizedAt === null || ret.product.finalizedAt === undefined ? null : new Date(ret.product.finalizedAt + "Z"),
            createdAt: new Date(ret.product.createdAt + "Z"),
            updatedAt: new Date(ret.product.updatedAt + "Z"),
            incomeTax: ret.product.incomeTax === null || ret.product.incomeTax === undefined ? null : ret.product.incomeTax,
            deletedAt: ret.product.deletedAt === null || ret.product.deletedAt === undefined ? null : new Date(ret.product.deletedAt + "Z"),
        },
        files: ret.files === null || ret.files === undefined ? null : ret.files.map((e: any) => e),
        description: ret.description,
        createdAt: new Date(ret.createdAt + "Z"),
        updatedAt: new Date(ret.updatedAt + "Z"),
        deletedAt: ret.deletedAt === null || ret.deletedAt === undefined ? null : new Date(ret.deletedAt + "Z"),
    };
}

export async function editNotification(id: string, editNotification: NewNotification, progress?: (progress: number) => void): Promise<Notification> {
    const args = {
        id: id,
        editNotification: {
            name: editNotification.name,
            description: editNotification.description,
            files: editNotification.files.map(e => ({
                bytes: e.bytes === null || e.bytes === undefined ? null : e.bytes.toString("base64"),
                url: e.url === null || e.url === undefined ? null : e.url,
            })),
        },
    };
    const ret = await makeRequest({name: "editNotification", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        product: {
            id: ret.product.id,
            name: ret.product.name,
            description: ret.product.description,
            image: ret.product.image === null || ret.product.image === undefined ? null : {
                thumb: {
                    width: ret.product.image.thumb.width || 0,
                    height: ret.product.image.thumb.height || 0,
                    url: ret.product.image.thumb.url,
                },
                width: ret.product.image.width || 0,
                height: ret.product.image.height || 0,
                url: ret.product.image.url,
            },
            expirationDate: new Date(ret.product.expirationDate + "Z"),
            finalizedAt: ret.product.finalizedAt === null || ret.product.finalizedAt === undefined ? null : new Date(ret.product.finalizedAt + "Z"),
            createdAt: new Date(ret.product.createdAt + "Z"),
            updatedAt: new Date(ret.product.updatedAt + "Z"),
            incomeTax: ret.product.incomeTax === null || ret.product.incomeTax === undefined ? null : ret.product.incomeTax,
            deletedAt: ret.product.deletedAt === null || ret.product.deletedAt === undefined ? null : new Date(ret.product.deletedAt + "Z"),
        },
        files: ret.files === null || ret.files === undefined ? null : ret.files.map((e: any) => e),
        description: ret.description,
        createdAt: new Date(ret.createdAt + "Z"),
        updatedAt: new Date(ret.updatedAt + "Z"),
        deletedAt: ret.deletedAt === null || ret.deletedAt === undefined ? null : new Date(ret.deletedAt + "Z"),
    };
}

export async function deleteNotification(id: string, progress?: (progress: number) => void): Promise<Notification> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "deleteNotification", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        product: {
            id: ret.product.id,
            name: ret.product.name,
            description: ret.product.description,
            image: ret.product.image === null || ret.product.image === undefined ? null : {
                thumb: {
                    width: ret.product.image.thumb.width || 0,
                    height: ret.product.image.thumb.height || 0,
                    url: ret.product.image.thumb.url,
                },
                width: ret.product.image.width || 0,
                height: ret.product.image.height || 0,
                url: ret.product.image.url,
            },
            expirationDate: new Date(ret.product.expirationDate + "Z"),
            finalizedAt: ret.product.finalizedAt === null || ret.product.finalizedAt === undefined ? null : new Date(ret.product.finalizedAt + "Z"),
            createdAt: new Date(ret.product.createdAt + "Z"),
            updatedAt: new Date(ret.product.updatedAt + "Z"),
            incomeTax: ret.product.incomeTax === null || ret.product.incomeTax === undefined ? null : ret.product.incomeTax,
            deletedAt: ret.product.deletedAt === null || ret.product.deletedAt === undefined ? null : new Date(ret.product.deletedAt + "Z"),
        },
        files: ret.files === null || ret.files === undefined ? null : ret.files.map((e: any) => e),
        description: ret.description,
        createdAt: new Date(ret.createdAt + "Z"),
        updatedAt: new Date(ret.updatedAt + "Z"),
        deletedAt: ret.deletedAt === null || ret.deletedAt === undefined ? null : new Date(ret.deletedAt + "Z"),
    };
}

export async function getAllPosts(pageOffset: number, progress?: (progress: number) => void): Promise<Post[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllPosts", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        image: {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        title: e.title,
        description: e.description,
        createdAt: new Date(e.createdAt + "Z"),
        deletedAt: e.deletedAt === null || e.deletedAt === undefined ? null : new Date(e.deletedAt + "Z"),
    }));
}

export async function getPostById(id: string, progress?: (progress: number) => void): Promise<Post> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getPostById", args, progress});
    return {
        id: ret.id,
        image: {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        title: ret.title,
        description: ret.description,
        createdAt: new Date(ret.createdAt + "Z"),
        deletedAt: ret.deletedAt === null || ret.deletedAt === undefined ? null : new Date(ret.deletedAt + "Z"),
    };
}

export async function createPost(newPost: NewPost, progress?: (progress: number) => void): Promise<Post> {
    const args = {
        newPost: {
            image: {
                bytes: newPost.image.bytes === null || newPost.image.bytes === undefined ? null : newPost.image.bytes.toString("base64"),
                image: newPost.image.image === null || newPost.image.image === undefined ? null : {
                    thumb: {
                        width: newPost.image.image.thumb.width || 0,
                        height: newPost.image.image.thumb.height || 0,
                        url: newPost.image.image.thumb.url,
                    },
                    width: newPost.image.image.width || 0,
                    height: newPost.image.image.height || 0,
                    url: newPost.image.image.url,
                },
            },
            title: newPost.title,
            description: newPost.description,
        },
    };
    const ret = await makeRequest({name: "createPost", args, progress});
    return {
        id: ret.id,
        image: {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        title: ret.title,
        description: ret.description,
        createdAt: new Date(ret.createdAt + "Z"),
        deletedAt: ret.deletedAt === null || ret.deletedAt === undefined ? null : new Date(ret.deletedAt + "Z"),
    };
}

export async function editPost(id: string, editPost: NewPost, progress?: (progress: number) => void): Promise<Post> {
    const args = {
        id: id,
        editPost: {
            image: {
                bytes: editPost.image.bytes === null || editPost.image.bytes === undefined ? null : editPost.image.bytes.toString("base64"),
                image: editPost.image.image === null || editPost.image.image === undefined ? null : {
                    thumb: {
                        width: editPost.image.image.thumb.width || 0,
                        height: editPost.image.image.thumb.height || 0,
                        url: editPost.image.image.thumb.url,
                    },
                    width: editPost.image.image.width || 0,
                    height: editPost.image.image.height || 0,
                    url: editPost.image.image.url,
                },
            },
            title: editPost.title,
            description: editPost.description,
        },
    };
    const ret = await makeRequest({name: "editPost", args, progress});
    return {
        id: ret.id,
        image: {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        title: ret.title,
        description: ret.description,
        createdAt: new Date(ret.createdAt + "Z"),
        deletedAt: ret.deletedAt === null || ret.deletedAt === undefined ? null : new Date(ret.deletedAt + "Z"),
    };
}

export async function deletePost(id: string, progress?: (progress: number) => void): Promise<Post> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "deletePost", args, progress});
    return {
        id: ret.id,
        image: {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        title: ret.title,
        description: ret.description,
        createdAt: new Date(ret.createdAt + "Z"),
        deletedAt: ret.deletedAt === null || ret.deletedAt === undefined ? null : new Date(ret.deletedAt + "Z"),
    };
}

export async function getAllPurchasedProductsByUser(pageOffSet: number, userId: string, progress?: (progress: number) => void): Promise<PurchasedProduct[]> {
    const args = {
        pageOffSet: pageOffSet || 0,
        userId: userId,
    };
    const ret = await makeRequest({name: "getAllPurchasedProductsByUser", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        user: {
            id: e.user.id,
            email: e.user.email,
            name: e.user.name,
            password: e.user.password,
            documentNumber: e.user.documentNumber,
            whatsapp: e.user.whatsapp === null || e.user.whatsapp === undefined ? null : e.user.whatsapp,
            phone: e.user.phone === null || e.user.phone === undefined ? null : e.user.phone,
            agency: e.user.agency === null || e.user.agency === undefined ? null : e.user.agency,
            agencyDv: e.user.agencyDv === null || e.user.agencyDv === undefined ? null : e.user.agencyDv,
            account: e.user.account === null || e.user.account === undefined ? null : e.user.account,
            accountDv: e.user.accountDv === null || e.user.accountDv === undefined ? null : e.user.accountDv,
            bankName: e.user.bankName === null || e.user.bankName === undefined ? null : e.user.bankName,
        },
        product: {
            id: e.product.id,
            name: e.product.name,
            description: e.product.description,
            image: e.product.image === null || e.product.image === undefined ? null : {
                thumb: {
                    width: e.product.image.thumb.width || 0,
                    height: e.product.image.thumb.height || 0,
                    url: e.product.image.thumb.url,
                },
                width: e.product.image.width || 0,
                height: e.product.image.height || 0,
                url: e.product.image.url,
            },
            expirationDate: new Date(e.product.expirationDate + "Z"),
            finalizedAt: e.product.finalizedAt === null || e.product.finalizedAt === undefined ? null : new Date(e.product.finalizedAt + "Z"),
            createdAt: new Date(e.product.createdAt + "Z"),
            updatedAt: new Date(e.product.updatedAt + "Z"),
            incomeTax: e.product.incomeTax === null || e.product.incomeTax === undefined ? null : e.product.incomeTax,
            deletedAt: e.product.deletedAt === null || e.product.deletedAt === undefined ? null : new Date(e.product.deletedAt + "Z"),
        },
        percentageExpectedValue: e.percentageExpectedValue,
        investedAmount: e.investedAmount,
        liftingAmount: e.liftingAmount,
        investmentDate: new Date(e.investmentDate + "Z"),
        grossValue: e.grossValue === null || e.grossValue === undefined ? null : e.grossValue,
        files: e.files === null || e.files === undefined ? null : e.files.map((e: any) => e),
        finalizedAt: e.finalizedAt === null || e.finalizedAt === undefined ? null : new Date(e.finalizedAt + "Z"),
        createdAt: new Date(e.createdAt + "Z"),
        updatedAt: new Date(e.updatedAt + "Z"),
        deletedAt: e.deletedAt === null || e.deletedAt === undefined ? null : new Date(e.deletedAt + "Z"),
    }));
}

export async function getPurchasedProductsByProductId(pageOffSet: number, productId: string, progress?: (progress: number) => void): Promise<PurchasedProduct[]> {
    const args = {
        pageOffSet: pageOffSet || 0,
        productId: productId,
    };
    const ret = await makeRequest({name: "getPurchasedProductsByProductId", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        user: {
            id: e.user.id,
            email: e.user.email,
            name: e.user.name,
            password: e.user.password,
            documentNumber: e.user.documentNumber,
            whatsapp: e.user.whatsapp === null || e.user.whatsapp === undefined ? null : e.user.whatsapp,
            phone: e.user.phone === null || e.user.phone === undefined ? null : e.user.phone,
            agency: e.user.agency === null || e.user.agency === undefined ? null : e.user.agency,
            agencyDv: e.user.agencyDv === null || e.user.agencyDv === undefined ? null : e.user.agencyDv,
            account: e.user.account === null || e.user.account === undefined ? null : e.user.account,
            accountDv: e.user.accountDv === null || e.user.accountDv === undefined ? null : e.user.accountDv,
            bankName: e.user.bankName === null || e.user.bankName === undefined ? null : e.user.bankName,
        },
        product: {
            id: e.product.id,
            name: e.product.name,
            description: e.product.description,
            image: e.product.image === null || e.product.image === undefined ? null : {
                thumb: {
                    width: e.product.image.thumb.width || 0,
                    height: e.product.image.thumb.height || 0,
                    url: e.product.image.thumb.url,
                },
                width: e.product.image.width || 0,
                height: e.product.image.height || 0,
                url: e.product.image.url,
            },
            expirationDate: new Date(e.product.expirationDate + "Z"),
            finalizedAt: e.product.finalizedAt === null || e.product.finalizedAt === undefined ? null : new Date(e.product.finalizedAt + "Z"),
            createdAt: new Date(e.product.createdAt + "Z"),
            updatedAt: new Date(e.product.updatedAt + "Z"),
            incomeTax: e.product.incomeTax === null || e.product.incomeTax === undefined ? null : e.product.incomeTax,
            deletedAt: e.product.deletedAt === null || e.product.deletedAt === undefined ? null : new Date(e.product.deletedAt + "Z"),
        },
        percentageExpectedValue: e.percentageExpectedValue,
        investedAmount: e.investedAmount,
        liftingAmount: e.liftingAmount,
        investmentDate: new Date(e.investmentDate + "Z"),
        grossValue: e.grossValue === null || e.grossValue === undefined ? null : e.grossValue,
        files: e.files === null || e.files === undefined ? null : e.files.map((e: any) => e),
        finalizedAt: e.finalizedAt === null || e.finalizedAt === undefined ? null : new Date(e.finalizedAt + "Z"),
        createdAt: new Date(e.createdAt + "Z"),
        updatedAt: new Date(e.updatedAt + "Z"),
        deletedAt: e.deletedAt === null || e.deletedAt === undefined ? null : new Date(e.deletedAt + "Z"),
    }));
}

export async function getPurchasedProductById(id: string, progress?: (progress: number) => void): Promise<PurchasedProduct> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getPurchasedProductById", args, progress});
    return {
        id: ret.id,
        user: {
            id: ret.user.id,
            email: ret.user.email,
            name: ret.user.name,
            password: ret.user.password,
            documentNumber: ret.user.documentNumber,
            whatsapp: ret.user.whatsapp === null || ret.user.whatsapp === undefined ? null : ret.user.whatsapp,
            phone: ret.user.phone === null || ret.user.phone === undefined ? null : ret.user.phone,
            agency: ret.user.agency === null || ret.user.agency === undefined ? null : ret.user.agency,
            agencyDv: ret.user.agencyDv === null || ret.user.agencyDv === undefined ? null : ret.user.agencyDv,
            account: ret.user.account === null || ret.user.account === undefined ? null : ret.user.account,
            accountDv: ret.user.accountDv === null || ret.user.accountDv === undefined ? null : ret.user.accountDv,
            bankName: ret.user.bankName === null || ret.user.bankName === undefined ? null : ret.user.bankName,
        },
        product: {
            id: ret.product.id,
            name: ret.product.name,
            description: ret.product.description,
            image: ret.product.image === null || ret.product.image === undefined ? null : {
                thumb: {
                    width: ret.product.image.thumb.width || 0,
                    height: ret.product.image.thumb.height || 0,
                    url: ret.product.image.thumb.url,
                },
                width: ret.product.image.width || 0,
                height: ret.product.image.height || 0,
                url: ret.product.image.url,
            },
            expirationDate: new Date(ret.product.expirationDate + "Z"),
            finalizedAt: ret.product.finalizedAt === null || ret.product.finalizedAt === undefined ? null : new Date(ret.product.finalizedAt + "Z"),
            createdAt: new Date(ret.product.createdAt + "Z"),
            updatedAt: new Date(ret.product.updatedAt + "Z"),
            incomeTax: ret.product.incomeTax === null || ret.product.incomeTax === undefined ? null : ret.product.incomeTax,
            deletedAt: ret.product.deletedAt === null || ret.product.deletedAt === undefined ? null : new Date(ret.product.deletedAt + "Z"),
        },
        percentageExpectedValue: ret.percentageExpectedValue,
        investedAmount: ret.investedAmount,
        liftingAmount: ret.liftingAmount,
        investmentDate: new Date(ret.investmentDate + "Z"),
        grossValue: ret.grossValue === null || ret.grossValue === undefined ? null : ret.grossValue,
        files: ret.files === null || ret.files === undefined ? null : ret.files.map((e: any) => e),
        finalizedAt: ret.finalizedAt === null || ret.finalizedAt === undefined ? null : new Date(ret.finalizedAt + "Z"),
        createdAt: new Date(ret.createdAt + "Z"),
        updatedAt: new Date(ret.updatedAt + "Z"),
        deletedAt: ret.deletedAt === null || ret.deletedAt === undefined ? null : new Date(ret.deletedAt + "Z"),
    };
}

export async function getReportsOfPurchasedProducts(progress?: (progress: number) => void): Promise<string> {
    const ret = await makeRequest({name: "getReportsOfPurchasedProducts", args: {}, progress});
    return ret;
}

export async function createPurchasedProduct(userId: string, productId: string, newPurchasedProducts: NewPurchasedProduct, progress?: (progress: number) => void): Promise<PurchasedProduct> {
    const args = {
        userId: userId,
        productId: productId,
        newPurchasedProducts: {
            investedAmount: newPurchasedProducts.investedAmount,
            investmentDate: (typeof newPurchasedProducts.investmentDate === "string" && (newPurchasedProducts.investmentDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (newPurchasedProducts.investmentDate as any).replace("Z", "") : newPurchasedProducts.investmentDate.toISOString().replace("Z", "")),
            percentageExpectedValue: newPurchasedProducts.percentageExpectedValue,
            grossValue: newPurchasedProducts.grossValue === null || newPurchasedProducts.grossValue === undefined ? null : newPurchasedProducts.grossValue,
            files: newPurchasedProducts.files === null || newPurchasedProducts.files === undefined ? null : newPurchasedProducts.files.map(e => e),
            finalizedAt: newPurchasedProducts.finalizedAt === null || newPurchasedProducts.finalizedAt === undefined ? null : (typeof newPurchasedProducts.finalizedAt === "string" && (newPurchasedProducts.finalizedAt as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (newPurchasedProducts.finalizedAt as any).replace("Z", "") : newPurchasedProducts.finalizedAt.toISOString().replace("Z", "")),
        },
    };
    const ret = await makeRequest({name: "createPurchasedProduct", args, progress});
    return {
        id: ret.id,
        user: {
            id: ret.user.id,
            email: ret.user.email,
            name: ret.user.name,
            password: ret.user.password,
            documentNumber: ret.user.documentNumber,
            whatsapp: ret.user.whatsapp === null || ret.user.whatsapp === undefined ? null : ret.user.whatsapp,
            phone: ret.user.phone === null || ret.user.phone === undefined ? null : ret.user.phone,
            agency: ret.user.agency === null || ret.user.agency === undefined ? null : ret.user.agency,
            agencyDv: ret.user.agencyDv === null || ret.user.agencyDv === undefined ? null : ret.user.agencyDv,
            account: ret.user.account === null || ret.user.account === undefined ? null : ret.user.account,
            accountDv: ret.user.accountDv === null || ret.user.accountDv === undefined ? null : ret.user.accountDv,
            bankName: ret.user.bankName === null || ret.user.bankName === undefined ? null : ret.user.bankName,
        },
        product: {
            id: ret.product.id,
            name: ret.product.name,
            description: ret.product.description,
            image: ret.product.image === null || ret.product.image === undefined ? null : {
                thumb: {
                    width: ret.product.image.thumb.width || 0,
                    height: ret.product.image.thumb.height || 0,
                    url: ret.product.image.thumb.url,
                },
                width: ret.product.image.width || 0,
                height: ret.product.image.height || 0,
                url: ret.product.image.url,
            },
            expirationDate: new Date(ret.product.expirationDate + "Z"),
            finalizedAt: ret.product.finalizedAt === null || ret.product.finalizedAt === undefined ? null : new Date(ret.product.finalizedAt + "Z"),
            createdAt: new Date(ret.product.createdAt + "Z"),
            updatedAt: new Date(ret.product.updatedAt + "Z"),
            incomeTax: ret.product.incomeTax === null || ret.product.incomeTax === undefined ? null : ret.product.incomeTax,
            deletedAt: ret.product.deletedAt === null || ret.product.deletedAt === undefined ? null : new Date(ret.product.deletedAt + "Z"),
        },
        percentageExpectedValue: ret.percentageExpectedValue,
        investedAmount: ret.investedAmount,
        liftingAmount: ret.liftingAmount,
        investmentDate: new Date(ret.investmentDate + "Z"),
        grossValue: ret.grossValue === null || ret.grossValue === undefined ? null : ret.grossValue,
        files: ret.files === null || ret.files === undefined ? null : ret.files.map((e: any) => e),
        finalizedAt: ret.finalizedAt === null || ret.finalizedAt === undefined ? null : new Date(ret.finalizedAt + "Z"),
        createdAt: new Date(ret.createdAt + "Z"),
        updatedAt: new Date(ret.updatedAt + "Z"),
        deletedAt: ret.deletedAt === null || ret.deletedAt === undefined ? null : new Date(ret.deletedAt + "Z"),
    };
}

export async function editPurchasedProduct(id: string, editPurchasedProduct: EditPurchasedProduct, progress?: (progress: number) => void): Promise<PurchasedProduct> {
    const args = {
        id: id,
        editPurchasedProduct: {
            product: {
                id: editPurchasedProduct.product.id,
                name: editPurchasedProduct.product.name,
                description: editPurchasedProduct.product.description,
                image: editPurchasedProduct.product.image === null || editPurchasedProduct.product.image === undefined ? null : {
                    thumb: {
                        width: editPurchasedProduct.product.image.thumb.width || 0,
                        height: editPurchasedProduct.product.image.thumb.height || 0,
                        url: editPurchasedProduct.product.image.thumb.url,
                    },
                    width: editPurchasedProduct.product.image.width || 0,
                    height: editPurchasedProduct.product.image.height || 0,
                    url: editPurchasedProduct.product.image.url,
                },
                expirationDate: (typeof editPurchasedProduct.product.expirationDate === "string" && (editPurchasedProduct.product.expirationDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (editPurchasedProduct.product.expirationDate as any).replace("Z", "") : editPurchasedProduct.product.expirationDate.toISOString().replace("Z", "")),
                finalizedAt: editPurchasedProduct.product.finalizedAt === null || editPurchasedProduct.product.finalizedAt === undefined ? null : (typeof editPurchasedProduct.product.finalizedAt === "string" && (editPurchasedProduct.product.finalizedAt as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (editPurchasedProduct.product.finalizedAt as any).replace("Z", "") : editPurchasedProduct.product.finalizedAt.toISOString().replace("Z", "")),
                createdAt: (typeof editPurchasedProduct.product.createdAt === "string" && (editPurchasedProduct.product.createdAt as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (editPurchasedProduct.product.createdAt as any).replace("Z", "") : editPurchasedProduct.product.createdAt.toISOString().replace("Z", "")),
                updatedAt: (typeof editPurchasedProduct.product.updatedAt === "string" && (editPurchasedProduct.product.updatedAt as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (editPurchasedProduct.product.updatedAt as any).replace("Z", "") : editPurchasedProduct.product.updatedAt.toISOString().replace("Z", "")),
                incomeTax: editPurchasedProduct.product.incomeTax === null || editPurchasedProduct.product.incomeTax === undefined ? null : editPurchasedProduct.product.incomeTax,
                deletedAt: editPurchasedProduct.product.deletedAt === null || editPurchasedProduct.product.deletedAt === undefined ? null : (typeof editPurchasedProduct.product.deletedAt === "string" && (editPurchasedProduct.product.deletedAt as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (editPurchasedProduct.product.deletedAt as any).replace("Z", "") : editPurchasedProduct.product.deletedAt.toISOString().replace("Z", "")),
            },
            investedAmount: editPurchasedProduct.investedAmount,
            percentageExpectedValue: editPurchasedProduct.percentageExpectedValue,
            investmentDate: (typeof editPurchasedProduct.investmentDate === "string" && (editPurchasedProduct.investmentDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (editPurchasedProduct.investmentDate as any).replace("Z", "") : editPurchasedProduct.investmentDate.toISOString().replace("Z", "")),
            grossValue: editPurchasedProduct.grossValue === null || editPurchasedProduct.grossValue === undefined ? null : editPurchasedProduct.grossValue,
            files: editPurchasedProduct.files === null || editPurchasedProduct.files === undefined ? null : editPurchasedProduct.files.map(e => e),
            finalizedAt: editPurchasedProduct.finalizedAt === null || editPurchasedProduct.finalizedAt === undefined ? null : (typeof editPurchasedProduct.finalizedAt === "string" && (editPurchasedProduct.finalizedAt as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (editPurchasedProduct.finalizedAt as any).replace("Z", "") : editPurchasedProduct.finalizedAt.toISOString().replace("Z", "")),
        },
    };
    const ret = await makeRequest({name: "editPurchasedProduct", args, progress});
    return {
        id: ret.id,
        user: {
            id: ret.user.id,
            email: ret.user.email,
            name: ret.user.name,
            password: ret.user.password,
            documentNumber: ret.user.documentNumber,
            whatsapp: ret.user.whatsapp === null || ret.user.whatsapp === undefined ? null : ret.user.whatsapp,
            phone: ret.user.phone === null || ret.user.phone === undefined ? null : ret.user.phone,
            agency: ret.user.agency === null || ret.user.agency === undefined ? null : ret.user.agency,
            agencyDv: ret.user.agencyDv === null || ret.user.agencyDv === undefined ? null : ret.user.agencyDv,
            account: ret.user.account === null || ret.user.account === undefined ? null : ret.user.account,
            accountDv: ret.user.accountDv === null || ret.user.accountDv === undefined ? null : ret.user.accountDv,
            bankName: ret.user.bankName === null || ret.user.bankName === undefined ? null : ret.user.bankName,
        },
        product: {
            id: ret.product.id,
            name: ret.product.name,
            description: ret.product.description,
            image: ret.product.image === null || ret.product.image === undefined ? null : {
                thumb: {
                    width: ret.product.image.thumb.width || 0,
                    height: ret.product.image.thumb.height || 0,
                    url: ret.product.image.thumb.url,
                },
                width: ret.product.image.width || 0,
                height: ret.product.image.height || 0,
                url: ret.product.image.url,
            },
            expirationDate: new Date(ret.product.expirationDate + "Z"),
            finalizedAt: ret.product.finalizedAt === null || ret.product.finalizedAt === undefined ? null : new Date(ret.product.finalizedAt + "Z"),
            createdAt: new Date(ret.product.createdAt + "Z"),
            updatedAt: new Date(ret.product.updatedAt + "Z"),
            incomeTax: ret.product.incomeTax === null || ret.product.incomeTax === undefined ? null : ret.product.incomeTax,
            deletedAt: ret.product.deletedAt === null || ret.product.deletedAt === undefined ? null : new Date(ret.product.deletedAt + "Z"),
        },
        percentageExpectedValue: ret.percentageExpectedValue,
        investedAmount: ret.investedAmount,
        liftingAmount: ret.liftingAmount,
        investmentDate: new Date(ret.investmentDate + "Z"),
        grossValue: ret.grossValue === null || ret.grossValue === undefined ? null : ret.grossValue,
        files: ret.files === null || ret.files === undefined ? null : ret.files.map((e: any) => e),
        finalizedAt: ret.finalizedAt === null || ret.finalizedAt === undefined ? null : new Date(ret.finalizedAt + "Z"),
        createdAt: new Date(ret.createdAt + "Z"),
        updatedAt: new Date(ret.updatedAt + "Z"),
        deletedAt: ret.deletedAt === null || ret.deletedAt === undefined ? null : new Date(ret.deletedAt + "Z"),
    };
}

export async function deletePurchasedProduct(id: string, progress?: (progress: number) => void): Promise<PurchasedProduct> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "deletePurchasedProduct", args, progress});
    return {
        id: ret.id,
        user: {
            id: ret.user.id,
            email: ret.user.email,
            name: ret.user.name,
            password: ret.user.password,
            documentNumber: ret.user.documentNumber,
            whatsapp: ret.user.whatsapp === null || ret.user.whatsapp === undefined ? null : ret.user.whatsapp,
            phone: ret.user.phone === null || ret.user.phone === undefined ? null : ret.user.phone,
            agency: ret.user.agency === null || ret.user.agency === undefined ? null : ret.user.agency,
            agencyDv: ret.user.agencyDv === null || ret.user.agencyDv === undefined ? null : ret.user.agencyDv,
            account: ret.user.account === null || ret.user.account === undefined ? null : ret.user.account,
            accountDv: ret.user.accountDv === null || ret.user.accountDv === undefined ? null : ret.user.accountDv,
            bankName: ret.user.bankName === null || ret.user.bankName === undefined ? null : ret.user.bankName,
        },
        product: {
            id: ret.product.id,
            name: ret.product.name,
            description: ret.product.description,
            image: ret.product.image === null || ret.product.image === undefined ? null : {
                thumb: {
                    width: ret.product.image.thumb.width || 0,
                    height: ret.product.image.thumb.height || 0,
                    url: ret.product.image.thumb.url,
                },
                width: ret.product.image.width || 0,
                height: ret.product.image.height || 0,
                url: ret.product.image.url,
            },
            expirationDate: new Date(ret.product.expirationDate + "Z"),
            finalizedAt: ret.product.finalizedAt === null || ret.product.finalizedAt === undefined ? null : new Date(ret.product.finalizedAt + "Z"),
            createdAt: new Date(ret.product.createdAt + "Z"),
            updatedAt: new Date(ret.product.updatedAt + "Z"),
            incomeTax: ret.product.incomeTax === null || ret.product.incomeTax === undefined ? null : ret.product.incomeTax,
            deletedAt: ret.product.deletedAt === null || ret.product.deletedAt === undefined ? null : new Date(ret.product.deletedAt + "Z"),
        },
        percentageExpectedValue: ret.percentageExpectedValue,
        investedAmount: ret.investedAmount,
        liftingAmount: ret.liftingAmount,
        investmentDate: new Date(ret.investmentDate + "Z"),
        grossValue: ret.grossValue === null || ret.grossValue === undefined ? null : ret.grossValue,
        files: ret.files === null || ret.files === undefined ? null : ret.files.map((e: any) => e),
        finalizedAt: ret.finalizedAt === null || ret.finalizedAt === undefined ? null : new Date(ret.finalizedAt + "Z"),
        createdAt: new Date(ret.createdAt + "Z"),
        updatedAt: new Date(ret.updatedAt + "Z"),
        deletedAt: ret.deletedAt === null || ret.deletedAt === undefined ? null : new Date(ret.deletedAt + "Z"),
    };
}

export async function finishPurchasedProduct(id: string, progress?: (progress: number) => void): Promise<PurchasedProduct> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "finishPurchasedProduct", args, progress});
    return {
        id: ret.id,
        user: {
            id: ret.user.id,
            email: ret.user.email,
            name: ret.user.name,
            password: ret.user.password,
            documentNumber: ret.user.documentNumber,
            whatsapp: ret.user.whatsapp === null || ret.user.whatsapp === undefined ? null : ret.user.whatsapp,
            phone: ret.user.phone === null || ret.user.phone === undefined ? null : ret.user.phone,
            agency: ret.user.agency === null || ret.user.agency === undefined ? null : ret.user.agency,
            agencyDv: ret.user.agencyDv === null || ret.user.agencyDv === undefined ? null : ret.user.agencyDv,
            account: ret.user.account === null || ret.user.account === undefined ? null : ret.user.account,
            accountDv: ret.user.accountDv === null || ret.user.accountDv === undefined ? null : ret.user.accountDv,
            bankName: ret.user.bankName === null || ret.user.bankName === undefined ? null : ret.user.bankName,
        },
        product: {
            id: ret.product.id,
            name: ret.product.name,
            description: ret.product.description,
            image: ret.product.image === null || ret.product.image === undefined ? null : {
                thumb: {
                    width: ret.product.image.thumb.width || 0,
                    height: ret.product.image.thumb.height || 0,
                    url: ret.product.image.thumb.url,
                },
                width: ret.product.image.width || 0,
                height: ret.product.image.height || 0,
                url: ret.product.image.url,
            },
            expirationDate: new Date(ret.product.expirationDate + "Z"),
            finalizedAt: ret.product.finalizedAt === null || ret.product.finalizedAt === undefined ? null : new Date(ret.product.finalizedAt + "Z"),
            createdAt: new Date(ret.product.createdAt + "Z"),
            updatedAt: new Date(ret.product.updatedAt + "Z"),
            incomeTax: ret.product.incomeTax === null || ret.product.incomeTax === undefined ? null : ret.product.incomeTax,
            deletedAt: ret.product.deletedAt === null || ret.product.deletedAt === undefined ? null : new Date(ret.product.deletedAt + "Z"),
        },
        percentageExpectedValue: ret.percentageExpectedValue,
        investedAmount: ret.investedAmount,
        liftingAmount: ret.liftingAmount,
        investmentDate: new Date(ret.investmentDate + "Z"),
        grossValue: ret.grossValue === null || ret.grossValue === undefined ? null : ret.grossValue,
        files: ret.files === null || ret.files === undefined ? null : ret.files.map((e: any) => e),
        finalizedAt: ret.finalizedAt === null || ret.finalizedAt === undefined ? null : new Date(ret.finalizedAt + "Z"),
        createdAt: new Date(ret.createdAt + "Z"),
        updatedAt: new Date(ret.updatedAt + "Z"),
        deletedAt: ret.deletedAt === null || ret.deletedAt === undefined ? null : new Date(ret.deletedAt + "Z"),
    };
}

export async function sendRequestResetPassword(email: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        email: email,
    };
    await makeRequest({name: "sendRequestResetPassword", args, progress});
    return undefined;
}

export async function validateToken(token: string, progress?: (progress: number) => void): Promise<boolean> {
    const args = {
        token: token,
    };
    const ret = await makeRequest({name: "validateToken", args, progress});
    return !!ret;
}

export async function resetPassword(token: string, newPassword: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        token: token,
        newPassword: newPassword,
    };
    await makeRequest({name: "resetPassword", args, progress});
    return undefined;
}

export async function getCurrentUser(progress?: (progress: number) => void): Promise<User> {
    const ret = await makeRequest({name: "getCurrentUser", args: {}, progress});
    return {
        id: ret.id,
        email: ret.email,
        name: ret.name,
        password: ret.password,
        documentNumber: ret.documentNumber,
        whatsapp: ret.whatsapp === null || ret.whatsapp === undefined ? null : ret.whatsapp,
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        agency: ret.agency === null || ret.agency === undefined ? null : ret.agency,
        agencyDv: ret.agencyDv === null || ret.agencyDv === undefined ? null : ret.agencyDv,
        account: ret.account === null || ret.account === undefined ? null : ret.account,
        accountDv: ret.accountDv === null || ret.accountDv === undefined ? null : ret.accountDv,
        bankName: ret.bankName === null || ret.bankName === undefined ? null : ret.bankName,
    };
}

export async function userLogin(documentNumber: string, password: string, progress?: (progress: number) => void): Promise<User> {
    const args = {
        documentNumber: documentNumber,
        password: password,
    };
    const ret = await makeRequest({name: "userLogin", args, progress});
    return {
        id: ret.id,
        email: ret.email,
        name: ret.name,
        password: ret.password,
        documentNumber: ret.documentNumber,
        whatsapp: ret.whatsapp === null || ret.whatsapp === undefined ? null : ret.whatsapp,
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        agency: ret.agency === null || ret.agency === undefined ? null : ret.agency,
        agencyDv: ret.agencyDv === null || ret.agencyDv === undefined ? null : ret.agencyDv,
        account: ret.account === null || ret.account === undefined ? null : ret.account,
        accountDv: ret.accountDv === null || ret.accountDv === undefined ? null : ret.accountDv,
        bankName: ret.bankName === null || ret.bankName === undefined ? null : ret.bankName,
    };
}

export async function logoutUser(progress?: (progress: number) => void): Promise<void> {
    await makeRequest({name: "logoutUser", args: {}, progress});
    return undefined;
}

export async function getOneUserById(id: string, progress?: (progress: number) => void): Promise<User> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getOneUserById", args, progress});
    return {
        id: ret.id,
        email: ret.email,
        name: ret.name,
        password: ret.password,
        documentNumber: ret.documentNumber,
        whatsapp: ret.whatsapp === null || ret.whatsapp === undefined ? null : ret.whatsapp,
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        agency: ret.agency === null || ret.agency === undefined ? null : ret.agency,
        agencyDv: ret.agencyDv === null || ret.agencyDv === undefined ? null : ret.agencyDv,
        account: ret.account === null || ret.account === undefined ? null : ret.account,
        accountDv: ret.accountDv === null || ret.accountDv === undefined ? null : ret.accountDv,
        bankName: ret.bankName === null || ret.bankName === undefined ? null : ret.bankName,
    };
}

export async function deleteUser(id: string, progress?: (progress: number) => void): Promise<User> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "deleteUser", args, progress});
    return {
        id: ret.id,
        email: ret.email,
        name: ret.name,
        password: ret.password,
        documentNumber: ret.documentNumber,
        whatsapp: ret.whatsapp === null || ret.whatsapp === undefined ? null : ret.whatsapp,
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        agency: ret.agency === null || ret.agency === undefined ? null : ret.agency,
        agencyDv: ret.agencyDv === null || ret.agencyDv === undefined ? null : ret.agencyDv,
        account: ret.account === null || ret.account === undefined ? null : ret.account,
        accountDv: ret.accountDv === null || ret.accountDv === undefined ? null : ret.accountDv,
        bankName: ret.bankName === null || ret.bankName === undefined ? null : ret.bankName,
    };
}

export async function editBankAccount(id: string, bankAccount: BankAccount, progress?: (progress: number) => void): Promise<User> {
    const args = {
        id: id,
        bankAccount: {
            bankName: bankAccount.bankName === null || bankAccount.bankName === undefined ? null : bankAccount.bankName,
            agency: bankAccount.agency === null || bankAccount.agency === undefined ? null : bankAccount.agency,
            agencyDv: bankAccount.agencyDv === null || bankAccount.agencyDv === undefined ? null : bankAccount.agencyDv,
            account: bankAccount.account === null || bankAccount.account === undefined ? null : bankAccount.account,
            accountDv: bankAccount.accountDv === null || bankAccount.accountDv === undefined ? null : bankAccount.accountDv,
        },
    };
    const ret = await makeRequest({name: "editBankAccount", args, progress});
    return {
        id: ret.id,
        email: ret.email,
        name: ret.name,
        password: ret.password,
        documentNumber: ret.documentNumber,
        whatsapp: ret.whatsapp === null || ret.whatsapp === undefined ? null : ret.whatsapp,
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        agency: ret.agency === null || ret.agency === undefined ? null : ret.agency,
        agencyDv: ret.agencyDv === null || ret.agencyDv === undefined ? null : ret.agencyDv,
        account: ret.account === null || ret.account === undefined ? null : ret.account,
        accountDv: ret.accountDv === null || ret.accountDv === undefined ? null : ret.accountDv,
        bankName: ret.bankName === null || ret.bankName === undefined ? null : ret.bankName,
    };
}

export async function getAllStatisticsByUser(progress?: (progress: number) => void): Promise<UserStatistics> {
    const ret = await makeRequest({name: "getAllStatisticsByUser", args: {}, progress});
    return {
        appliedValue: ret.appliedValue,
        liftedValue: ret.liftedValue,
        liftingAmount: ret.liftingAmount,
        netAnnualAverage: ret.netAnnualAverage,
        countProductsNotFinalizated: ret.countProductsNotFinalizated,
        countProductsFinalizated: ret.countProductsFinalizated,
    };
}

export async function getUserWithAdvisor(id: string, progress?: (progress: number) => void): Promise<UserWithAdvisor> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getUserWithAdvisor", args, progress});
    return {
        advisor: ret.advisor === null || ret.advisor === undefined ? null : {
            id: ret.advisor.id,
            name: ret.advisor.name,
            email: ret.advisor.email,
            password: ret.advisor.password,
            type: ret.advisor.type,
        },
        id: ret.id,
        email: ret.email,
        name: ret.name,
        password: ret.password,
        documentNumber: ret.documentNumber,
        whatsapp: ret.whatsapp === null || ret.whatsapp === undefined ? null : ret.whatsapp,
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        agency: ret.agency === null || ret.agency === undefined ? null : ret.agency,
        agencyDv: ret.agencyDv === null || ret.agencyDv === undefined ? null : ret.agencyDv,
        account: ret.account === null || ret.account === undefined ? null : ret.account,
        accountDv: ret.accountDv === null || ret.accountDv === undefined ? null : ret.accountDv,
        bankName: ret.bankName === null || ret.bankName === undefined ? null : ret.bankName,
    };
}

export async function getUserDeleteRequestById(id: string, progress?: (progress: number) => void): Promise<UserDeleteRequest> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getUserDeleteRequestById", args, progress});
    return {
        id: ret.id,
        user: {
            id: ret.user.id,
            email: ret.user.email,
            name: ret.user.name,
            password: ret.user.password,
            documentNumber: ret.user.documentNumber,
            whatsapp: ret.user.whatsapp === null || ret.user.whatsapp === undefined ? null : ret.user.whatsapp,
            phone: ret.user.phone === null || ret.user.phone === undefined ? null : ret.user.phone,
            agency: ret.user.agency === null || ret.user.agency === undefined ? null : ret.user.agency,
            agencyDv: ret.user.agencyDv === null || ret.user.agencyDv === undefined ? null : ret.user.agencyDv,
            account: ret.user.account === null || ret.user.account === undefined ? null : ret.user.account,
            accountDv: ret.user.accountDv === null || ret.user.accountDv === undefined ? null : ret.user.accountDv,
            bankName: ret.user.bankName === null || ret.user.bankName === undefined ? null : ret.user.bankName,
        },
        acceptedAt: ret.acceptedAt === null || ret.acceptedAt === undefined ? null : new Date(ret.acceptedAt + "Z"),
        createdAt: new Date(ret.createdAt + "Z"),
        deletedAt: ret.deletedAt === null || ret.deletedAt === undefined ? null : new Date(ret.deletedAt + "Z"),
    };
}

export async function getAllUserDeleteRequests(pageOffSet: number, progress?: (progress: number) => void): Promise<UserDeleteRequest[]> {
    const args = {
        pageOffSet: pageOffSet || 0,
    };
    const ret = await makeRequest({name: "getAllUserDeleteRequests", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        user: {
            id: e.user.id,
            email: e.user.email,
            name: e.user.name,
            password: e.user.password,
            documentNumber: e.user.documentNumber,
            whatsapp: e.user.whatsapp === null || e.user.whatsapp === undefined ? null : e.user.whatsapp,
            phone: e.user.phone === null || e.user.phone === undefined ? null : e.user.phone,
            agency: e.user.agency === null || e.user.agency === undefined ? null : e.user.agency,
            agencyDv: e.user.agencyDv === null || e.user.agencyDv === undefined ? null : e.user.agencyDv,
            account: e.user.account === null || e.user.account === undefined ? null : e.user.account,
            accountDv: e.user.accountDv === null || e.user.accountDv === undefined ? null : e.user.accountDv,
            bankName: e.user.bankName === null || e.user.bankName === undefined ? null : e.user.bankName,
        },
        acceptedAt: e.acceptedAt === null || e.acceptedAt === undefined ? null : new Date(e.acceptedAt + "Z"),
        createdAt: new Date(e.createdAt + "Z"),
        deletedAt: e.deletedAt === null || e.deletedAt === undefined ? null : new Date(e.deletedAt + "Z"),
    }));
}

export async function getAllPendingUserDeleteRequests(pageOffSet: number, progress?: (progress: number) => void): Promise<UserDeleteRequest[]> {
    const args = {
        pageOffSet: pageOffSet || 0,
    };
    const ret = await makeRequest({name: "getAllPendingUserDeleteRequests", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        user: {
            id: e.user.id,
            email: e.user.email,
            name: e.user.name,
            password: e.user.password,
            documentNumber: e.user.documentNumber,
            whatsapp: e.user.whatsapp === null || e.user.whatsapp === undefined ? null : e.user.whatsapp,
            phone: e.user.phone === null || e.user.phone === undefined ? null : e.user.phone,
            agency: e.user.agency === null || e.user.agency === undefined ? null : e.user.agency,
            agencyDv: e.user.agencyDv === null || e.user.agencyDv === undefined ? null : e.user.agencyDv,
            account: e.user.account === null || e.user.account === undefined ? null : e.user.account,
            accountDv: e.user.accountDv === null || e.user.accountDv === undefined ? null : e.user.accountDv,
            bankName: e.user.bankName === null || e.user.bankName === undefined ? null : e.user.bankName,
        },
        acceptedAt: e.acceptedAt === null || e.acceptedAt === undefined ? null : new Date(e.acceptedAt + "Z"),
        createdAt: new Date(e.createdAt + "Z"),
        deletedAt: e.deletedAt === null || e.deletedAt === undefined ? null : new Date(e.deletedAt + "Z"),
    }));
}

export async function acceptUserDeleteRequest(id: string, progress?: (progress: number) => void): Promise<User> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "acceptUserDeleteRequest", args, progress});
    return {
        id: ret.id,
        email: ret.email,
        name: ret.name,
        password: ret.password,
        documentNumber: ret.documentNumber,
        whatsapp: ret.whatsapp === null || ret.whatsapp === undefined ? null : ret.whatsapp,
        phone: ret.phone === null || ret.phone === undefined ? null : ret.phone,
        agency: ret.agency === null || ret.agency === undefined ? null : ret.agency,
        agencyDv: ret.agencyDv === null || ret.agencyDv === undefined ? null : ret.agencyDv,
        account: ret.account === null || ret.account === undefined ? null : ret.account,
        accountDv: ret.accountDv === null || ret.accountDv === undefined ? null : ret.accountDv,
        bankName: ret.bankName === null || ret.bankName === undefined ? null : ret.bankName,
    };
}

export async function refuseUserDeleteRequest(id: string, progress?: (progress: number) => void): Promise<UserDeleteRequest> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "refuseUserDeleteRequest", args, progress});
    return {
        id: ret.id,
        user: {
            id: ret.user.id,
            email: ret.user.email,
            name: ret.user.name,
            password: ret.user.password,
            documentNumber: ret.user.documentNumber,
            whatsapp: ret.user.whatsapp === null || ret.user.whatsapp === undefined ? null : ret.user.whatsapp,
            phone: ret.user.phone === null || ret.user.phone === undefined ? null : ret.user.phone,
            agency: ret.user.agency === null || ret.user.agency === undefined ? null : ret.user.agency,
            agencyDv: ret.user.agencyDv === null || ret.user.agencyDv === undefined ? null : ret.user.agencyDv,
            account: ret.user.account === null || ret.user.account === undefined ? null : ret.user.account,
            accountDv: ret.user.accountDv === null || ret.user.accountDv === undefined ? null : ret.user.accountDv,
            bankName: ret.user.bankName === null || ret.user.bankName === undefined ? null : ret.user.bankName,
        },
        acceptedAt: ret.acceptedAt === null || ret.acceptedAt === undefined ? null : new Date(ret.acceptedAt + "Z"),
        createdAt: new Date(ret.createdAt + "Z"),
        deletedAt: ret.deletedAt === null || ret.deletedAt === undefined ? null : new Date(ret.deletedAt + "Z"),
    };
}

export async function ping(progress?: (progress: number) => void): Promise<string> {
    const ret = await makeRequest({name: "ping", args: {}, progress});
    return ret;
}

export async function setPushToken(token: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        token: token,
    };
    await makeRequest({name: "setPushToken", args, progress});
    return undefined;
}

//////////////////////////////////////////////////////

let fallbackDeviceId: string | null = null;

function setDeviceId(deviceId: string): void {
    fallbackDeviceId = deviceId;
    try {
        localStorage.setItem("deviceId", deviceId);
    } catch (e) {}
}

function getDeviceId(): string | null {
    try {
        return localStorage.getItem("deviceId") || fallbackDeviceId;
    } catch (e) {}

    return fallbackDeviceId;
}

async function device(): Promise<any> {
    const parser = new UAParser();
    parser.setUA(navigator.userAgent);
    const agent = parser.getResult();
    const me = document.currentScript as HTMLScriptElement;
    const device: any = {
            type: "web",
            platform: {
                browser: agent.browser.name,
                browserVersion: agent.browser.version,
                os: agent.os.name,
                osVersion: agent.os.version,
            },
            screen: {
                width: screen.width,
                height: screen.height,
            },
            version: me ? me.src : "",
            language: navigator.language,
    };

    const deviceId = getDeviceId();
    if (deviceId)
        device.id = deviceId;

    return device;
}

function randomBytesHex(len: number): string {
    let hex = "";
    for (let i = 0; i < 2 * len; ++i) {
        hex += "0123456789abcdef"[Math.floor(Math.random() * 16)];
    }

    return hex;
}

export interface ListenerTypes {
    fail: (e: Error, name: string, args: any) => void;
    fatal: (e: Error, name: string, args: any) => void;
    success: (res: string, name: string, args: any) => void;
}

// tslint:disable-next-line: ban-types
type HookArray = Function[];
export type Listenables = keyof ListenerTypes;
export type ListenersDict = { [k in Listenables]: Array<ListenerTypes[k]> };

const listenersDict: ListenersDict = {
    fail: [],
    fatal: [],
    success: [],
};

export function addEventListener(listenable: Listenables, hook: ListenerTypes[typeof listenable]): void {
    const listeners: HookArray = listenersDict[listenable];
    listeners.push(hook);
}

export function removeEventListener(listenable: Listenables, hook: ListenerTypes[typeof listenable]): void {
    const listeners: HookArray = listenersDict[listenable];
    listenersDict[listenable] = listeners.filter((h) => h !== hook) as any;
}

async function makeRequest({name, args, progress}: {name: string, args: any, progress?: (progress: number) => void}): Promise<any> {
    const deviceData = await device();
    return new Promise<any>((resolve, reject) => {
        const req = new XMLHttpRequest();
        req.open(
            "POST",
            `${baseUrl.startsWith("http") || baseUrl.startsWith("localhost") ?
                "" :
                "https://"
            }${baseUrl}/${name}`,
        );

        const body = {
            id: randomBytesHex(8),
            device: deviceData,
            name: name,
            args: args,
        };

        function roughSizeOfObject(object: any): number {
            const objectList: any = [];
            const stack: any = [ object ];
            let bytes = 0;

            while (stack.length) {
                const value = stack.pop();
                if (typeof value === "boolean") {
                    bytes += 4;
                } else if (typeof value === "string") {
                    bytes += value.length * 2;
                } else if (typeof value === "number") {
                    bytes += 8;
                } else if (
                    typeof value === "object"
                    && objectList.indexOf(value) === -1
                ) {
                    objectList.push(value);
                    for (const i in value) {
                        stack.push(value[i]);
                    }
                }
            }

            return bytes;
        }

        req.upload.onprogress = (event: ProgressEvent) => {
            if (event.lengthComputable && progress) {
                progress(Math.ceil(((event.loaded) / event.total) * 100));
            }
        };

        req.onreadystatechange = () => {
            if (req.readyState !== 4) return;
            try {
                const response = JSON.parse(req.responseText);

                try {
                    setDeviceId(response.deviceId);

                    if (response.ok) {
                        resolve(response.result);
                        listenersDict["success"].forEach((hook) => hook(response.result, name, args));
                    } else {
                        const error = typeof response.error === "object" ?
                            response.error :
                            { type: "Fatal", message: response.toString() };

                        reject(error);

                        listenersDict["fail"].forEach((hook) => hook(error, name, args));
                    }
                } catch (e) {
                    console.error(e);
                    reject({type: "Fatal", message: `[${name}] ${e.toString()}`});

                    listenersDict["fatal"].forEach((hook) => hook(e, name, args));
                }
            } catch (e) {
                console.error(e);
                reject({ type: "BadFormattedResponse", message: `Response couldn't be parsed as JSON (${req.responseText}):\n${e.toString()}` });
                listenersDict["fatal"].forEach((hook) => hook(e, name, args));
            }
        };

        req.send(JSON.stringify(body));
    });
}
