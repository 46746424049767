import { Notification } from "./Notification";
import TableView from "./TableView";
import CreateOrEdit from "./CreateOrEdit";
import Details from "./Details";
import CreateLinkedAUser from "./CreateLinkedAUser";

export const Product = {
	TableView,
	CreateOrEdit,
	Details,
	Notification,
	CreateLinkedAUser,
};

