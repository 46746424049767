import { makeAutoObservable } from "mobx";
import { FormShelf } from "@startapp/mobx-utils/src/web";
import { AttributeShelf, LoaderShelf } from "@startapp/mobx-utils";
import api from "~/resources/api";
import strings from "~/resources/strings";
import { showSuccessToast } from "~/resources/toast";
import { onFetchError } from "~/resources/fetchError";
import FilePickerShelf from "~/shelves/FilePickerShelf";

const pageStrings = strings.pages.users.createOrEdit;

export default class Store {

	public product: api.Product;
	public loader = new LoaderShelf();
	public id = new AttributeShelf("");
	public productName = new AttributeShelf("");
	public userName = new AttributeShelf("");
	public grossValue = new AttributeShelf("");
	public investedAmount = new AttributeShelf<string>("");
	public investmentDate = new AttributeShelf<Date>(new Date());
	public percentageExpectedValue = new AttributeShelf<string>("");
	public isFinalized = new AttributeShelf<boolean>(false);
	public finalizedDate = new AttributeShelf<Date | null>(new Date());
	public files = new AttributeShelf<string[] | null>([]);
	public file = new FilePickerShelf(api.uploadUncertainFile);

	public formPassword = new FormShelf({
		password: "",
	});

	constructor(id: string){
		makeAutoObservable(this);

		if (id) {
			this.id.setValue(id);
			this.getPurchasedProduct(id);
		}
	}
	public getPurchasedProduct = async (id: string) => {
		this.loader.tryStart();
		try {
			const purchasedProduct = await api.getPurchasedProductById(id);
			this.product = purchasedProduct.product;
			this.userName.setValue(purchasedProduct.user.name);
			this.setInitValues(purchasedProduct);
		} catch (e) {
			onFetchError(e);
		} finally {
			this.loader.end();
		}
	};

	public setInitValues = (data: api.PurchasedProduct) => {
		this.grossValue.setValue(data.grossValue?.toString() || "");
		this.investedAmount.setValue(data.investedAmount.toString());
		this.investmentDate.setValue(data.investmentDate);
		this.percentageExpectedValue.setValue(data.percentageExpectedValue.toString());
		this.files.setValue(data.files);
		this.productName.setValue(data.product.name);
		this.isFinalized.setValue(data.finalizedAt ? true : false);
		this.finalizedDate.setValue(data.finalizedAt);

	};

	public purchasedProductData = () => ({
		investedAmount: Number(this.investedAmount.value),
		product: this.product,
		investmentDate: this.investmentDate.value,
		grossValue: Number(this.grossValue.value),
		files: this.files.value,
		percentageExpectedValue: Number(this.percentageExpectedValue.value),
		finalizedAt: this.isFinalized.value ? this.finalizedDate.value : null,
	});

	public editPurchasedProduct = async (onSuccess: () => void) => {
		this.loader.start();
		try {
			await api.editPurchasedProduct(this.id.value, this.purchasedProductData());
			showSuccessToast(pageStrings.successEditPurchasedProduct);
			onSuccess();
		} catch (e) {
			onFetchError(e);
		} finally {
			this.loader.end();
		}
	};
}

