import React from "react";
import RDatePicker from "react-datepicker";
import {
	Input,
	InputProps,
	FormLabel,
	FormControl,
	FormControlProps,
	Flex,
	Text,
} from "@chakra-ui/react";
import "react-datepicker/dist/react-datepicker.css";
import { observer } from "mobx-react-lite";
import format from "~/resources/format";
import strings from "~/resources/strings";

interface IProps extends FormControlProps {
	onChangeDate: (date: Date) => void;
	selectedDate: Date | null;
	isDisabled?: boolean;
	minDate?: Date;
	inputProps?: InputProps;
	label?: string;
	placeholder?: string;
	calendarIcon?: boolean;
	onlyYear?: boolean;
}

export const DatePicker: React.FC<IProps> = observer((props) => {
	const {
		onChangeDate,
		selectedDate,
		inputProps,
		placeholder,
		minDate,
		label,
		isDisabled,
		onlyYear,
	} = props;
	const methodToFormat =  onlyYear ? format.dateOnlyYear(selectedDate) : format.date(selectedDate);
	const methodToFormatMaster =  onlyYear ? strings.common.mask.year : strings.common.mask.date;
	const formatedDate = selectedDate ? methodToFormat : methodToFormatMaster;

	return (
		<FormControl
			w="100%"
			cursor="pointer"
			isDisabled={isDisabled}
		>
			{label && (
				<FormLabel
					fontWeight="bold"
					color="white"
					noOfLines={1}
				>
					{label}
				</FormLabel>
			)}

			<RDatePicker
				selected={selectedDate}
				timeIntervals={10}
				showMonthDropdown={!onlyYear}
				showYearDropdown={!onlyYear}
				dropdownMode="select"
				minDate={minDate}
				showYearPicker={onlyYear}
				dateFormat={onlyYear ? "yyyy" : undefined}
				onChange={onChangeDate}
				disabled={isDisabled}
				customInput={
					<Flex position="relative">
						<Text
							fontWeight="bold"
							position="absolute"
							top={6}
							left={5}
						>
							{placeholder}
						</Text>
						<Input
							py={8}
							textAlign="right"
							color="white"
							value={formatedDate}
							readOnly
							cursor="pointer"
							{...inputProps}
							marginRight={2}
						/>
					</Flex>
				}
			/>
		</FormControl>
	);
});
