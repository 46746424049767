import React from "react";
import { observer } from "mobx-react-lite";
import {
	Flex,
	Tooltip,
	Image,
	Center,
	Text,
	Button,
} from "@chakra-ui/react";
import { Loading } from "../Loading";
import strings from "../../resources/strings";

export interface IProps {
	pickImage?: () => void;
	src: string | null;
	loading?: boolean;
}
export const PdfPicker: React.FC<IProps> = observer((props) => {
	const {
		pickImage,
		src,
		loading,
	} = props;
	const componentStrings = strings.components.pdfPicker;

	return (
		<Flex
			direction="column"
			mr={4}
		>
			<Flex
				width={300}
				position="relative"
				overflow="hidden"
				mb={2}
				mt={2}
				color="primary.500"
				direction="column"
			>
				<Tooltip label={componentStrings.selectAnDocument}>
					<Flex
						cursor="pointer"
						borderColor="primary.500"
						rounded="xl"
						w="100%"
						fontWeight="bold"
						onClick={pickImage}
						__css={{ filter: loading && "brightness(0.5)" }}
					>
						<Button
							w="50%"
							h={8}
							borderRadius={10}
							px={24}
							my={2}
							py={2}
							fontSize="sm"
						>
							{strings.actions.add}
						</Button>
					</Flex>
				</Tooltip>
				{src && (
					<Center
						bg="primary.100"
						py={2}
						rounded="md"
						justifyContent="space-between"
						px={2}
					>
						<Text>{src}</Text>
						<Image src="/iconpdf.svg" />
					</Center>
				)}
				{loading && (
					<Loading
						position="absolute"
					/>
				)}
			</Flex>
		</Flex>
	);
});
