import React from "react";
import {
	Box,
	Button,
	Flex,
	Link,
} from "@chakra-ui/react";
import strings from "~/resources/strings";

export interface IProps {
	pdf: string;
	label?: string;
}

export const DocumentLink: React.FC<IProps> = (props) => {

	const { pdf, label } = props;
	const commonStrings = strings.common;

	return (
		<Box>
			<Flex
				w="100%"
				justifyContent="center"
				alignItems="center"
			>
				<Button
					h={8}
					my={5}
					px={2}
					textAlign="center"
					w={{
						base: 150,
						md: "50%",
						lg: "33%",
					}}
				>
					<Link
						href={pdf}
						isExternal
						px={12}
						color="primary.500"
					>
						{label ? label : commonStrings.pdfView}
					</Link>
				</Button>
			</Flex>
		</Box>
	);
};
