import { useGlobalStore } from "~/contexts/useGlobalContext";
import strings from "~/resources/strings";

interface ILinkMenu {
	text: string;
	path: string;
}

const useMainRoutes = (): ILinkMenu[] => {

	const routeAdmin = (path?: string) => `/admin${path ? "/" + path : ""}`;
	const { authStore } = useGlobalStore();

	const adminRoutes = [
		{
			text: strings.nav.adminsMaster,
			path: routeAdmin("adminUsers"),
		},
		{
			text: strings.nav.users,
			path: routeAdmin("users"),
		},
		{
			text: strings.nav.products,
			path: routeAdmin("product"),
		},
		{
			text: strings.nav.blog,
			path: routeAdmin("posts"),
		},
		{
			text: strings.nav.userDeleteRequests,
			path: routeAdmin("userDeleteRequests"),
		},
	];

	if (!authStore.currentAdminUser) {
		return [];
	}

	return adminRoutes;
};

export default useMainRoutes;
