import React from "react";
import {
	Flex,
	IconButton,
	Text,
} from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

export interface IProps {
	currentPage: number;
	prevPage: () => void;
	nextPage: () => void;
	hasNextPage: boolean;
}

export const Pagination: React.FC<IProps> = (props) => {
	const {
		currentPage,
		prevPage,
		nextPage,
		hasNextPage,
	} = props;
	return (
		<Flex alignItems="center" justifyContent="center">
			<IconButton
				variant="icon"
				color="secondary.50"
				disabled={currentPage === 0}
				aria-label="Left Icon"
				__css={{
					fontSize: 18,
				}}
				icon={
					<ChevronLeftIcon
						color={currentPage === 0 ? "secondary.500" : "secondary.50"}
						cursor={currentPage === 0 ? "not-allowed" : "pointer"}
					/>
				}
				onClick={prevPage}
			/>
			<Text mx={3} color="white">{currentPage+1}</Text>
			<IconButton
				variant="icon"
				color="secondary.50"
				disabled={!hasNextPage}
				__css={{
					fontSize: 18,
				}}
				aria-label="Right Icon"
				icon={<ChevronRightIcon />}
				onClick={nextPage}
			/>
		</Flex>
	);
};
