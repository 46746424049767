import React from "react";
import { observer } from "mobx-react-lite";
import { Link as RouterLink} from "react-router-dom";
import {
	Text,
	Box,
	Flex,
	Link,
} from "@chakra-ui/react";
import strings from "../../../resources/strings";
import { useGlobalStore } from "../../../contexts/useGlobalContext";
import { useHistory } from "~/hooks/useHistory";
import useMainRoutes from "~/hooks/useMainRoutes";

interface IProps {
	onClose: () => void;
}

export const DrawerLinks: React.FC<IProps> = observer((props) => {

	const { authStore } = useGlobalStore();
	const routes = useMainRoutes();
	const fontSizeBreakPoint = { base: "sm", lg: "md" };
	const { onClose } = props;

	const history = useHistory();
	const onSuccess = () => {
		history.push("/login");
	};

	return (
		<>
			<Box
				display="flex"
				mt={8}
				flexDirection="column"
				alignItems="flex-start"
				borderBottom="1px solid"
				py={4}
			>
				{ routes && routes.map((mainLink) => (
					mainLink &&
						<Link
							as={RouterLink}
							key={mainLink.text}
							to={mainLink.path}
							onClick={onClose}
							display="flex"
							_hover={{
								textDecoration: "none",
							}}
							justifyContent="center"
							alignItems="center"
							mb={4}
						>
							<Text
								fontSize={fontSizeBreakPoint}
								fontWeight="bold"
							>
								{mainLink.text}
							</Text>
						</Link>
				))}
			</Box>
			<Flex
				justifyContent="flex-start"
				onClick={() => authStore.logout(onSuccess)}
				mt="35px"
			>
				<Text
					color="secondary.50"
					fontSize={fontSizeBreakPoint}
					cursor="pointer"
				>
					{strings.nav?.logout}
				</Text>
			</Flex>
		</>
	);
});
