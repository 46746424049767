import React from "react";
import { observer } from "mobx-react-lite";
import {
	Routes,
	Route,
	useLocation,
} from "react-router-dom";
import { useHistory } from "~/hooks/useHistory";
import { useAuthRoute } from "~/hooks/useAuthRoute";
import AdminRoutes from "./AdminRoutes";
import {
	Loading,
	Login,
	NewPassword,
	Recovery,
} from "~/pages";

const App = observer(() => {
	const history = useHistory();
	const location = useLocation();

	const onSuccess = () => {
		const routesToBeRedirect = ["/", "/login"];
		const hasToBeRedirected = routesToBeRedirect.includes(location.pathname);

		if (hasToBeRedirected) {
			return history.push("/admin");
		}
	};

	useAuthRoute(onSuccess);
	return (
		<Routes>
			<Route
				path="/admin/*"
				element={<AdminRoutes />}
			/>
			<Route
				path="/login"
				element={<Login />}
			/>
			<Route
				index
				element={<Loading />}
			/>
			<Route
				path="/recovery"
				element={<Recovery />}
			/>
			<Route
				path="/recovery/token/:token"
				element={<NewPassword />}
			/>
		</Routes>

	);
});

export default App;
