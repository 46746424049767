import { ComponentStyleConfig } from "@chakra-ui/react";
import { theme } from "@chakra-ui/theme";

export const Button: ComponentStyleConfig = {
	defaultProps: {
		variant: "solid",
	},
	variants: {
		solid: (props) => ({
			...theme.components.Button.variants.solid(props),
			borderWidth: "1px",
			textColor: "primary.900",
			_hover: {
				bg: "primary.200",
			},
		}),
		outline: (props) => ({
			...theme.components.Button.variants.outline(props),
			textColor: "primary.50",
			borderWidth: "1px",
			_hover: {
				color: "primary.900",
			},
			_active: {
				color: "black",
				bg: "primary.50",
			},
		}),
	},
};
