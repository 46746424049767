import React from "react";
import { observer } from "mobx-react-lite";
import {
	Flex,
	VStack,
	Spinner,
} from "@chakra-ui/react";
import {
	Card,
} from "~/components";

export const Loading: React.FC = observer(() => (
	<Flex
		justifyContent="center"
		alignItems="center"
		w="100%"
		minH="100vh"
		bg="primary.700"
	>
		<Card
			display="flex"
			alignItems="center"
			maxW={{ base:"100vw", sm: "28rem" }}
			minH={{ base: "100vh", sm: "30rem" }}
			rounded={{ base: 0, sm: 10 }}
			w="100%"
			bgColor="primary.500"
		>
			<VStack spacing="8" w="100%">
				<Spinner color="primary.700" />
			</VStack>
		</Card>
	</Flex>
));
