import React from "react";
import { Flex } from "@chakra-ui/react";
import { DetailsRow } from "~/components";
import strings from "~/resources/strings";
import api from "~/resources/api";

interface IProps {
	bankAccount: api.User;
}

export const BankAccountDetails: React.FC<IProps> = (props) => {
	const { bankAccount } = props;
	const commonStrings = strings.common;

	return (
		<>
			<DetailsRow
				label={commonStrings.fields.bankName}
				value={bankAccount.bankName}
			/>
			<Flex
				w="100%"
				flexDirection={{
					base: "column",
					md: "row",
				}}
				alignItems="center"
				justifyContent="space-between"
			>
				<DetailsRow
					label={commonStrings.fields.account}
					value={bankAccount.account}
					boxProps={{
						w: {
							base: "100%",
							md: "45%",
						},
						mb: {
							base: 8,
							md: 0,
						},
					}}
				/>
				<DetailsRow
					label={commonStrings.fields.accountDv}
					value={bankAccount.accountDv}
					boxProps={{
						w: {
							base: "100%",
							md: "45%",
						},
						mb: {
							base: 8,
							md: 0,
						},
					}}
				/>
			</Flex>
			<Flex
				w="100%"
				flexDirection={{
					base: "column",
					md: "row",
				}}
				alignItems="center"
				justifyContent="space-between"
			>
				<DetailsRow
					label={commonStrings.fields.agency}
					value={bankAccount.agency}
					boxProps={{
						w: {
							base: "100%",
							md: "45%",
						},
						mb: {
							base: 8,
							md: 0,
						},
					}}
				/>
				<DetailsRow
					label={commonStrings.fields.agencyDv}
					value={bankAccount.agencyDv}
					boxProps={{
						w: {
							base: "100%",
							md: "45%",
						},
						mb: {
							base: 8,
							md: 0,
						},
					}}
				/>
			</Flex>
		</>
	);
};
