import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import api from "~/resources/api";
import strings from "~/resources/strings";
import { showInfoToast } from "~/resources/toast";
import { useGlobalStore } from "../contexts/useGlobalContext";

export const useAuthRoute = (onSuccess?: (currenAdminUser?: api.AdminUser) => void) => {
	const { authStore } = useGlobalStore();
	const location = useLocation();
	const navigate = useNavigate();


	const onError = () => {
		if (location.pathname.includes("/recovery")){
			return ;
		}
		navigate("/login", { replace: true });
	};

	if (authStore.currentAdminUser && authStore.currentAdminUser?.type === api.AdminUserType.advisor) {
		navigate("/login", { replace: true });
		showInfoToast(strings.error.accessDenied);
		authStore.logout(() => {});
		return;
	}

	useEffect(() => {
		authStore.authenticate(onSuccess, onError );
	}, [authStore.loginRefresh.value]);

	return authStore.loader.isLoading;
};
