import React from "react";
import { observer } from "mobx-react-lite";
import {
	Flex,
	Button,
	Image,
	Input,
	InputGroup,
	InputLeftAddon,
	Box,
} from "@chakra-ui/react";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import { Card } from "~/components";
import strings from "~/resources/strings";
import { useHistory } from "~/hooks/useHistory";
import { showErrorToast, showSuccessToast } from "~/resources/toast";

export const Login: React.FC = observer(() => {
	const pageStrings = strings.pages.login;
	const history = useHistory();
	const { authStore } = useGlobalStore();

	const onSuccess = (userName: string) => {
		showSuccessToast(pageStrings.success(userName));
		authStore.loginRefresh.setValue(!authStore.loginRefresh.value);
	};

	const onError = (errorMessage: string) => {
		showErrorToast(errorMessage);
	};

	const login = () => {
		authStore.login(onSuccess, onError);
	};

	const toRecovery = () => {
		history.push("/recovery");
	};

	return (
		<Flex
			justifyContent="center"
			alignItems="center"
			w="100%"
			minH="100vh"
			bg="primary.700"
			direction="column"
		>
			<Image src="./logo.png" alt={pageStrings.altImg} />
			<Card
				display="flex"
				alignItems="center"
				maxW={{ base:"100vw", sm: "26rem" }}
				minH={{ base: "100vh", sm: "25rem" }}
				rounded={{ base: 0, sm: 10 }}
				w="100%"
				bgColor="primary.500"
				p={5}
			>
				<Flex
					direction="column"
					mt={5}
					gap={3}
					w="100%"
				>
					<InputGroup>
						<InputLeftAddon
							bg="transparent"
							h={69}
							rounded="xl"
						>
							<Image
								w={4}
								mx={2}
								src="./envelope.svg"
								alt={pageStrings.altImg}
							/>
						</InputLeftAddon>
						<Input
							{...authStore.form.field("email")}
							disabled={authStore.loader.isLoading}
							p={6}
							placeholder={pageStrings.fields.email}
						/>
					</InputGroup>
					<InputGroup>
						<InputLeftAddon
							bg="transparent"
							h={69}
							rounded="xl"
						>
							<Image
								w={4}
								mx={2}
								src="./padlock.svg"
								alt={pageStrings.altImg}
							/>
						</InputLeftAddon>
						<Input
							type="password"
							{...authStore.form.field("password")}
							disabled={authStore.loader.isLoading}
							p={6}
							placeholder={pageStrings.fields.password}
						/>
					</InputGroup>
					<Button
						h={58}
						mt={2}
						w="100%"
						onClick={login}
					>
						{pageStrings.loginIn}
					</Button>
					<Flex mt={3}>
						<Box
							onClick={toRecovery}
							cursor="pointer"
							mx="auto"
							textDecoration="underline"
							color="primary.400"
						>
							{pageStrings.recoveryPassword}
						</Box>
					</Flex>
				</Flex>
			</Card>
		</Flex>
	);
});
