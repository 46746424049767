import React from "react";
import { observer } from "mobx-react-lite";
import {
	Button,
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	StackProps,
} from "@chakra-ui/react";

interface IProps extends StackProps {
	onClick?: () => void;
	isOpen: boolean;
	hasClearOption?: boolean;
	title?: string;
	buttonText?: string;
	onClose: () => void;
	size?: string;
}

export const ModalComponent: React.FC<IProps> = observer((props) => {
	const {
		isOpen,
		title,
		onClick,
		onClose,
		buttonText,
		size,
	} = props;
	return (
		<>
			<Modal
				size={size ? size : "xl"}
				isCentered
				isOpen={isOpen}
				onClose={onClose}
			>
				<ModalOverlay backdropFilter="blur(10px) hue-rotate(90deg)" />
				<ModalContent>
					<ModalHeader color="primary.500">{title}</ModalHeader>
					<ModalBody>
						{props.children}
					</ModalBody>
					<ModalFooter>
						{onClick && (
							<Button w="100%" onClick={onClick}>{buttonText}</Button>
						)}
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
});

