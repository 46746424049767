import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import {
	Button,
	Flex,
	Heading,
	Textarea,
	Text,
	VStack,
} from "@chakra-ui/react";
import {
	CentralizedCard,
	FileAttach,
	TextInput,
} from "~/components";
import strings from "~/resources/strings";
import { useHistory } from "~/hooks/useHistory";
import Store from "./store";
import api from "~/resources/api";

const Create: React.FC = () => {
	const { id } = useParams<{id: string}>();
	const history = useHistory();
	const store = useLocalObservable(() => new Store(id));

	const commonStrings = strings.common;
	const pageStrings = strings.pages.product.notification;

	React.useEffect(() => {

		if (store.file.getPickerFields().uncertainfiedFile) {
			store.files.setValue([...store.files.value, store.file.uncertainfiedFile as api.UncertainFile]);
			store.filesName.setValue([...store.filesName.value, store.file.fileName || ""]);
			return;
		}

	},[store.file.getPickerFields().uncertainfiedFile]);

	const onRemove = (index: number) =>{
		store.files.value.splice(index, 1);
		store.filesName.value.splice(index, 1);
	};

	const onSuccess = () => {
		history.goBack();
	};

	return (
		<Flex
			mx="auto"
			w={{
				base: "100%",
				md: "80%",
				xl: "55%",
			}}
		>
			<CentralizedCard
				title={{
					text: pageStrings.create.title,
				}}
				altPaddingForCard
			>
				<Heading fontSize="md" color="primary.500">{commonStrings.create}</Heading>
				<TextInput
					placeholder={commonStrings.fields.title}
					_placeholder={{ fontWeight: "bold", color: "white" }}
					isDisabled={store.loader.isLoading}
					errorText={store.formShelf.field("name").error}
					{...store.formShelf.field("name")}
				/>
				<FileAttach
					labelText={commonStrings.fields.files}
					onClick={() => store.file.getPickerFields().pick()}
					files={store.filesName.value}
					onRemove={onRemove}
				/>
				<Textarea
					p={3}
					placeholder={commonStrings.fields.text}
					isDisabled={store.loader.isLoading}
					minH="30vh"
					_placeholder={{
						color: "white",
					}}
					{...store.formShelf.field("description")}
				/>
				<VStack spacing={8} mt={5}>
					<Button
						w="100%"
						mt={5}
						h={58}
						isLoading={store.loader.isLoading}
						onClick={() => store.createNotification(onSuccess)}
					>
						{commonStrings.buttons.saveOrEdit(!!id)}
					</Button>
					<Text
						cursor="pointer"
						onClick={() => history.goBack()}
						textDecoration="underline"
						align="center"
						mt={10}
					>
						{strings.common.buttons.backButton}
					</Text>
				</VStack>
			</CentralizedCard>
		</Flex>
	);
};

export default observer(Create);
