import React from "react";
import { observer } from "mobx-react-lite";
import {
	Center,
	IconButton,
	Image,
	Tooltip,
	Text,
	Box,
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import { Loading } from "../Loading";
import strings from "../../resources/strings";
import imagePlaceholder from "../../../static/imgPicker.svg";

export interface IProps {
	pickImage?: () => void;
	src: string | null;
	loading?: boolean;
	onDelete?: () => void;
}
export const SmallImagePicker: React.FC<IProps> = observer((props) => {
	const {
		pickImage,
		src,
		loading,
		onDelete,
	} = props;

	const componentStrings = strings.components.imagePicker;

	return (
		<Center
			width={120}
			height={120}
			minW={120}
			position="relative"
			overflow="hidden"
		>
			{(src && onDelete)&& (
				<IconButton
					position="absolute"
					variant="icon"
					right={1}
					top={1}
					colorScheme="secondary"
					aria-label="Edit"
					size="sm"
					icon={<DeleteIcon />}
					onClick={onDelete}
				/>
			)}
			<Tooltip label={componentStrings.selectAnImage}>
				<Box
					minW={120}
					border="1px solid white"
					p={src ? 0 : 9}
					rounded="md"
				>
					<Image
						h={src ? "100%" : 45}
						minW={45}
						w={src ? 120 : 50}
						src={src ? src : imagePlaceholder}
						onClick={pickImage}
						fallbackSrc={imagePlaceholder}
						rounded="lg"
						cursor="pointer"
						__css={{ filter: loading && "brightness(0.5)" }}
					/>
				</Box>
			</Tooltip>
			{loading && (
				<Center position="absolute" gap={3}>
					<Text>
						{strings.components.imagePicker.await}
					</Text>
					<Loading size={5} color="black" />
				</Center>
			)}
		</Center>
	);
});
