import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import {
	Box,
	Button,
	Stack,
	Switch,
	Text,
	Flex,
} from "@chakra-ui/react";
import {
	AutoComplete,
	CentralizedCard,
	FileAttach,
	MoneyInput,
	SelectItem,
	TextInput,
	DatePicker,
} from "~/components";
import strings from "~/resources/strings";
import { useHistory } from "~/hooks/useHistory";
import Store from "./store";
import api from "~/resources/api";

interface IProps {
	toUser?: boolean;
}

const CreateLinkedAUser: React.FC<IProps> = (props) => {
	const { toUser } = props;
	const [isOpen, setIsOpen] = React.useState(false);
	const [isOpenProductModal, setIsOpenProductModal] = React.useState(false);
	const commonStrings = strings.common;
	const pageStrings = strings.pages.product.createOrEdit;
	const { id } = useParams<{ id: string }>();
	const history = useHistory();
	const store = useLocalObservable(() => new Store(id || "", toUser));

	React.useEffect(() => {
		if (store.file.getPickerFields().uncertainfiedFile) {
			store.files.setValue([...store.files.value, store.file.fileName!]);
			return;
		}
	},[store.file.getPickerFields().uncertainfiedFile]);

	const onRemove = (index: number) =>{
		store.files.value.splice(index, 1);
	};

	const onSuccess = () => {
		history.goBack();
	};

	return (
		<Flex
			w={{
				base:"100%",
				md:"90%",
				lg:"80%",
				xl:"60%",
			}}
			mx="auto"
		>
			<CentralizedCard
				title={{
					text: pageStrings.titlePurchased,
				}}
				altPaddingForCard
			>
				<Box>
					<Text fontWeight="bold">{strings.common.fields.credit}</Text>
					<Flex gap={3} alignItems="center">
						{store.product.value && (
							<Text>{store.product.value.name}</Text>
						)}
						<Button h={7} onClick={() => setIsOpenProductModal(true)}>{pageStrings.selectCredit}</Button>
					</Flex>
				</Box>
				<Box>
					<Text fontWeight="bold">{strings.common.fields.user}</Text>
					<Flex gap={3} alignItems="center">
						{store.user.value && (
							<Text>{store.user.value.name}</Text>
						)}
						<Button h={7} onClick={() => setIsOpen(true)}>{pageStrings.selectUser}</Button>
					</Flex>
				</Box>
				<AutoComplete
					onSearch={store.paginatedListShelfUser.refresh}
					isOpen={isOpen}
					onClose={() => setIsOpen(false)}
					onChange={store.name.setValue}
					listProps={{
						data:store.paginatedListShelfUser.items,
						loading: store.paginatedListShelfUser.loader.isLoading,
						renderItem: (data: api.User, index: number) =>
							(
								<SelectItem
									name={data.name}
									key={index}
									onClick={() => {
										store.user.setValue(data);
										setIsOpen(false);
									}}
								/>
							),
						paginantionProps: {
							currentPage: store.paginatedListShelfUser.page,
							nextPage: store.paginatedListShelfUser.nextPage,
							prevPage: store.paginatedListShelfUser.previousPage,
							hasNextPage: store.paginatedListShelfUser.hasNextPage,
						},
					}}
				/>
				<AutoComplete
					onSearch={store.paginatedListShelfProduct.refresh}
					isOpen={isOpenProductModal}
					onClose={() => setIsOpenProductModal(false)}
					onChange={store.name.setValue}
					listProps={{
						data:store.paginatedListShelfProduct.items,
						loading: store.paginatedListShelfProduct.loader.isLoading,
						renderItem: (data: api.Product, index: number) =>
							(
								<SelectItem
									name={data.name}
									key={index}
									onClick={() => {
										store.product.setValue(data);
										setIsOpenProductModal(false);
									}}
								/>
							),
						paginantionProps: {
							currentPage: store.paginatedListShelfProduct.page,
							nextPage: store.paginatedListShelfProduct.nextPage,
							prevPage: store.paginatedListShelfProduct.previousPage,
							hasNextPage: store.paginatedListShelfProduct.hasNextPage,
						},
					}}
				/>
				<MoneyInput
					labelText={commonStrings.fields.investedAmount}
					value={store.investedAmount.value}
					onChange={store.investedAmount.setValue}
				/>
				<DatePicker
					placeholder={strings.components.datePicker.investmentDate}
					onChangeDate={store.investmentDate.setValue}
					selectedDate={store.investmentDate.value}
				/>
				<TextInput
					labelText={commonStrings.fields.percentageExpectedValue}
					isDisabled={store.loader.isLoading}
					prefix="%"
					onChange={(e) => store.percentageExpectedValue.setValue(e.target.value)}
					value={store.percentageExpectedValue.value}
				/>
				<FileAttach
					labelText={commonStrings.fields.files}
					onClick={() => store.file.getPickerFields().pick()}
					files={store.files.value}
					onRemove={onRemove}
				/>
				<Stack>
					<Text color="white" fontSize={16}>{commonStrings.fields.isFinalized}</Text>
					<Box>
						<Switch
							isChecked={store.isFinalized.value}
							mb={8}
							colorScheme="teal"
							onChangeCapture={() => store.isFinalized.setValue(!store.isFinalized.value)}
							size="lg"
						/>
					</Box>
				</Stack>
				{store.isFinalized.value && (
					<>
						<MoneyInput
							labelText={commonStrings.fields.grossValue}
							value={store.grossValue.value}
							onChange={store.grossValue.setValue}
						/>
						<DatePicker
							placeholder={strings.components.datePicker.finalizedDate}
							onChangeDate={store.finalizedDate.setValue}
							selectedDate={store.finalizedDate.value}
						/>
					</>
				)}
				<Stack spacing={5}>
					<Button
						w="100%"
						padding={8}
						isLoading={store.loader.isLoading}
						onClick={() => store.createPurchasedProduct(onSuccess)}
					>
						{commonStrings.buttons.done}
					</Button>
					<Text
						cursor="pointer"
						onClick={() => history.goBack()}
						textAlign="center"
						textDecorationLine="underline"
					>
						{pageStrings.back}
					</Text>
				</Stack>
			</CentralizedCard>
		</Flex>
	);
};

export default observer(CreateLinkedAUser);
