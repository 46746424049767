import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import {
	Button,
	Flex,
	Heading,
	Text,
	Image,
	RadioGroup,
	Stack,
	Radio,
} from "@chakra-ui/react";
import {
	AutoComplete,
	BankForm,
	CentralizedCard,
	SelectItem,
	TextInput,
} from "~/components";
import strings from "~/resources/strings";
import { useHistory } from "~/hooks/useHistory";
import Store from "./store";
import api from "~/resources/api";
import PencilIcon from "../../../../static/pencilIcon.svg";

const CreateOrEdit: React.FC = () => {
	const { id } = useParams<{id: string}>();
	const history = useHistory();
	const store = useLocalObservable(() => new Store(id || ""));
	const commonStrings = strings.common;
	const [ isOpen, setIsOpen ] = React.useState(false);

	const onClickAdvisor = (data: api.AdminUser) => {
		store.advisorSelected.setValue(data);
		setIsOpen(false);
	};

	const onSuccess = () => {
		history.goBack();
	};

	return (
		<CentralizedCard
			button={(
				<Button
					minW={{ base: "80%", md: 340 }}
					size="lg"
					mb={5}
					isLoading={store.loader.isLoading}
					onClick={() => store.createOrEditUser(onSuccess)}
				>
					{commonStrings.buttons.saveOrEdit(!!id)}
				</Button>
			)}
			altPaddingForCard
		>
			<Heading size="sm">
				{strings.pages.users.createOrEdit.advisor}
			</Heading>
			<Flex flexDirection="row">
				<Button
					variant="unstyled"
					fontSize={16}
					mt={-4}
					onClick={() => {
						store.advisorNameFilter.setValue("");
						store.advisors.refresh();
						setIsOpen(true);
					}}
				>
					<Flex flexDirection="row">
						<Text fontWeight={300}>
							{store.advisorSelected.value?.name}
						</Text>
						<Image src={PencilIcon} mx={1} />
					</Flex>
				</Button>
			</Flex>
			<AutoComplete
				isOpen={isOpen}
				onClose={() => setIsOpen(false)}
				onSearch={store.advisors.refresh}
				onChange={store.inputValue.setValue}
				listProps={{
					data: store.advisors.items,
					loading: store.advisors.loader.isLoading,
					renderItem: (data: api.AdminUser, index) => (
						<SelectItem
							name={data.name}
							defaultValue={store.advisorNameFilter.value!}
							onClick={() => onClickAdvisor(data)}
							key={index}
						/>
					),
					paginantionProps: {
						currentPage: store.advisors.page,
						nextPage: store.advisors.nextPage,
						prevPage: store.advisors.previousPage,
						hasNextPage: store.advisors.hasNextPage,
					},
				}}
			/>
			<TextInput
				labelText={commonStrings.fields.name}
				labelProps={{ fontWeight: "bold" }}
				type="text"
				isDisabled={store.loader.isLoading}
				errorText={store.formShelf.field("name").error}
				{...store.formShelf.field("name")}
			/>
			<TextInput
				labelText={commonStrings.fields.email}
				labelProps={{ fontWeight: "bold" }}
				type="text"
				isDisabled={store.loader.isLoading}
				errorText={store.formShelf.field("email").error}
				{...store.formShelf.field("email")}
			/>

			{!id && (
				<RadioGroup defaultValue={store.isCpf.value ? "1" : "2"} onChange={(e) => store.isCpf.setValue(e === "1")}>
					<Stack spacing={5} direction='row'>
						<Radio value="1">
							{commonStrings.fields.cpf}
						</Radio>
						<Radio value="2">
							{commonStrings.fields.cnpj}
						</Radio>
					</Stack>
				</RadioGroup>
			)}
			{store.isCpf.value ? (
				<TextInput
					labelText={commonStrings.fields.cpf}
					labelProps={{ fontWeight: "bold" }}
					type="text"
					isDisabled={store.loader.isLoading}
					mask="999.999.999-99"
					errorText={store.formShelf.field("documentNumber").error}
					{...store.formShelf.field("documentNumber")}
				/>
			): (
				<TextInput
					labelText={commonStrings.fields.cnpj}
					labelProps={{ fontWeight: "bold" }}
					type="text"
					isDisabled={store.loader.isLoading}
					mask="99.999.999/9999-99"
					errorText={store.formShelf.field("documentNumber").error}
					{...store.formShelf.field("documentNumber")}
				/>
			)}
			<TextInput
				labelText={commonStrings.fields.phone}
				labelProps={{ fontWeight: "bold" }}
				type="text"
				isDisabled={store.loader.isLoading}
				mask="(99) 99999-9999"
				errorText={store.formShelf.field("phone").error}
				{...store.formShelf.field("phone")}
			/>
			<TextInput
				labelText={commonStrings.fields.whatsapp}
				labelProps={{ fontWeight: "bold" }}
				type="text"
				isDisabled={store.loader.isLoading}
				mask="(99) 99999-9999"
				errorText={store.formShelf.field("whatsapp").error}
				{...store.formShelf.field("whatsapp")}
			/>
			{!id && (
				<TextInput
					labelText={commonStrings.fields.password}
					labelProps={{ fontWeight: "bold" }}
					type="password"
					isDisabled={store.loader.isLoading}
					errorText={store.formPassword.field("password").error}
					{...store.formPassword.field("password")}
				/>
			)}
			<BankForm
				formValues={{
					field: store.formShelfBankAccount.field,
				}}
				isLoading={store.loader.isLoading}
			/>
		</CentralizedCard>
	);
};

export default observer(CreateOrEdit);
