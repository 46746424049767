import React from "react";
import {
	Td,
	IconButton,
	Tooltip,
} from "@chakra-ui/react";
import {
	BiBlock,
} from "react-icons/bi";
import {
	SearchIcon,
	EditIcon,
	DeleteIcon,
	Search2Icon,
	LockIcon,
	AddIcon,
} from "@chakra-ui/icons";
import strings from "~/resources/strings";

interface IProps {
	onApprove?: () => void;
	onDelete?: () => void;
	onView?: () => void;
	onEdit?: () => void;
	onBlock?: () => void;
	onClick?: () => void;
	onCreate?: () => void;
	isBlocked?: boolean;
	isRight?: boolean;
}

export const TableCellWithActionButtons: React.FC<IProps> = (props) => {
	const {
		onApprove,
		onClick,
		onDelete,
		onView,
		onEdit,
		onBlock,
		onCreate,
		isBlocked,
		isRight,
	} = props;

	return (
		<Td
			h="100%"
			display="flex"
			justifyContent={isRight ? "flex-end" : "center"}
			alignItems="center"
			flexDirection="row"
			gap={4}
		>
			{onView && (
				<Tooltip label={strings.common.details}>
					<IconButton
						variant="icon"
						colorScheme="secondary"
						size="60px"
						aria-label="Search"
						icon={<SearchIcon color="primary.50" />}
						onClick={onView}
					/>
				</Tooltip>
			)}
			{onApprove && (
				<Tooltip label={strings.common.approve}>
					<IconButton
						variant="icon"
						colorScheme="secondary"
						size="md"
						aria-label="Search"
						icon={<LockIcon color="primary.500" />}
						onClick={onApprove}
					/>
				</Tooltip>
			)}
			{onEdit && (
				<Tooltip label={strings.common.edit}>
					<IconButton
						variant="icon"
						colorScheme="white"
						aria-label="Edit"
						size="60px"
						icon={<EditIcon color="white" />}
						onClick={onEdit}
						height="40px"
					/>
				</Tooltip>
			)}
			{
				onDelete
					&&
						<Tooltip label={strings.common.delete}>
							<IconButton
								variant="icon"
								colorScheme="white"
								size="60px"
								aria-label="Delete"
								onClick={onDelete}
								icon={<DeleteIcon color="white" />}
							/>
						</Tooltip>
			}
			{
				onClick
					&&
						<Tooltip label={strings.common.details}>
							<IconButton
								variant="icon"
								colorScheme="white"
								size="60px"
								aria-label="Delete"
								onClick={onClick}
								icon={<Search2Icon />}
							/>
						</Tooltip>
			}
			{
				onCreate
					&&
						<Tooltip label={strings.common.create}>
							<IconButton
								variant="icon"
								colorScheme="secondary"
								size="60px"
								aria-label="Delete"
								onClick={onCreate}
								icon={<AddIcon />}
							/>
						</Tooltip>
			}
			{
				onBlock
					&&
					(
						isBlocked
							? (
								<Tooltip label={strings.common.enable}>
									<IconButton
										variant="icon"
										color="red"
										size="60px"
										aria-label="Disable"
										onClick={onBlock}
										icon={<BiBlock />}
									/>
								</Tooltip>
							) : (
								<Tooltip label={strings.common.disable}>
									<IconButton
										variant="icon"
										color="green"
										size="60px"
										aria-label="Enable"
										onClick={onBlock}
										icon={<BiBlock />}
									/>
								</Tooltip>
							))
			}
		</Td>
	);
};
