import Create from "./Create";
import Edit from "./Edit";
import Details from "./Details";


export const Notification = {
	Create,
	Edit,
	Details,
};

