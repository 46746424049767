import { makeAutoObservable } from "mobx";
import { FormShelf, ImagePickerShelf } from "@startapp/mobx-utils/src/web";
import { AttributeShelf, LoaderShelf } from "@startapp/mobx-utils";
import api from "~/resources/api";
import { Errors } from "~/resources/errors";
import strings from "~/resources/strings";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import { CustomError } from "~/resources/customError";
import { onFetchError } from "~/resources/fetchError";

const pageStrings = strings.pages.posts.createOrEdit;

export default class Store {
	public loader = new LoaderShelf();
	public imageShelf = new ImagePickerShelf(api.uploadImage);
	public id = new AttributeShelf("");


	public formShelf = new FormShelf({
		title: "",
		description: "",
	});

	constructor(id: string) {
		makeAutoObservable(this);

		if (id) {
			this.id.setValue(id);
			this.getPost(id);
		}
	}

	public getPost = async (id: string) => {
		this.loader.tryStart();
		try {
			const post = await api.getPostById(id);
			this.setInitValues(post);
		} catch (e) {
			const error = Errors.handleError(e);
			showErrorToast(error);
		} finally {
			this.loader.end();
		}
	};

	public setInitValues = (data: api.Post) => {
		this.formShelf = new FormShelf({
			title: data.title,
			description: data.description || "",
		});
		this.imageShelf.getPickerFields().setUploadedImage(data.image || null);
	};

	public get postData() {
		const data = this.formShelf.getValues();
		return {
			title: data.title,
			description: data.description,
			image: this.imageShelf.uncertainfiedImage!,
		};
	}

	public createOrEditPost = async (onSuccess: () => void) => {
		this.loader.start();
		try {
			if (this.id.value) {
				await api.editPost(this.id.value, this.postData);
			} else {
				await api.createPost({...this.postData});
			}
			showSuccessToast(pageStrings.success(!!this.id.value));
			onSuccess();
		} catch (e) {
			const error = Errors.treatError(e);
			if (error.type === api.ErrorType.Validation) {
				this.formShelf.fieldError.cleanAndAddErrors(JSON.parse((e as Error).message));
			} else {
				if (!this.postData.image) {
					const customError = new CustomError(api.ErrorType.MissingArgument, strings.error.selectImage);
					showErrorToast(customError.message);
				} else {
					onFetchError(e);
				}
			}
		} finally {
			this.loader.end();
		}
	};
}
