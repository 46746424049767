import React from "react";
import {
	Flex,
	Stack,
	Heading,
	BoxProps,
} from "@chakra-ui/react";

import {
	Card,
	Loading,
} from "~/components";

export interface IProps {
	title?: {
		text: string;
		helper?: React.ReactElement;
	};
	button?: React.ReactElement;
	isTable?: boolean;
	children?: React.ReactNode;
	isLoading?: boolean;
	boxProps?: BoxProps;
	altPaddingForCard?: boolean;
	widthCardIR?: string;
}

export const CentralizedCard: React.FC<IProps> = (props) => {
	const {
		title,
		button,
		children,
		widthCardIR,
		isLoading,
		boxProps,
	} = props;

	return (
		<Flex
			w={{base:"95%",md:"75%"}}
			mx="auto"
			flexDirection="column"
			alignItems="center"
			color="white"
			justifyContent="center"
			mt={title ? 90 : 15}
		>
			{title && (
				<Flex
					w="100%"
					mx={{base:2, md: 0}}
					maxW={{ base:"95%", md:"75%", lg: "60%"}}
					mb={8}
					alignItems="center"
					justifyContent="center"
					{...boxProps}
				>
					<Heading
						display="inline-block"
						size="lg"
						color="secondary.50"
						mt={25}
						textAlign="center"
					>
						{title.text}
					</Heading>
					{title.helper}
				</Flex>
			)}
			<Card
				w={widthCardIR || "100%"}
				bgColor="primary.500"
				borderRadius={20}
				maxW={{
					base:"100%",
				}}
				p={6}
				mx={3}
				mb={5}
			>
				{
					isLoading ? (
						<Loading />
					) : (
						<Stack w="100%" spacing={2}>
							{children}
						</Stack>
					)
				}
			</Card>
			{button}
		</Flex>
	);
};
