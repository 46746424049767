import { makeAutoObservable } from "mobx";
import {
	AttributeShelf,
	FetchModelShelf,
	LoaderShelf,
	PaginatedListShelf,
} from "@startapp/mobx-utils";
import api from "~/resources/api";
import { onFetchError } from "~/resources/fetchError";
import strings from "~/resources/strings";
import { showSuccessToast } from "~/resources/toast";

export class Store {

	public fetchModelShelf: FetchModelShelf<api.Product>;
	public loader = new LoaderShelf();
	public paginatedListShelfNotification: PaginatedListShelf<api.Notification>;
	public paginatedListShelfUsers: PaginatedListShelf<api.PurchasedProduct>;
	public purchasedProductId = new AttributeShelf<string>("");
	public notificationId = new AttributeShelf<string>("");

	constructor(id: string) {
		makeAutoObservable(this);
		this.fetchModelShelf = new FetchModelShelf(id, () => api.getProductById(id),
			{
				fetchOnConstructor: true,
				onFetchError,
			},
		);

		this.paginatedListShelfNotification = new PaginatedListShelf(
			(page) => api.getAllNotificationByProduct(page, id),
			{
				fetchOnConstructor: true,
				onFetchError,
			},
		);

		this.paginatedListShelfUsers = new PaginatedListShelf(
			(page) =>  api.getPurchasedProductsByProductId(page, id),
			{
				fetchOnConstructor: true,
				onFetchError,
			},
		);
	}

	public deleteNotification = async (id: string) => {
		this.loader.start();
		try {
			await api.deleteNotification(id);
			showSuccessToast(strings.common.deleteSuccess);
			this.paginatedListShelfNotification.refresh();
		} catch (e) {
			onFetchError(e);
		} finally {
			this.loader.end();
		}
	};

	public deletePurchasedProduct = async (id: string) => {
		this.loader.start();
		try {
			await api.deletePurchasedProduct(id);
			showSuccessToast(strings.common.deleteSuccess);
			this.paginatedListShelfUsers.refresh();
		} catch (e) {
			onFetchError(e);
		} finally {
			this.loader.end();
		}
	};
}
