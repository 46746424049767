import { makeAutoObservable } from "mobx";
import { AttributeShelf, LoaderShelf } from "@startapp/mobx-utils";
import { FormShelf, ImagePickerShelf } from "@startapp/mobx-utils/src/web";
import api from "~/resources/api";
import { Errors } from "~/resources/errors";
import strings from "~/resources/strings";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import { CustomError } from "~/resources/customError";
import { onFetchError } from "~/resources/fetchError";
import FilePickerShelf from "~/shelves/FilePickerShelf";

const pageStrings = strings.pages.product.createOrEdit;

export default class Store {
	public loader = new LoaderShelf();
	public imageShelf = new ImagePickerShelf(api.uploadImage);
	public id = new AttributeShelf("");
	public expirationDate = new AttributeShelf<Date>(new Date());
	public isFinalized = new AttributeShelf<boolean>(false);
	public file = new FilePickerShelf(api.uploadUncertainFile);

	public formShelf = new FormShelf({
		name: "",
		description: "",
	});

	constructor(id: string) {
		makeAutoObservable(this);

		if (id) {
			this.id.setValue(id);
			this.getProduct(id);
		}
	}

	public getProduct = async (id: string) => {
		this.loader.tryStart();
		try {
			const product = await api.getProductById(id);
			this.setInitValues(product);
		} catch (e) {
			onFetchError(e);
		} finally {
			this.loader.end();
		}
	};

	public setInitValues = (data: api.Product) => {
		this.formShelf = new FormShelf({
			name: data.name,
			description: data.description || "",
		});
		this.isFinalized.setValue(data.finalizedAt ? true : false);
		this.expirationDate.setValue(data.expirationDate);
		this.imageShelf.getPickerFields().setUploadedImage(data.image || null);
	};

	public get productData() {
		const data = this.formShelf.getValues();
		return {
			name: data.name,
			description: data.description,
			incomeTax: this.file.getPickerFields().uncertainfiedFile!,
			image: this.imageShelf.uncertainfiedImage!,
			expirationDate: this.expirationDate.value,
			finalizedAt: this.isFinalized.value ? new Date() : null,
		};
	}

	public createOrEditProduct = async (onSuccess: () => void) => {
		this.loader.start();
		try {
			if (this.id.value) {
				await api.editProduct(this.id.value, this.productData);
			} else {
				await api.createProduct({...this.productData});
			}
			showSuccessToast(pageStrings.success(!!this.id.value));
			onSuccess();
		} catch (e) {
			const error = Errors.treatError(e);
			if (error.type === api.ErrorType.Validation) {
				this.formShelf.fieldError.cleanAndAddErrors(JSON.parse((e as Error).message));
				showErrorToast(error.message);
			} else {
				if (!this.productData.image) {
					const customError = new CustomError(api.ErrorType.MissingArgument, strings.error.selectImage);
					showErrorToast(customError.message);
				} else {
					onFetchError(e);
				}
			}
		} finally {
			this.loader.end();
		}
	};
}
