import { ComponentStyleConfig } from "@chakra-ui/react";
import { theme } from "@chakra-ui/theme";

export const Input: ComponentStyleConfig = {
	defaultProps: {
		variant: "outline",
		_hover: {
			bg: "gray.200",
		},
	},
	variants: {
		outline: (props) => ({
			...theme.components.Input.variants.outline(props),
			field: {
				color: "secondary.50",
				bg: "transparent",
				_placeholder: {
					color: "secondary.50",
				},
				h: 69,
				borderRadius: 12,
				rounded:"xl",
				_focus:{
					boxShadow: "0 0 0 0",
					border: "1px solid white",
					outline: 0,
				},
			},
		}),
	},
};
