import React from "react";
import {
	Table as CTable,
	Thead,
	Tbody,
	Th,
	Tr,
	Flex,
	Heading,
	Button,
	BoxProps,
	Box,
} from "@chakra-ui/react";

import {
	Label,
	Card,
	Pagination,
	Loading,
} from "../";
import strings from "~/resources/strings";

interface IProps<DataType> extends BoxProps {
	data: DataType[];
	headers: string[];
	renderRow: (item: DataType, index: number) => React.ReactElement | null;
	loading: boolean;
	emptyMessage: string;
	currentPage: number;
	prevPage: () => void;
	nextPage: () => void;
	hasNextPage: boolean;
	onAdd?: () => void;
	addButtonText?: string;
	addButtonLoading?: boolean;
	tableTitle?: string;
	headerFilter?: JSX.Element;
	isCard?: boolean;
	headerColor?: string;
	exportCsv?: {
		onClick: () => void;
		isLoading?: boolean;
	};
}

export const Table = <DataType,>(props: IProps<DataType>) => {
	const {
		data,
		headers,
		renderRow,
		loading,
		emptyMessage,
		currentPage,
		prevPage,
		nextPage,
		hasNextPage,
		tableTitle,
		onAdd,
		addButtonText,
		addButtonLoading,
		headerFilter,
		isCard,
		headerColor,
		exportCsv,
		...rest
	} = props;

	return (
		<Flex
			w="100%"
			flexDir="column"
			alignItems="center"
			mb={5}
		>
			<Flex
				w={{base:"100%", md: "75%"}}
				justifyContent="space-between"
				mx="auto"
			>
				{tableTitle && (
					<Heading
						maxW={{ base:"100%", md: isCard ? "100%" : "75%" }}
						variant="secondary"
						size="md"
						color="white"
						mb={3}
						mt={isCard ? 5 : 0}
					>
						{tableTitle}
					</Heading>)}

				<Flex cursor="pointer" mr={{base:5, md: 10}}>
					{headerFilter && (headerFilter)}
				</Flex>
			</Flex>

			<Card
				display="initial"
				w="100%"
				maxW={{ base:"100%", md: isCard ? "100%" : "75%" }}
				px={0}
				mb={10}
				borderRadius={12}
				boxShadow={isCard ? 0 : "lg"}
				bg="primary.500"
				{...rest}
			>
				<Box
					maxHeight={{ base: 450, md: 600 }}
					overflowX="auto"
				>
					{loading || !data ? (
						<Flex my={5} w="100%" justifyContent="center">
							<Loading />
						</Flex>
					) : data.length < 1 ? (
						<Flex
							my={5}
							w="100%"
							justifyContent="center"
							minH={300}
							alignItems="center"
						>
							<Heading
								size="md"
								fontWeight="bold"
								color="white"
							>
								{emptyMessage}
							</Heading>
						</Flex>
					) : (
						<CTable
							variant="simple"
							ml="auto"
							height="100%"
						>
							<Thead
								borderBottomColor="white"
								borderBottomWidth={2}
								position="relative"
								h={14}
							>

								<Tr>
									{headers.map((header, index) => (
										<Th key={index}>
											<Label
												fontWeight="bold"
												color={headerColor || "white"}
												fontSize="sm"
											>
												{header}
											</Label>
										</Th>
									))}
								</Tr>

								{exportCsv && (
									<Button
										position="absolute"
										top={2}
										right={{ base: 5, md: 10 }}
										bg="secondary.400"
										onClick={exportCsv.onClick}
										isLoading={exportCsv.isLoading}
									>
										ASD
									</Button>
								)}
							</Thead>
							<Tbody color="white">
								{data.map((dataItem, index) => renderRow(dataItem, index))}
							</Tbody>
						</CTable>
					)}

				</Box>
				<Flex
					py={4}
					px={6}
					alignItems="center"
					justifyContent="flex-end"
				>
					<Pagination
						currentPage={currentPage}
						prevPage={prevPage}
						nextPage={nextPage}
						hasNextPage={hasNextPage}
					/>
				</Flex>
			</Card>
			{onAdd && (
				<Button
					w="100%"
					maxW={{
						sm: "100%",
						md: 330,
					}}
					fontSize='1.3rem'
					size="lg"
					mt={15}
					variant="outline"
					onClick={onAdd}
					isLoading={addButtonLoading}
					mb={isCard ? 10 : 0}
				>
					{addButtonText || strings.actions.add}
				</Button>
			)}
		</Flex>
	);
};
