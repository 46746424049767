import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import {
	Button,
	Link,
	Box,
	Stack,
	Text,
	Switch,
	Flex,
	VStack,
} from "@chakra-ui/react";
import { CentralizedCard, TextInput } from "~/components";
import strings from "~/resources/strings";
import Store from "./store";
import { useHistory } from "~/hooks/useHistory";

const CreateOrEdit: React.FC = () => {
	const { id } = useParams<{ id: string }>();
	const history = useHistory();
	const store = useLocalObservable(() => new Store(id));
	const pageStrings = strings.pages.adminUserTable.createOrEdit;
	const commonStrings = strings.common;

	const onSucess = () => {
		history.goBack();
	};

	return (
		<Flex
			w={{
				base:"100%",
				md:"90%",
				lg:"80%",
				xl:"60%",
			}}
			mx="auto"
		>
			<CentralizedCard
				title={{
					text: pageStrings.title(!!id),
				}}
			>
				<TextInput
					placeholder={commonStrings.fields.name}
					labelProps={{ fontWeight: "bold" }}
					labelColor="primary"
					type="text"
					isDisabled={store.loader.isLoading}
					errorText={store.formShelf.field("name").error}
					{...store.formShelf.field("name")}
				/>
				<TextInput
					placeholder={commonStrings.fields.email}
					labelProps={{ fontWeight: "bold" }}
					labelColor="primary"
					type="text"
					isDisabled={store.loader.isLoading}
					errorText={store.formShelf.field("email").error}
					{...store.formShelf.field("email")}
				/>

				{!id && (
					<>
						<TextInput
							placeholder={commonStrings.fields.password}
							labelProps={{ fontWeight: "bold" }}
							labelColor="primary"
							type="password"
							isDisabled={store.loader.isLoading}
							errorText={store.formShelf.field("password").error}
							{...store.formShelf.field("password")}
						/>
					</>
				)}
				{!id && (
					<Stack mb={5}>
						<Text color="white" fontSize={16}>{commonStrings.fields.isAdvisor}</Text>
						<Switch
							isChecked={store.isAdvisor.value}
							colorScheme="teal"
							onChangeCapture={() => store.isAdvisor.setValue(!store.isAdvisor.value)}
							size="lg"
						/>
					</Stack>
				)}
				<VStack w="100%">
					<Button
						mt={5}
						height="60px"
						w="100%"
						borderRadius="8px"
						isLoading={store.loader.isLoading}
						onClick={() => store.createOrEditAdmin(onSucess)}
					>
						{commonStrings.buttons.saveOrEdit(!!id)}
					</Button>
					<Box
						textAlign="center"
						color="secondary.50"
						pt={6}
					>
						<Link
							onClick={() => history.goBack()}
						>
							{strings.actions.back}
						</Link>
					</Box>
				</VStack>
			</CentralizedCard>
		</Flex>

	);
};

export default observer(CreateOrEdit);
