import React from "react";
import { observer } from "mobx-react-lite";
import {
	Flex,
	Center,
	Heading,
	Text,
	Tooltip,
} from "@chakra-ui/react";
import strings from "~/resources/strings";

interface IProps {
	onClick: () => void;
	onRemove: (index: number) => void;
	files: string[] | null;
	labelText?: string;
}

export const FileAttach: React.FC<IProps> = observer((props) => {
	const {
		onClick,
		onRemove,
		files,
		labelText,
	} = props;

	const componentString = strings.components.fileAttach;

	return (
		<Flex direction="column" color="white">
			<Heading
				size="md"
				mb={3}
				mt={5}
				cursor="default"
			>
				{labelText ? labelText : componentString.label}
			</Heading>
			<Flex gap={1} flexWrap="wrap">
				{files && files.map((file, index) => (
					<Center
						key={index}
						p={1}
						px={4}
						w="max-content"
						gap={3}
						mb={2}
						rounded="xl"
						cursor="pointer"
						onClick={() => onRemove(index)}
						border="dotted 1px white"
					>
						<Text>
							{file}
						</Text>
					</Center>
				))}
			</Flex>
			<Tooltip label={componentString.label}>
				<Center
					w={40}
					h={7}
					onClick={onClick}
					rounded="md"
					px={10}
					cursor="pointer"
					bg="primary.100"
					boxShadow="xl"
					_hover={{
						bg: "rgba(255,255,255,.2)",
						transition: ".4s",
					}}
				>
					<Text color="black" fontWeight="bold">Adicionar</Text>
				</Center>
			</Tooltip>
			<Text
				onClick={onClick}
				cursor="pointer"
				mt={2}
				mb={5}
			>
				{componentString.label}
			</Text>
		</Flex>
	);
});
