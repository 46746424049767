import { makeAutoObservable } from "mobx";
import {
	AttributeShelf,
	LoaderShelf,
	PaginatedListShelf,
} from "@startapp/mobx-utils";
import api from "~/resources/api";
import { onFetchError } from "~/resources/fetchError";
import { showSuccessToast } from "~/resources/toast";
import strings from "~/resources/strings";

const pageStrings = strings.pages.adminUserTable;

export default class Store {

	public paginetedListShelf: PaginatedListShelf<api.AdminUser>;
	public adminType = new AttributeShelf<boolean | null>(null);
	public name = new AttributeShelf<string>("");
	public loader = new LoaderShelf();

	constructor() {
		makeAutoObservable(this);
		this.paginetedListShelf = new PaginatedListShelf(
			(page) => api.getAllAdminUsers(page,
				this.filterByAdminType(),
				this.name.value,
			),
			{
				fetchOnConstructor: true,
				onFetchError,
			},
		);
	}

	public filterByAdminType = () => {
		switch (this.adminType.value){
			case true:
				return api.AdminUserType.master;
			case false:
				return api.AdminUserType.advisor;
			default:
				return null;
		}
	};

	public deleteAdmin = async (id: string) => {
		this.loader.start();
		try {
			await api.deleteAdminUser(id);
			showSuccessToast(pageStrings.table.delete);
			this.paginetedListShelf.refresh();
		} catch (e) {
			onFetchError(e);
		} finally {
			this.loader.end();
		}
	};
}
