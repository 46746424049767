import React from "react";
import { observer } from "mobx-react-lite";
import {
	Center,
	IconButton,
	Image,
	Tooltip,
	Text,
	Stack,
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import { Loading } from "../Loading";
import strings from "../../resources/strings";
import imagePlaceholder from "../../../static/imgPicker.svg";

export interface IProps {
	pickImage?: () => void;
	src: string | null;
	loading?: boolean;
	onDelete?: () => void;
}
export const ImagePicker: React.FC<IProps> = observer((props) => {
	const {
		pickImage,
		src,
		loading,
		onDelete,
	} = props;

	const componentStrings = strings.components.imagePicker;

	return (
		<Stack
			bg="primary.700"
			h="558px"
			w="100%"
			justifyContent="center"
			border="1px solid"
			borderColor="secondary.50"
			borderRadius="12px"
			onClick={pickImage}
			cursor="pointer"
		>
			<Center
				position="relative"
				overflow="hidden"
			>
				{(src && onDelete)&& (
					<IconButton
						position="absolute"
						variant="icon"
						right={1}
						top={1}
						colorScheme="secondary"
						aria-label="Edit"
						size="sm"
						icon={<DeleteIcon />}
						onClick={onDelete}
					/>
				)}
				<Tooltip label={componentStrings.selectAnImage}>
					<Image
						h="100%"
						p={0}
						width={src ? "100%" : "155px"}
						height={src ? "100%" : "139px"}
						m={0}
						src={src ? src : imagePlaceholder}
						fallbackSrc={imagePlaceholder}
						rounded="lg"
						__css={{ filter: loading && "brightness(0.5)" }}
					/>
				</Tooltip>
				{loading && (
					<Center
						position="absolute"
						gap={3}
					>
						<Text>
							{strings.components.imagePicker.await}
						</Text>
						<Loading
							size={5}
							color="black"
						/>
					</Center>
				)}
			</Center>
		</Stack>
	);
});
