import "moment/locale/pt-br";
import LocalizedStrings from "localized-strings";
import format from "./format";

const strings = new LocalizedStrings({
	ptBR: {
		removeCharactersExceptLetterNumber: (character: string) => character.replace(/[^a-zA-Z0-9]/g, ""),
		components: {
			autoCompleteModal: {
				back: "Voltar",
				userModal: {
					button: "Filtrar",
				},
			},
			autoCompleteList: {
				empty: "Não há itens na lista",
			},
			imagePicker: {
				selectAnImage: "Selecione uma imagem:",
				pdf: "PDF:",
				await: "Aguarde...",
			},
			pdfPicker: {
				selectAnDocument: "Anexar Documento",
				selectAnPdf: "Selecione um PDF.",
			},
			drawerSearch: {
				clear: "Limpar",
				close: "Fechar",
				placeholder: "Nome",
				search: "Filtrar",
				titleStatus: "Status do Créditos",
				titleAdmin: "Administrador",
				tipo: "Status do Créditos",
				status: {
					finalized: "Finalizado",
					notFinalized: "Em Andamento",
				},
				adminType: {
					adminMaster: "Master",
					advisor: "Assessor",
				},
			},
			datePicker: {
				expirationDate: "Data de Prazo",
				investmentDate: "Data de Investimento",
				finalizedDate: "Data de Finalização",
			},
			fileAttach: {
				label: "Clique em adicionar para escolher um ou mais arquivos.",
			},
		},
		nav: {
			home: "Home",
			adminsMaster: "Administradores Master",
			users: "Usuários",
			products: "Produtos",
			blog: "Blog",
			userDeleteRequests: "Solicitações de Exclusão",
			logout: "Sair do App",
		},
		actions: {
			save: "Salvar",
			delete: "Apagar",
			finish: "Finalizar",
			create: "Criar",
			cancel: "Cancelar",
			filter: "Filtrar",
			clear: "Limpar",
			select: "Selecionar",
			back: "Voltar",
			add: "Adicionar",
			send: "Enviar",
			attach: "Anexar",
			change: "Alterar",
			pick: "Escolher",
		},
		moment: {
			day: "DD",
			dateMonth: "DD/MM",
			date: "DD/MM/YYYY",
			dateHour: "DD/MM/YYYY HH[h]",
			dateTime: "DD/MM/YYYY HH:mm",
		},
		error: {
			stillLoading: "Ainda Carregando, aguarde.",
			notLoggedIn: "Usuario não logado",
			accessDenied: "Acesso Negado.",
			default: "Houve um erro, tente novamente mais tarde.",
			selectImage: "É necessário selecionar uma imagem.",
		},
		common: {
			yes: "Sim",
			no: "Não",
			date: "Data",
			create: "Criar",
			noResutls: "Sem Resultados",
			delete: "Clique para deletar",
			disable: "Clique para desabilitar",
			enable: "Clique para habilitar",
			details: "Clique para ver mais informações",
			approve: "Clique para aprovar ou recusar",
			edit: "Clique para editar",
			clinicCreate: "Criar Clínica",
			cancel: "Cancelar",
			finish: "Finalizar",
			confirm: "Confirmar",
			detailsTitle: "Detalhes",
			editTitle: "Editar",
			send: "Enviar",
			documentPhoto: "Foto:",
			incomeTaxCreate: "Criar IR",
			documentProof: "Comprovante:",
			documentProofNoResult: "Comprovante não encontrado",
			pdfView: "Ver documento",
			noRegistered: "Não Cadastrado",
			close: "Fechar",
			deleteSuccess: "Deletado com sucesso!",
			finishSuccess: "Produto Finalizado!",
			placeholderSearch: "Digite sua busca aqui!",
			buttons: {
				confirmButton: (isEdit?: boolean) => isEdit ? "Editar" : "Enviar",
				saveOrEdit: (isEdit?: boolean) => isEdit ? "Concluir" : "Enviar",
				backButton: "Voltar",
				add: "Adicionar",
				create: "Criar",
				approve: "Aprovar",
				refuse: "Recusar",
				done: "Concluir",
			},
			empty: "Campo vazio",
			infoUser: "Informações do Usuaŕio:",
			fields: {
				name: "Nome",
				status: "Status do Crédito",
				email: "Email",
				password: "Senha",
				title: "Título",
				files: "Arquivos",
				description: "Descrição",
				downloadIr: "Imposto de Renda",
				user: "Usuário",
				cpf: "CPF",
				cnpj: "CNPJ",
				pdf: "PDF",
				id: "ID",
				whatsapp: "WhatsApp",
				phone: "Telefone",
				agency: "Agência",
				agencyDv: "Dígito da agência",
				account: "Conta",
				document: "Documento",
				accountDv: "Dígito da conta",
				bankName: "Banco",
				incomeTax: "Imposto de Renda",
				expirationDate: "Data de Prazo",
				isFinalized: "Crédito Finalizado?",
				finalize: "Finalizar este crédito?",
				credit: "Crédito",
				text: "Texto",
				grossValue: "Valor Bruto",
				isAdvisor: "Administrador Assessor?",
				createdAt: "Data de criação",
				investedAmount: "Valor Investido",
				investmentDate: "Data do Investimento",
				year: "Ano",
				percentageExpectedValue: "Percentual de valor esperado",
				finalizedOrNot: (isFinalized: boolean) => isFinalized ? "Finalizado" : "Em Andamento",
			},
			mask: {
				date: "__/__/____",
				time: "__:__",
				year: "Ano",
			},
			modal: {
				title: "Deletar",
				titleFinish: "Finalizar",
				descriptionDelete: "Deseja mesmo remover?",
				button: {
					yes: "Sim",
					no: "Não",
					approve: "Aprovar",
					finish: "Finalizar",
					refuse: "Recusar",
					remove: "Remover",
					delete: "Deletar",
					cancel: "Cancelar",
					block: "Bloquear",
					disable: "Bloquear",
					enable: "Desbloquear",
					confirm: "Confirmar",
				},
				titleApproveOrRefuse: "Aprovar ou Recusar",
				descriptionApproveOrRefuse: "Tem certeza que deseja excluir esse usuário da plataforma?",
				description: (userName: string) => `Deseja mesmo deletar o usuário ${userName}?`,
				descriptionCredit: "Deseja mesmo desvincular este crédito do usuário?",
				descriptionNotification: (credit: string) => `Deseja mesmo deletar esta notificação? (${credit}).`,
			},
		},
		home: {
			message: (userName: string | undefined) => `Bem vindo ao Dashboard,  ${userName}!`,
		},
		pages: {
			login: {
				loginIn: "Login",
				altImg: "Mohz logo",
				recoveryPassword: "Esqueceu a senha?",
				success: (userName: string) => `Bem vindo ${userName}.`,
				fields: {
					email: "E-mail",
					password: "Senha",
				},
			},
			recoveryPage: {
				success: "Bem vindo!",
				altImg: "Connect-vet logo",
				title: "Recuperação de Senha",
				newAccess: "Novo Acesso",
				sendSuccess: "E-mail enviado com sucesso!",
				recoverPasswordButton: "Enviar",
				newPasswordButton: "Alterar Senha",
				email: "Digite o seu e-mail abaixo para receber o link de recuperação de senha:",
				token: "Token",
				validToken: "Token válido!",
				confirmTokenButton: "Confirmar Token",
				tokenButton: "Já possui um token?",
				noTokenButton: "Não possuo token",
				newPassword: "Nova senha",
				confirmNewPassword: "Confirme sua nova senha",
				samePasswordError: "As senhas não correspondem",
				successPassword: "Senha alterada com sucesso",
				shortPassword: "Senha muito curta",
			},
			home: {
				welcome: (adminName: string) => `Bem vindo ao dashboard ${adminName}!`,
			},
			purchasedProduct: {
				title: "Adicionar Crédito",
				finalizedDate: "Data de finalização",
				titleEdit: "Editar Crédito do Usuário",
				placeholderProduct: "Clique para selecionar um produto.",
				expirationDateFormater: (date: string) => `Data de Prazo: ${date}`,
			},
			posts: {
				table: {
					title: "BLOG",
					header: ["Foto", "ID", "Título", "Texto"],
					delete: "Post deletado com sucesso!",
					add: "Criar Uma Nova Postagem",
					success: (post: string) => `Post ${post} deletado com sucesso!`,
				},
				createOrEdit: {
					title: (isEdit?: boolean) => isEdit ? "Editar Postagem" : "Nova Postagem",
					success: (isEdit?: boolean) => isEdit ? "Post editado com sucesso" : "Post criado com sucesso",
					textAddImage: "Clique em adicionar para escolher uma imagem",
					titlePlaceholder: "Título",
					imgSizeRecomended: "Tamanho recomendado: (1130x1600)",
					altSearch: "Ícone de busca",
					descriptionPlaceholder: "Digite seu texto aqui...",
				},
			},
			product: {
				table: {
					title: "Crédito",
					altSearch: "Ícone de busca",
					altDownload: "Ícone de Download",
					header: ["ID", "Nome", "Data de Prazo", "Imagem", "Status"],
					delete: "Produto deletado com sucesso!",
					add: "Criar",
					status: (finalizedAt: Date | null) => finalizedAt ? "Finalizado" : "Em andamento",
				},
				createOrEdit: {
					title: (hasId: string | undefined) => hasId ? "Editar Crédito" : "Novo Crédito",
					titlePurchased: "Novo Crédito do Usuário",
					success: (isEdit?: boolean) => isEdit ? "Crédito editado com sucesso" : "Crédito criado com sucesso",
					textAddImage: "Clique para escolher uma imagem",
					selectUser: "Selecionar Usuário",
					selectCredit: "Selecionar Crédito",
					finalizedDate: "Data de finalização",
					successPurchasedProduct: "Crédito vinculado com sucesso!",
					back: "Voltar",
				},
				details: {
					headersNotification: ["ID", "Título", "Texto", "Iniciado em", ""],
					headersUsers: ["ID", "Nome", "Email", "CPF", "Finalizado em", ""],
					titleNotification: "Avisos",
					titleUsers: "Usuários Vinculados",
					notFinalized: "Não Finalizado",
					descriptionDeleteProduct: "Deseja desvincular este crédito do usuário?",
					descriptionDeleteNotification: "Deseja apagar este aviso?",
				},
				notification : {
					create: {
						success: "Notificação criada com sucesso!",
						title: "Cadastrar Aviso",
					},
					edit: {
						success: "Notificação editada com sucesso!",
						title: "Editar Aviso",
					},
					details: {
						title:  "Detalhes do Aviso",
						pdf:  (number: number) => `PDF N.º ${number}`,
					},
				},
			},
			userDeleteRequests: {
				table: {
					title: "Lista de Usuários com Solicitações de Exclusão de Conta",
					header: ["Nome do Usuário", "Email", ""],
					successApproveOrRefuse: (isApprove: boolean) => `Exclusão de Conta${isApprove ? " Aprovada" : " Recusada"}`,
				},
			},
			adminUserTable: {
				table: {
					title: "Administradores Master",
					header: ["Nome", "E-mail",""],
					delete: "Administrador deletado com sucesso!",
					totalText: "Administrador cadastrados",
					addButtonText: "Cadastrar Funcionário",
					alt: "ícone de busca",
				},
				createOrEdit: {
					title: (isEdit?: boolean) => isEdit ? "Editar Administrador" : "Novo Administrador Master",
					success: (isEdit?: boolean) => isEdit ? "Administrador editado com sucesso" : "Administrador criado com sucesso",
				},
			},
			users: {
				table: {
					title: "Lista de Usuários",
					header: ["ID", "Nome", "Email", "Telefone", "WhatsApp", ""],
					delete: (title: string) => `Usuário ${title} foi deletado!`,
					totalText: "Usuário cadastrado",
					alt: "Search Icon",
					addButtonText: "Cadastrar Usuário",
					placeholder: "Digite aqui...",
					options: ["CPF"],
				},
				createOrEdit: {
					bankAccountTitle: (hasBankAccount: boolean) => hasBankAccount ? "Editar Conta Bancária" : "Cadastro de Conta",
					success: (isEdit?: boolean) => isEdit ? "Usuário editado com sucesso" : "Usuário criado com sucesso",
					successPurchasedProduct: "Crédito adicionado com sucesso",
					successEditPurchasedProduct: "Crédito editado com sucesso",
					advisor: "Assessor",
				},
				details: {
					tableTitle: "IR",
					headers: ["Ano"],
					notFinalized: "Não Finalizado",
					headersProducts: ["ID", "Nome", "Crédito", "Finalizado em", ""],
					addButtonText: "Criar",
					title: "Créditos Vinculados",
				},
			},
			incomeTax: {
				create: {
					textButton: "Clique em adicionar para escolher um arquivo",
				},
				details: {
					title: (year: string) => `IR ${year}`,
				},
			},
		},
		format,
	},
});

export default strings;
