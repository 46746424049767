
const teal = {
	50: "#F2F2F2",
	100: "#F5F5F5",
	200: "#C4C4C4",
	300: "#ADADAD",
	400: "#969696",
	500: "#333333",
	600: "#565656",
	700: "#232323",
	800: "#333333",
	900: "#1A1A1A",
};

const white = {
	50: "#FFFFFF",
	100: "#efefef",
	200: "#dcdcdc",
	300: "#bdbdbd",
	400: "#989898",
	500: "#7c7c7c",
	600: "#656565",
	700: "#525252",
	800: "#464646",
	900: "#3d3d3d",
};

export const colors = {
	primary: teal,
	secondary: white,
};
