import React from "react";
import { observer } from "mobx-react-lite";
import {
	Flex,
	Button,
} from "@chakra-ui/react";
import {
	Card,
	TextInput,
} from "~/components";
import strings from "~/resources/strings";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import { useHistory } from "~/hooks/useHistory";
import { showErrorToast } from "~/resources/toast";

export const Recovery: React.FC = observer(() => {
	const [hasToken, setHasToken] = React.useState(false);
	const { authStore } = useGlobalStore();
	const history = useHistory();

	const pageStrings = strings.pages.recoveryPage;

	const onValidateToken = () => {
		if (hasToken) {
			authStore.validateToken(() => {
				history.push(
					`/recovery/token/${authStore.receivedToken}`,
				);
			},
			(errorMessage: string) => showErrorToast(errorMessage),
			);
		} else {
			authStore.sendNewPassword(() => {
				history.push("/recovery");
				setHasToken(!hasToken);
			},
			(errorMessage: string) => showErrorToast(errorMessage),
			);
		}
	};

	return (
		<Flex
			justifyContent="center"
			alignItems="center"
			w="100%"
			minH="100vh"
			bg="primary.700"
		>
			<Card
				display="flex"
				alignItems="center"
				maxW={{ base: "100vw", sm: "28rem" }}
				rounded={{ base: 0, sm: 10 }}
				w="100%"
				p={10}
				bgColor="primary.500"
			>
				<Flex direction="column" w="100%">
					{!authStore.receivedToken && authStore.receivedToken.length === 0 && (
						<>
							{hasToken ? (
								<>
									<TextInput
										key={authStore.form.field("token").name}
										type="text"
										labelText={pageStrings.token}
										isDisabled={authStore.loader.isLoading}
										{...authStore.form.field("token")}
									/>
								</>
							) : (
								<>
									<TextInput
										labelProps={{
											mx:"auto",
											mb: 10,
											color: "white",
											textAlign: "center",
											fontWeight: "normal",
										}}
										key={authStore.form.field("email").name}
										type="email"
										labelText={pageStrings.email}
										isDisabled={authStore.loader.isLoading}
										{...authStore.form.field("email")}
									/>
								</>
							)}
							<Button
								mt={4}
								w="100%"
								isLoading={authStore.loader.isLoading}
								onClick={onValidateToken}
							>
								{pageStrings.recoverPasswordButton}
							</Button>
						</>
					)}
				</Flex>
			</Card>
		</Flex>
	);
});
