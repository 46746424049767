import React from "react";
import { observer } from "mobx-react-lite";
import {
	Button,
	Flex,
	StackProps,
} from "@chakra-ui/react";
import {
	DatePicker,
	ModalComponent,
	PdfPicker,
} from "~/components";
import strings from "~/resources/strings";

interface IProps extends StackProps {
	isOpen: boolean;
	onClose: () => void;
	selectYear: (year: Date) => void;
	year: Date | null;
	onClick?: () => void;
	src: string;
}

export const ModalPdfPicker: React.FC<IProps> = observer((props) => {
	const {
		onClose,
		isOpen,
		selectYear,
		year,
		onClick,
		src,
	} = props;
	const commonStrings = strings.common;

	return (
		<ModalComponent
			size="md"
			isOpen={isOpen}
			onClose={onClose}
			gap={3}
		>
			<Flex direction="column" gap={3}>
				<DatePicker
					onlyYear
					onChangeDate={selectYear}
					selectedDate={year}
				/>
				<PdfPicker src={src} />
				<Button
					bgColor="primary.900"
					color="white"
					_hover={{
						bgColor: "primary.50",
						color: "black",
					}}
					onClick={onClick}
					py={10}
				>
					{commonStrings.send}
				</Button>
			</Flex>
		</ModalComponent>
	);
});

