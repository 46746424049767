import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import {
	Box,
	Button,
	Flex,
	Stack,
	Switch,
	Text,
} from "@chakra-ui/react";
import {
	CentralizedCard,
	DatePicker,
	FileAttach,
	MoneyInput,
	TextInput,
} from "~/components";
import strings from "~/resources/strings";
import { useHistory } from "~/hooks/useHistory";
import Store from "./store";

const Edit: React.FC = () => {
	const { id } = useParams<{id: string}>();
	const history = useHistory();
	const store = useLocalObservable(() => new Store(id || ""));
	const commonStrings = strings.common;
	const pageStrings = strings.pages.purchasedProduct;

	React.useEffect(() => {
		if (store.file.getPickerFields().uncertainfiedFile) {
			store.files.setValue([...store.files.value!, store.file.fileName!]);
			return;
		}
	},[store.file.getPickerFields().uncertainfiedFile]);

	const onRemove = (index: number) =>{
		store.files.value?.splice(index, 1);
	};

	const onSuccess = () => {
		history.goBack();
	};

	return (
		<Flex
			mx="auto"
			w={{
				base: "100%",
				md: "80%",
				xl: "60%",
			}}
		>
			<CentralizedCard
				title={{
					text: pageStrings.titleEdit,
				}}
				button={(
					<Button
						minW={{ base: "80%", md: 340 }}
						size="lg"
						mb={5}
						isLoading={store.loader.isLoading}
						onClick={() => store.editPurchasedProduct(onSuccess)}
					>
						{commonStrings.buttons.saveOrEdit(!!id)}
					</Button>
				)}
				altPaddingForCard
			>
				<TextInput
					isDisabled
					labelText={commonStrings.fields.credit}
					labelProps={{
						marginBottom: 0,
						marginY: 0,
					}}
					value={store.productName.value}
					onChange={(e) => store.productName.setValue(e.target.value)}
					border="none"
					padding={0}
				/>
				<TextInput
					isDisabled
					labelText={commonStrings.fields.user}
					labelProps={{
						marginBottom: 0,
						marginY: 0,
					}}
					value={store.userName.value}
					onChange={(e) => store.userName.setValue(e.target.value)}
					border="none"
					padding={0}
				/>
				<MoneyInput
					labelText={commonStrings.fields.investedAmount}
					value={store.investedAmount.value}
					onChange={store.investedAmount.setValue}
				/>
				<Flex>
					<DatePicker
						label={strings.components.datePicker.investmentDate}
						onChangeDate={store.investmentDate.setValue}
						selectedDate={store.investmentDate.value}
					/>
				</Flex>
				<TextInput
					labelText={commonStrings.fields.percentageExpectedValue}
					isDisabled={store.loader.isLoading}
					prefix="%"
					onChange={(e) => store.percentageExpectedValue.setValue(e.target.value)}
					value={store.percentageExpectedValue.value}
				/>
				<FileAttach
					onClick={() => store.file.getPickerFields().pick()}
					files={store.files.value}
					onRemove={onRemove}
				/>
				<Stack>
					<Text color="white" fontSize={16}>{commonStrings.fields.isFinalized}</Text>
					<Box>
						<Switch
							isChecked={store.isFinalized.value}
							mb={8}
							colorScheme="teal"
							onChangeCapture={() => store.isFinalized.setValue(!store.isFinalized.value)}
							size="lg"
						/>
					</Box>
				</Stack>
				{store.isFinalized.value && (
					<>
						<MoneyInput
							labelText={commonStrings.fields.grossValue}
							value={store.grossValue.value}
							onChange={store.grossValue.setValue}
						/>
						<Text>{strings.components.datePicker.investmentDate}</Text>
						<DatePicker
							placeholder={strings.components.datePicker.finalizedDate}
							onChangeDate={store.finalizedDate.setValue}
							selectedDate={store.finalizedDate.value}
						/>
					</>
				)}
			</CentralizedCard>
		</Flex>
	);
};

export default observer(Edit);
