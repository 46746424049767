import React from "react";
import {
	Input,
	InputGroup,
	InputLeftElement,
} from "@chakra-ui/react";
import format from "../../resources/format";
import { Label } from "../Label";

interface IProps{
	value: string;
	labelText?: string;
	onChange: (value: string) => void;
}

export const MoneyInput: React.FC<IProps> = (props) => {

	const {
		value,
		onChange,
		labelText,
	} = props;

	return (
		<>
			<Label
				marginBottom={2}
				color="white"
				fontWeight="bold"
				my={2}
				fontSize="md"
			>
				{labelText}
			</Label>
			<InputGroup>
				<InputLeftElement
					pointerEvents="none"
					color="white"
				>
					R$
				</InputLeftElement>
				<Input
					name="price"
					placeholder="0,00"
					value={format.decimal(Number(value))}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
						onChange(format.cleanDecimal(e.currentTarget.value));
					}}
				/>
			</InputGroup>
		</>
	);
};
