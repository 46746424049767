import { makeAutoObservable } from "mobx";
import { FormShelf, ImagePickerShelf } from "@startapp/mobx-utils/src/web";
import { AttributeShelf,
	LoaderShelf,
	PaginatedListShelf,
} from "@startapp/mobx-utils";
import api from "~/resources/api";
import { Errors } from "~/resources/errors";
import strings from "~/resources/strings";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import { onFetchError } from "~/resources/fetchError";

const pageStrings = strings.pages.users.createOrEdit;

export default class Store {

	public state = new AttributeShelf<api.StateUF>(api.StateUF.AC);
	public loader = new LoaderShelf();
	public id = new AttributeShelf("");
	public isCpf = new AttributeShelf(true);
	public imageShelf = new ImagePickerShelf(api.uploadImage);
	public advisors: PaginatedListShelf<api.AdminUser>;
	public advisorNameFilter = new AttributeShelf<string | null>("MOHZ");
	public advisorSelected = new AttributeShelf<api.AdminUser | null>(null);
	public inputValue = new AttributeShelf<string>("");

	public formPassword = new FormShelf({
		password: "",
	});
	public formShelf = new FormShelf({
		phone: "",
		name: "",
		email: "",
		documentNumber: "",
		whatsapp: "",
	});

	public formShelfBankAccount = new FormShelf({
		bankName: "",
		agency: "",
		agencyDv: "",
		account: "",
		accountDv: "",
	});

	constructor(id: string){
		makeAutoObservable(this);

		if (id) {
			this.id.setValue(id);
			this.getUser(id);
		}

		this.advisors = new PaginatedListShelf(
			(page) => api.getAllAdminUsers(page,null,this.advisorNameFilter.value),
			{
				fetchOnConstructor: true,
				onFetchError,
			},
		);

	}
	public getUser = async (id: string) => {
		this.loader.tryStart();
		try {
			const user = await api.getUser(id);
			if (user.advisor === null) {
				this.advisorSelected.setValue(this.advisors.items[1]);
			} else {
				this.advisorSelected.setValue(user.advisor);
			}
			this.setInitValues(user);
		} catch (e) {
			onFetchError(e);
		} finally {
			this.loader.end();
		}
	};

	public setInitValues = (data: api.User) => {
		this.formShelf = new FormShelf({
			phone: data.phone || "",
			name: data.name,
			email: data.email,
			documentNumber: data.documentNumber || "",
			whatsapp: data.whatsapp || "",
		});
		this.formShelfBankAccount = new FormShelf({
			bankName: data.bankName || "",
			account: data.account || "",
			accountDv: data.accountDv || "",
			agency: data.agency || "",
			agencyDv: data.agencyDv || "",
		});
		if (data.documentNumber.length > 14) {
			return this.isCpf.setValue(false);
		}
		this.isCpf.setValue(true);
	};

	public userData = () => {
		const data = this.formShelf.getValues();
		const dataPassword = this.formPassword.getValues();
		const dataBankAccount = this.formShelfBankAccount.getValues();
		return {
			name: data.name,
			password: dataPassword.password,
			email: data.email,
			documentNumber: data.documentNumber,
			phone: data.phone !== "" ? data.phone : null,
			whatsapp: data.whatsapp !== "" ? data.whatsapp : null,
			account: dataBankAccount.account !== "" ? dataBankAccount.account : null,
			accountDv: dataBankAccount.accountDv !== "" ? dataBankAccount.accountDv : null,
			agency: dataBankAccount.agency !== "" ? dataBankAccount.agency : null,
			agencyDv: dataBankAccount.agencyDv !== "" ? dataBankAccount.agencyDv : null,
			bankName: dataBankAccount.bankName !== "" ? dataBankAccount.bankName : null,
			advisor: this.advisorSelected.value!,
		};
	};

	public createOrEditUser = async (onSuccess: () => void) => {
		this.loader.start();
		try {
			if (this.id.value) {
				await api.editUser(this.id.value, this.userData());
			} else {
				await api.createUser(this.userData());
			}
			showSuccessToast(pageStrings.success(!!this.id.value));
			onSuccess();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};
}

