import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import {
	Flex,
	Tr,
	Td,
} from "@chakra-ui/react";
import strings from "~/resources/strings";
import { Table, TableCellWithActionButtons } from "~/components";
import Store from "./store";
import api from "~/resources/api";
import { useGlobalStore } from "~/contexts/useGlobalContext";

const TableView: React.FC = () => {
	const store = useLocalObservable(() => new Store());
	const pageStrings = strings.pages.userDeleteRequests.table;
	const modal = strings.common.modal;

	const { dialog } = useGlobalStore();

	const onApprove = (id: string) => {
		store.approveOrRefuseDelete(id, true);
		dialog.closeDialog();
	};
	const onRefuse = (id: string) => {
		store.approveOrRefuseDelete(id, false);
		dialog.closeDialog();
	};

	const openDialog = (userDelete: api.UserDeleteRequest) => {
		dialog.showDialog({
			closeOnOverlayClick: true,
			description: modal.descriptionApproveOrRefuse,
			buttons: [
				{
					title: modal.button.yes,
					onPress: () => onApprove(userDelete.id),
					buttonProps: { bg: "primary.700" },
				},
				{
					title: modal.button.no,
					onPress: () => onRefuse(userDelete.id),
					buttonProps: { bg: "white", borderWidth: 1, borderColor: "primary.900", textColor: "primary.700" },
				},
			],
		});
	};

	return (
		<Flex flexDir="column" p={{ base: "2", lg: "16" }}>
			<Table
				data={store.paginetedListShelf.items}
				loading={store.paginetedListShelf.loader.isLoading}
				tableTitle={pageStrings.title}
				headers={pageStrings.header}
				renderRow={(item, index) => (
					<Tr key={index}>
						<Td>{item.user.name}</Td>
						<Td>{item.user.email}</Td>
						<TableCellWithActionButtons
							onDelete={() => openDialog(item)}
						/>
					</Tr>
				)}
				emptyMessage={strings.common.noResutls}
				currentPage={store.paginetedListShelf.page}
				prevPage={store.paginetedListShelf.previousPage}
				nextPage={store.paginetedListShelf.nextPage}
				hasNextPage={store.paginetedListShelf.hasNextPage}
			/>
		</Flex>
	);
};

export default observer(TableView);
