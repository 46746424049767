import React from "react";
import { observer } from "mobx-react-lite";

import {
	Button,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalBody,
	ModalHeader,
	InputGroup,
	Input,
} from "@chakra-ui/react";

import { spacing } from "~/themes/spacing";
import strings from "~/resources/strings";
import { List, IProps as IListProps } from "./List";

interface IProps<DataType>{
	isOpen: boolean;
	closeOnOverlayClick?: boolean;
	listProps: IListProps<DataType>;
	header?: React.ReactElement;
	onClose: () => void;
	onSearch: () => void;
	onChange: (event: string) => void;
}

export const AutoComplete = observer(<DataType,>(props: IProps<DataType>) => {
	const {
		onClose,
		onSearch,
		isOpen,
		listProps,
		onChange,
		closeOnOverlayClick = true,
	} = props;

	const componentStrings = strings.components.autoCompleteModal.userModal;

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			closeOnOverlayClick={closeOnOverlayClick}
			isCentered
		>
			<ModalOverlay />
			<ModalContent
				w={{base: "100vw"}}
				maxH="95%"
				overflowY="auto"
				bg="white"
			>
				<ModalHeader px={spacing.s2}>
					<InputGroup
						size="md"
						mt={10}
						w="90%"
						mx="auto"
						position="relative"
					>
						<Input
							onChange={(e) => onChange(e.target.value)}
							border="1px solid"
							borderColor="black"
							_hover={{
								borderColor: "1px solid black",
							}}
							_focus={{
								boxShadow: "0 0 0 0",
								border: "1px solid black",
								outline: 0,
							}}
							zIndex={1}
							color="black"
							h={69}
						/>
						<Button
							border="1px solid black"
							onClick={onSearch}
							w={40}
							bg="black"
							color="white"
							h={69}
							zIndex={2}
							rounded="xl"
							position="absolute"
							right={0}
						>
							{componentStrings.button}
						</Button>
					</InputGroup>
				</ModalHeader>
				<ModalBody px={spacing.s2} bg="white">
					<List {...listProps} />
				</ModalBody>
			</ModalContent>
		</Modal>
	);

});

