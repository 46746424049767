import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import {
	Box,
	Button,
	Text,
	Textarea,
} from "@chakra-ui/react";
import {
	CentralizedCard,
	ImagePicker,
	TextInput,
} from "~/components";
import strings from "~/resources/strings";
import { useHistory } from "~/hooks/useHistory";
import Store from "./store";

const CreateOrEdit: React.FC = () => {
	const commonStrings = strings.common;
	const pageStrings = strings.pages.posts.createOrEdit;
	const { id } = useParams<{ id: string }>();
	const history = useHistory();
	const store = useLocalObservable(() => new Store(id || ""));

	const onSuccess = () => {
		history.goBack();
	};

	return (
		<CentralizedCard
			button={(
				<Button
					minW={{ base: "80%", md: 340 }}
					size="lg"
					mb={5}
					isLoading={store.loader.isLoading}
					onClick={() => store.createOrEditPost(onSuccess)}
				>
					{commonStrings.buttons.saveOrEdit(!!id)}
				</Button>
			)}
			altPaddingForCard
		>
			<TextInput
				placeholder={pageStrings.titlePlaceholder}
				type="text"
				isDisabled={store.loader.isLoading}
				errorText={store.formShelf.field("title").error}
				{...store.formShelf.field("title")}
			/>
			<Box>
				<Text ml={4}fontSize={12}>{pageStrings.imgSizeRecomended}</Text>
				<ImagePicker
					pickImage={store.imageShelf.getPickerFields().pick}
					src={store.imageShelf.src}
				/>
			</Box>
			<Textarea
				_placeholder={{
					color: "white",
				}}
				minH="35vh"
				color="white"
				placeholder={pageStrings.descriptionPlaceholder}
				{...store.formShelf.field("description")}
			/>
		</CentralizedCard>
	);
};

export default observer(CreateOrEdit);
