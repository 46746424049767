import { makeAutoObservable } from "mobx";
import { FormShelf } from "@startapp/mobx-utils/src/web";
import { AttributeShelf, LoaderShelf } from "@startapp/mobx-utils";
import api from "~/resources/api";
import { Errors } from "~/resources/errors";
import strings from "~/resources/strings";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import FilePickerShelf from "~/shelves/FilePickerShelf";

const pageStrings = strings.pages.product.notification;

export default class Store {

	public loader = new LoaderShelf();
	public id = new AttributeShelf("");
	public productId = new AttributeShelf("");
	public files = new AttributeShelf<api.UncertainFile[]>([]);
	public filesName = new AttributeShelf<string[]>([]);
	public file = new FilePickerShelf(api.uploadUncertainFile);

	public formShelf = new FormShelf({
		name: "",
		description: "",
	});

	constructor(productId?: string){
		makeAutoObservable(this);
		if (productId) {
			this.productId.setValue(productId);
		}
	}

	public notificationData = () => {
		const data = this.formShelf.getValues();
		return {
			name: data.name,
			description: data.description,
			files: this.files.value,
		};
	};

	public createNotification = async (onSuccess: () => void) => {
		this.loader.start();
		try {
			await api.createNotification(this.productId.value, this.notificationData());
			showSuccessToast(pageStrings.create.success);
			onSuccess();
		} catch (e) {
			const error = Errors.treatError(e);
			if (error.type === api.ErrorType.Validation) {
				this.formShelf.fieldError.cleanAndAddErrors(JSON.parse((e as Error).message));
			} else {
				const errorMessage = Errors.handleError(e);
				showErrorToast(errorMessage);
			}
		} finally {
			this.loader.end();
		}
	};
}

