import { makeAutoObservable } from "mobx";
import {
	AttributeShelf,
	FetchModelShelf,
	LoaderShelf,
	PaginatedListShelf,
} from "@startapp/mobx-utils";
import api from "~/resources/api";
import { onFetchError } from "~/resources/fetchError";
import strings from "~/resources/strings";
import { showSuccessToast } from "~/resources/toast";

export class Store {

	public fetchModelShelf: FetchModelShelf<api.User>;
	public loader = new LoaderShelf();
	public paginatedListShelf: PaginatedListShelf<api.PurchasedProduct>;
	public isFinalized = new AttributeShelf<boolean>(true);

	constructor(id: string) {
		makeAutoObservable(this);
		this.fetchModelShelf = new FetchModelShelf(id, () => api.getUser(id),
			{
				fetchOnConstructor: true,
				onFetchError,
			},
		);
		this.paginatedListShelf = new PaginatedListShelf(
			(page: number) =>  api.getAllPurchasedProductsByUser(page, id),
			{
				fetchOnConstructor: true,
			},
		);
	}

	public deletePurchasedProduct = async (id: string) => {
		this.loader.start();
		try {
			await api.deletePurchasedProduct(id);
			showSuccessToast(strings.common.deleteSuccess);
			this.paginatedListShelf.refresh();
		} catch (e) {
			onFetchError(e);
		} finally {
			this.loader.end();
		}
	};
}
