import { makeAutoObservable } from "mobx";
import {
	PaginatedListShelf,
	LoaderShelf,
	AttributeShelf,
} from "@startapp/mobx-utils";
import api from "~/resources/api";
import { onFetchError } from "~/resources/fetchError";
import { showSuccessToast } from "~/resources/toast";
import strings from "~/resources/strings";

const pageStrings = strings.pages.product.table;

export default class Store {
	public loader = new LoaderShelf();
	public paginetedListShelf: PaginatedListShelf<api.Product>;
	public isFinalized = new AttributeShelf<boolean | null>(null);
	public name = new AttributeShelf<string | null>(null);
	public downloadLoader = new LoaderShelf();
	constructor() {
		makeAutoObservable(this);

		this.paginetedListShelf = new PaginatedListShelf(
			(page: number) =>  api.getAllProducts(page, this.name.value, this.isFinalized.value),
			{
				fetchOnConstructor: true,
			},
		);
	}

	public deleteProduct = async (id: string) => {
		this.loader.tryStart();
		try {
			await api.deleteProduct(id);
			showSuccessToast(pageStrings.delete);
			this.paginetedListShelf.refresh();
		} catch (e) {
			onFetchError(e);
		} finally {
			this.loader.end();
		}
	};

	public downloadCSV = async () => {
		try {
			this.downloadLoader.tryStart();
			const url = await api.getReportsOfPurchasedProducts();
			window.open(url);
		} catch (e) {
			onFetchError(e);
		} finally {
			this.downloadLoader.end();
		}
	};
}

