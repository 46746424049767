import React from "react";
import {
	Drawer,
	DrawerOverlay,
	DrawerBody,
	DrawerContent,
	Text,
	Input,
	Flex,
	Button,
	InputGroup,
	Icon,
	Heading,
} from "@chakra-ui/react";
import { AiOutlineMenu } from "react-icons/ai";
import strings from "~/resources/strings";
import { DrawerContainer } from "~/components";

interface IProps {
	isOpen: boolean;
	value?: string;
	onChange?: (e: string) => void;
	setStatus?: (status: boolean | null) => void;
	filterAdmin?: boolean;
	searchFinalized?: boolean | null;
	onClose: () => void;
	onClick: () => void;
}

export const DrawerSearch: React.FC<IProps> = (props) => {
	const {
		isOpen,
		onClose,
		onClick,
		onChange,
		setStatus,
		value,
		filterAdmin,
		searchFinalized,
	} = props;

	const [openMenu, setOpenMenu] = React.useState(false);

	const componentsString = strings.components.drawerSearch;

	const handleChange = (e: string) => onChange ? onChange(e) : null;

	const clearFilter = () => {
		if (onChange) {
			onChange("");
		}
		onClick();
		if (setStatus) {
			setStatus(null);
		}
	};

	return (
		<Drawer
			placement="left"
			isOpen={isOpen}
			onClose={onClose}
		>
			<DrawerOverlay />
			<DrawerContent
				background="primary.600"
				color="white"
				alignItems="center"
			>
				<DrawerBody pt={{md:20}}>
					<Icon
						as={AiOutlineMenu}
						onClick={() => setOpenMenu(true)}
						cursor="pointer"
						color="secondary.50"
						ml={10}
						w={35}
						h={35}
					/>
					{onChange && (
						<InputGroup
							size="md"
							mt={10}
							position="relative"
						>
							<Input
								zIndex={1}
								onChange={(e) => handleChange(e.target.value)}
								value={value}
								placeholder={componentsString.placeholder}
							/>
							<Button
								h="100%"
								zIndex={2}
								onClick={onClick}
								rounded="xl"
								position="absolute"
								top={0}
								right={0}
							>
								{componentsString.search}
							</Button>
						</InputGroup>
					)}
					{setStatus && (
						<Flex
							direction="column"
							mt={7}
							gap={2}
						>
							<Heading
								mb={1}
								fontSize={18}
								as="h5"
							>
								{filterAdmin ? componentsString.titleAdmin : componentsString.titleStatus}
							</Heading>
							<Button
								isActive={searchFinalized !== null && searchFinalized ? true : false}
								onClick={() => {
									setStatus(true);
									onClick();
								}}
								w={145}
								variant="outline"
							>
								{filterAdmin ? componentsString.adminType.adminMaster : componentsString.status.finalized}
							</Button>
							<Button
								isActive={searchFinalized !== null && searchFinalized === false ? true : false}
								onClick={() => {
									setStatus(false);
									onClick();
								}}
								w={145}
								variant="outline"
							>
								{filterAdmin ? componentsString.adminType.advisor :componentsString.status.notFinalized}
							</Button>
						</Flex>
					)}
					<Flex
						direction="column"
						mt={40}
						textAlign="center"
					>
						<Text
							onClick={clearFilter}
							cursor="pointer"
							fontWeight="bold"
							mb={5}
						>
							{strings.components.drawerSearch.clear}
						</Text>
						<Text
							onClick={onClose}
							textTransform="uppercase"
							cursor="pointer"
						>
							{strings.components.drawerSearch.close}
						</Text>
					</Flex>
				</DrawerBody>
			</DrawerContent>
			<DrawerContainer
				isOpen={openMenu}
				onClose={() => setOpenMenu(false)}
			/>
		</Drawer>
	);
};
